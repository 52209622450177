










































import {Component, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import PurposeCard from "@/components/domains/esthe/soundesthe/plans/purposes/PurposeCard.vue";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";

@Component({
  components: {BackBtn, MyPageContentTemplate, HoverLinkCard, PurposeCard, KawaiiButton}
})
export default class SoundEsthePurposePlan extends Vue {
  name = "SoundEsthePurposePlan";

}
