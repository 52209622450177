import {User} from "@/components/domains/authorization/user";
import {AuthGetterTypes} from "@/store/auth/types";
import store from '@/store';
import MyPageLinkHolder from "@/components/domains/account/mypage/myPageLinkHolder";

export class MyPageService {

    public static getMyPageLinks(): {}[] {
        const user: User = store.getters[AuthGetterTypes.getCurrentUser];
        return this.getMyPageLinksOf(user);
    }

    public static getMyPageLinksOf(user: User): {}[] {

        // マイページのリンク設定
        const myPageLinks: {}[] = [];

        if (user.agency.code === '') {
            // 所属団体不明ユーザー
        }
        // 仮登録
        else if (user.agency.status == '1') {
            myPageLinks.push(MyPageLinkHolder.link_認可認証);
            return myPageLinks;
        }
        // 停止
        else if (user.agency.status == '2') {
            myPageLinks.push(MyPageLinkHolder.link_認可認証);
            return myPageLinks;
        }

        // エンタープライズ
        if (user.roles.includes("ROLE_ENTERPRISE")) {
            myPageLinks.push(MyPageLinkHolder.title_エンタープライズ);
            myPageLinks.push(MyPageLinkHolder.link_エンタープライズ);
        } else {
            // エステ
            myPageLinks.push(MyPageLinkHolder.title_エステ);
            myPageLinks.push(MyPageLinkHolder.link_AI_ESTHE);
            myPageLinks.push(MyPageLinkHolder.link_メディカルサウンド);
            // myPageLinks.push(MyPageLinkHolder.link_奏);

            // 活動
            myPageLinks.push(MyPageLinkHolder.title_活動);
            myPageLinks.push(MyPageLinkHolder.link_利用状況);
        }

        // ロール系
        if (user.roles.includes("ROLE_ADMIN")) {

            myPageLinks.push(MyPageLinkHolder.link_カレンダー);
            myPageLinks.push(MyPageLinkHolder.link_顔診断履歴)
            myPageLinks.push(MyPageLinkHolder.link_プロフィール);

            // サブスクリプション
            // myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            // myPageLinks.push(MyPageLinkHolder.link_購読状況);
            // myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            // サブスクリプション
            myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            myPageLinks.push(MyPageLinkHolder.link_サブスク管理);
            myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            // その他
            myPageLinks.push(MyPageLinkHolder.title_その他);
            myPageLinks.push(MyPageLinkHolder.link_お支払方法);
            myPageLinks.push(MyPageLinkHolder.link_認可認証);
            // myPageLinks.push({type: "link", name: "移管", link: "/mypage/transfer"});
            myPageLinks.push(MyPageLinkHolder.link_アップロード);
            myPageLinks.push(MyPageLinkHolder.link_各種設定);
            myPageLinks.push(MyPageLinkHolder.link_管理画面);

        } else if (user.roles.includes("ROLE_PERSON_IN_CHARGE")) {

            myPageLinks.push(MyPageLinkHolder.link_カレンダー);
            myPageLinks.push(MyPageLinkHolder.link_管理カレンダー);
            myPageLinks.push(MyPageLinkHolder.link_顔診断履歴)

            // カルテ
            myPageLinks.push(MyPageLinkHolder.title_カルテ);
            myPageLinks.push(MyPageLinkHolder.link_カルテ管理);
            myPageLinks.push(MyPageLinkHolder.link_カルテ一覧_担当者);
            myPageLinks.push(MyPageLinkHolder.link_プロフィール);

            // サブスクリプション
            // myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            // myPageLinks.push(MyPageLinkHolder.link_購読状況);
            // myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            // サブスクリプション
            myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            myPageLinks.push(MyPageLinkHolder.link_サブスク管理);
            myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            // その他
            myPageLinks.push({type: "title", name: "その他", link: "", icon: ''});
            myPageLinks.push(MyPageLinkHolder.link_お支払方法);
            myPageLinks.push(MyPageLinkHolder.link_認可認証);
            myPageLinks.push(MyPageLinkHolder.link_各種設定);

        } else if (user.roles.includes("ROLE_CUSTOMER")) {

            myPageLinks.push(MyPageLinkHolder.link_カレンダー);
            myPageLinks.push(MyPageLinkHolder.link_顔診断履歴)

            // カルテ
            myPageLinks.push(MyPageLinkHolder.title_カルテ);
            myPageLinks.push(MyPageLinkHolder.link_カルテ一覧_顧客);

            // サブスクリプション
            myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            myPageLinks.push(MyPageLinkHolder.link_サブスク管理);
            myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            // その他
            myPageLinks.push(MyPageLinkHolder.title_その他);
            myPageLinks.push(MyPageLinkHolder.link_お支払方法);
            myPageLinks.push(MyPageLinkHolder.link_プロフィール);
            myPageLinks.push(MyPageLinkHolder.link_認可認証);

            // サブスクリプション
            // myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            // myPageLinks.push(MyPageLinkHolder.link_購読状況);
            // myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            myPageLinks.push(MyPageLinkHolder.link_各種設定);

        } else if (user.roles.includes("ROLE_GENERAL")) {

            myPageLinks.push(MyPageLinkHolder.link_カレンダー);
            myPageLinks.push(MyPageLinkHolder.link_顔診断履歴)
            myPageLinks.push(MyPageLinkHolder.link_プロフィール);

            // サブスクリプション
            myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            myPageLinks.push(MyPageLinkHolder.link_サブスク管理);
            myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            // その他
            myPageLinks.push(MyPageLinkHolder.title_その他);
            myPageLinks.push(MyPageLinkHolder.link_お支払方法);
            myPageLinks.push(MyPageLinkHolder.link_認可認証);

            // サブスクリプション
            // myPageLinks.push(MyPageLinkHolder.title_サブスクリプション);
            // myPageLinks.push(MyPageLinkHolder.link_購読状況);
            // myPageLinks.push(MyPageLinkHolder.link_購入履歴);

            myPageLinks.push(MyPageLinkHolder.link_各種設定);
        } else if (user.roles.includes("ROLE_ENTERPRISE")) {
            myPageLinks.push(MyPageLinkHolder.link_各種設定);
        }

        return myPageLinks;
    }
}
