







import {Component, Vue} from "vue-property-decorator";
import StepContents, {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import BeforeCameraStep from "@/components/domains/esthe/aiesthe/plans/detectface/steps/BeforeCameraStep.vue";
import VibrationExperienceStep
  from "@/components/domains/esthe/aiesthe/plans/detectface/steps/VibrationExperienceStep.vue";
import AfterCameraStep from "@/components/domains/esthe/aiesthe/plans/detectface/steps/AfterCameraStep.vue";
import SelectCourseStep from "@/components/domains/esthe/aiesthe/plans/detectface/steps/SelectCourseStep.vue";
import ResultStep from "@/components/domains/esthe/aiesthe/plans/detectface/steps/ResultStep.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";

@Component({
  components: {MyPageContentTemplate, StepContents}
})
export default class AIEstheDetectFacePlanPage extends Vue {

  name = "AIEstheDetectFacePlanPage"

  steps: Step[] = [
    new SelectCourseStep(),
    new BeforeCameraStep(),
    new VibrationExperienceStep(),
    new AfterCameraStep(),
    new ResultStep()
  ];
}

