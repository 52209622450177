

























































import {Component, Prop, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import authService from "@/components/domains/authorization/authService";
import HpSubTitle from "@/components/infrastructures/atoms/text/HpSubTitle.vue";
import LangSelection from "@/components/infrastructures/molecules/LangSelection.vue";

@Component({
  components: {LangSelection, HpSubTitle, KawaiiButton}
})
export default class HomePcTemplate extends Vue {
  name = "HomePcTemplate";

  get isLoggedIn() {
    return authService.isLoggedIn();
  }

  @Prop() links!: [];
}
