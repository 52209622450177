
































































import {Component, Vue} from "vue-property-decorator";
import MentalCheckInductionHoverLink from "@/components/domains/mentalcheck/MentalCheckInductionHoverLink.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle, MentalCheckInductionHoverLink}
})
export default class MenuPanel extends Vue {
  name = "MenuPanel";

  get getMyPageLinks() {
    return this.$store.getters.getMyPageLinks
    // .filter((link: any) => link.type === 'link')
  }
}
