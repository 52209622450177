

















import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {AIEstheMutationTypes} from "@/store/aiesthe/types";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import BigRadio from "@/components/infrastructures/atoms/designed/BigRadio.vue";

@Component({
  components: {BigRadio, HoverLinkCard, BackBtn, SubBtn, KawaiiButton, VerticalSlotContents}
})
export default class FeelingSelectCourseStep extends Step {
  name = "FeelingSelectCourseStep";
  selected = 0;

  values = [
    {value: 1, label: '朝の目覚め'},
    {value: 2, label: 'おやすみ'},
    {value: 3, label: 'リラクゼーション'},
    {value: 4, label: '気分転換'},
  ];

  public getComponentName(): string {
    return this.name;
  }

  validate(): boolean {
    return !(this.selected != 1 && this.selected != 2 && this.selected != 3 && this.selected != 4);
  }

  getStepTitle(): string {
    return 'コース選択';
  }

  @Watch("selected")
  watchSelected() {
    this.$store.commit(AIEstheMutationTypes.setCourse, this.selected);
    if (!this.validate()) {
      this.doCancel();
    } else {
      this.doComplete();
    }
    if (this.isCompleted()) {
      this.nextStep();
    }
  }

  nextStep() {
    this.next();
  }
}

