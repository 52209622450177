










import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {Component} from "vue-property-decorator";

@Component
export default class TransferAgencySelectionStep extends Step {

  name = "TransferAgencySelectionStep";

  getComponentName(): string {
    return this.name;
  }

  getStepTitle(): string {
    return "移管先団体の選択";
  }

  back() {
    super.back();
  }

  next() {
    super.next();
  }
}
