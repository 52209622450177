import {MutationTree} from "vuex";
import {AIEstheMutationTypes, AIEstheState} from "@/store/aiesthe/types";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";

const mutations: MutationTree<AIEstheState> = {
    [AIEstheMutationTypes.setImage]: (state, image: string) => {
        state.image = image;
    },
    [AIEstheMutationTypes.setCourse]: (state, course: number) => {
        state.course = course;
    },
    [AIEstheMutationTypes.setAfter]: (state, after: boolean) => {
        state.after = after
    },
    [AIEstheMutationTypes.setId]: (state, id: string) => {
        state.id = id
    },
    [AIEstheMutationTypes.setSoundId]: (state, soundId: string) => {
        state.soundId = soundId
    },
    [AIEstheMutationTypes.setSoundSrc]: (state, soundSrc: string) => {
        state.soundSrc = soundSrc
    },
    [AIEstheMutationTypes.setBodyPart]: (state, bodyPart: string) => {
        state.bodyPart = bodyPart
    },
    [AIEstheMutationTypes.setPublish]: (state, publish: boolean) => {
        state.publish = publish
    },
    [AIEstheMutationTypes.setDateTime]: (state, dateTime: StopWatchDateTime) => {
        state.dateTime = dateTime
    },
    [AIEstheMutationTypes.setDetectFaceResult]: (state, detectFaceResult: {}) => {
        state.detectFaceResult = detectFaceResult
    },
}

export default mutations;
