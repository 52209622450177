import IndexedAudio from "@/components/domains/esthe/IndexedAudio";

export interface DmvState {
    subWaves: IndexedAudio[];
    playingMain: boolean;
    loop: boolean;
}

export enum DmvGetterTypes {
    getSelectedWaves = "getSelectedWaves",
    getLoopSelectedWave = "getLoopSelectedWave",
    getPlayingMain = "getPlayingMain",
}

export enum DmvMutationTypes {
    setSelectedWave = "setSelectedWave",
    setSelectedWaves = "setSelectedWaves",
    setPlayingMain = "setPlayingMain",
    setLoopSelectedWave = "setLoopSelectedWave"
}

export enum DmvActionTypes {
    clearSelectedWaves = "clearSelectedWaves",
    playSelectedWaves = "playSelectedWaves",
    pauseSelectedWaves = "pauseSelectedWaves"
}
