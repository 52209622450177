import {GetterTree} from "vuex";
import {RootState} from "@/store/type";
import {CalteGetterTypes, CalteState} from "@/store/calte/types";
import CalteFormat from "@/components/domains/calte/calteFormat";
import CalteRecord from "@/components/domains/calte/calteRecord";

const getters: GetterTree<CalteState, RootState> = {
    [CalteGetterTypes.getSelectedCustomer]: (state: CalteState): {} => {
        return state.selectedCustomer;
    },
    [CalteGetterTypes.getCalteFormat]: (state: CalteState): CalteFormat => {
        return state.calteFormat;
    },
    [CalteGetterTypes.getCalteRecord]: (state: CalteState): CalteRecord => {
        return state.calteRecord;
    },
    [CalteGetterTypes.getAIEstheFaceDetectResult]: (state: CalteState): {} => {
        return state.aiEstheFaceDetectResult;
    }
}

export default getters;
