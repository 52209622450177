import httpResource from "@/components/infrastructures/functions/http/httpResource";
import PagingValidation from "@/components/infrastructures/functions/paging/pagingValidation";

export default class AIEstheService {

    public static async historyPagingSizeSorted(page: number, itemsPerPage: number, sort: string) {

        const size = itemsPerPage == -1 ? 0 : itemsPerPage;

        if (!PagingValidation.validate(size)) {
            return;
        }

        const totalResponse = await httpResource.get(`aiesthe/my-history-count`);
        const actPage = page - 1;

        const caltesResponse = await httpResource.get(`aiesthe/my-history?page=${actPage}&size=${size}&sort=${sort}`);
        const sortedItems = caltesResponse.data;

        return {items: sortedItems, total: totalResponse.data};
    }

    public static async historyPagingSize(page: number, itemsPerPage: number) {
        return this.historyPagingSizeSorted(page, itemsPerPage, 'desc');
    }

    public static async detectFaceResult(detectFaceId: string) {
        return httpResource.get(`/aiesthe/detect-result?detectId=${detectFaceId}`);
    }

    public static async fetchSoundTherapyDmvList(clusterId: number, bodyPart = 'all') {
        return httpResource.get(`/aiesthe/sound-therapy/list?clusterId=${clusterId}&bodyPart=${bodyPart}`);
    }

    public static async fetchSoundTherapyDmv(soundSrcId: string) {
        return httpResource.get(`/aiesthe/sound-therapy/sound?soundSrcId=${soundSrcId}`);
    }
}