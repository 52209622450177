import {GetterTree} from "vuex";
import {RootState} from "@/store/type";
import {AccountTransferGetterTypes, AccountTransferState} from "@/store/account/transfer/types";

const getters: GetterTree<AccountTransferState, RootState> = {
    [AccountTransferGetterTypes.getUser]: (state: AccountTransferState) => {
        return state.user;
    },
    [AccountTransferGetterTypes.getTargetAgency]: (state: AccountTransferState) => {
        return state.targetAgency;
    },
    [AccountTransferGetterTypes.getTargetRoles]: (state: AccountTransferState) => {
        return state.targetRoles;
    }
}

export default getters;
