
























import {Component, Vue} from "vue-property-decorator";
import KawaiiCamera from "@/components/infrastructures/molecules/KawaiiCamera.vue";
import KawaiiSoundPlayer from "@/components/infrastructures/molecules/KawaiiSoundPlayer.vue";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import DMVPlayListPlayer from "@/components/domains/esthe/DVMPlayListPlayer.vue";
import SmartSoundPlayer from "@/components/infrastructures/atoms/SmartSoundPlayer.vue";

@Component({
  components: {
    SmartSoundPlayer, DVMPlayListPlayer: DMVPlayListPlayer, DMVPlayer, KawaiiSoundPlayer, KawaiiCamera
  }
})
export default class PlaygroundPage extends Vue {
  name = "PlaygroundPage";
  image = "";

  soundSources = [
    {
      id: "1",
      src: "https://aiesthe.s3.ap-northeast-1.amazonaws.com/sounds/therapy/4/%E3%80%8A3%E3%81%A4%E3%81%AE%E5%B0%8F%E5%93%81%E3%80%8B%E3%81%8B%E3%82%89+%E7%B7%B4%E7%BF%92%E6%9B%B2+%E5%AC%B0%E3%83%8F%E7%9F%AD%E8%AA%BF+Op.2-1_+%E6%B0%97%E5%88%86%E8%BB%A2%E6%8F%9B%E6%96%B0demo3%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%A3%E3%83%BC%E3%83%92%E3%82%99%E3%83%B3%EF%BC%9A%E3%80%8A3%E3%81%A4%E3%81%AE%E5%B0%8F%E5%93%81%E3%80%8B%E3%81%8B%E3%82%89+%E7%B7%B4%E7%BF%92%E6%9B%B2+%E5%AC%B0%E3%83%8F%E7%9F%AD%E8%AA%BF+Op.2-1_%E3%83%9F%E3%83%83%E3%82%AF%E3%82%B9%E3%82%BF%E3%82%99%E3%82%A6%E3%83%B3.mp3"
    },
    {
      id: "2",
      src: "https://aiesthe.s3.ap-northeast-1.amazonaws.com/sounds/therapy/4/%E3%82%A8%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%82%99_+51%E4%B8%A1%E6%96%B9+%E6%B0%97%E5%88%86%E8%BB%A2%E6%8F%9B%E3%82%B7%E3%83%A7%E3%83%8F%E3%82%9A%E3%83%B3%E3%82%A8%E3%83%81%E3%83%A5%E3%83%BC%E3%83%88%E3%82%99.mp3"
    }
  ];
}
