





import {Component, VModel, Vue} from "vue-property-decorator";
import {marked} from "marked";

@Component
export default class MarkdownViewer extends Vue {
  name = "MarkdownViewer";

  @VModel() markdown: string;

  get getText() {
    return marked(this.markdown);
  }
}
