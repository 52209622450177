




















































































































import {Component, Prop, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import AIEstheDetectFaceResultCard from "@/components/domains/esthe/aiesthe/AIEstheDetectFaceResultCard.vue";
import {CalendarType} from "@/components/domains/calender/calendarType";
import CalteForm from "@/components/domains/calte/pic/CalteForm.vue";
import CalteFormat from "@/components/domains/calte/calteFormat";
import {CalendarSpecification} from "@/components/domains/calender/calendarSpecification";
import CalteRecord from "@/components/domains/calte/calteRecord";
import {CalteFormatConverter} from "@/components/domains/calte/calteFormatConverter";
import DMVRecordResultCard from "@/components/domains/esthe/dmv/DMVRecordResultCard.vue";

@Component({
  components: {DMVRecordResultCard, CalteForm, AIEstheDetectFaceResultCard, KawaiiDialog}
})
export default class KawaiiCalendar extends Vue {

  name = 'KawaiiCalendar';

  @Prop({default: () => []}) userIds: string[];
  @Prop({default: false}) showUsername: boolean;

  // Calendar
  type = 'month';
  // types: string[] = ['month', 'week', 'day', '4day'];
  types: {}[] = [
    {text: '月', value: 'month'},
    {text: '週', value: 'week'},
    {text: '日', value: 'day'},
  ];

  mode = 'stack';
  weekday: number[] = [0, 1, 2, 3, 4, 5, 6];

  display = 0;
  displays: {}[] = [
    {text: 'すべて', value: 0},
    {text: 'カルテ記録', value: 1},
    {text: 'AI エステ', value: 2},
    {text: 'DMV 体験', value: 3},
  ];

  value = '';
  events: {}[] = [];
  colors: string[] = ['accent', 'accent darken-1', 'accent darken-2'];

  // Dialog
  showAiEstheDialog = false;
  aiEstheDialogResult = {};
  aiEstheDialogMessage = '';

  calteFormat = new CalteFormat();
  calteUsername = '';
  calteRecordId = '';
  showCalteDialog = false;
  calteDialogResult = {};
  calteDialogMessage = '';

  submitDialog = false;
  submitMessage = '';
  submitError = false;

  dmvResult = {};
  dmvUsername = '';
  showDMVDialog = false;
  dmvDialogMessage = '';

  resetDialog() {
    this.showAiEstheDialog = false
    this.aiEstheDialogResult = {};
    this.aiEstheDialogMessage = '';

    this.calteFormat = new CalteFormat();
    this.calteUsername = '';
    this.calteRecordId = '';
    this.showCalteDialog = false;
    this.calteDialogResult = {};
    this.calteDialogMessage = '';

    this.dmvResult = {};
    this.dmvUsername = '';
    this.showDMVDialog = false;
    this.dmvDialogMessage = '';

    this.submitDialog = false;
    this.submitMessage = '';
    this.submitError = false;
  }

  // ev: {start, end}
  private getEvents(ev: any) {

    if (this.userIds.length === 0) {
      return;
    }

    this.events = [];

    const request = {
      userIds: this.userIds,
      since: ev.start.date,
      until: ev.end.date
    };

    httpResource.post(`/calendar/esthe-between`, request)
        .then((response: any) => {
          const events = response.data;
          this.events = events;
        })
        .catch((error: any) => {
          // NOP
        });
  }

  private showEvent(ev: any) {

    this.resetDialog();

    if (CalendarType.AI_ESTHE.is(ev.event)) {

      httpResource.get(`aiesthe/detect-result?detectId=${ev.event.detectId}`)
          .then((response: any) => {
            this.aiEstheDialogResult = response.data;
          })
          .catch(() => {
            this.aiEstheDialogMessage = "読み込みに失敗しました。"
          })
          .finally(() => {
            this.showAiEstheDialog = true;
          });

    } else if (CalendarType.CALTE_RECORD.is(ev.event)) {

      const calteRecordId = ev.event.recordId;

      this.calteRecordId = calteRecordId;

      httpResource.get(`common/calte/username?calteRecordId=${calteRecordId}`)
          .then((response: any) => {
            this.calteUsername = response.data;
          });

      // カルテの画像取得
      httpResource.get(`aiesthe/detect-result-from-calte-record-id?calteRecordId=${calteRecordId}`)
          .then((response: any) => {
            this.aiEstheDialogResult = response.data;
          });

      httpResource.get(`common/calte/get-format?calteRecordId=${calteRecordId}`)
          .then((response: any) => {
            const id = response.data.calteId;
            const title = response.data.title;
            const items = response.data.calteItems;
            const calteFormat = CalteFormat.createWithId(id, title, items);
            this.calteFormat = calteFormat;
          })
          .catch((error: any) => {
            this.calteDialogMessage = "カルテが見つかりませんでした。";
          })
          .finally(() => {
            this.showCalteDialog = true;
          });

    } else if (CalendarType.DMV_EXP.is(ev.event)) {

      const dmvRecordId = ev.event.recordId;

      httpResource.get(`dmv/record-result?recordId=${dmvRecordId}`)
          .then((response: any) => {
            this.dmvUsername = response.data.username;
            this.dmvResult = response.data;
          })
          .catch((error: any) => {
            this.dmvDialogMessage = "DMV 体験結果が見つかりませんでした。";
          })
          .finally(() => {
            this.showDMVDialog = true;
          });
    } else if (CalendarType.SOUND_EXP.is(ev.event)) {

      const dmvRecordId = ev.event.recordId;

      httpResource.get(`soundesthe/record-result?recordId=${dmvRecordId}`)
          .then((response: any) => {
            this.dmvUsername = response.data.username;
            this.dmvResult = response.data;
          })
          .catch((error: any) => {
            this.dmvDialogMessage = "DMV 体験結果が見つかりませんでした。";
          })
          .finally(() => {
            this.showDMVDialog = true;
          });
    }
  }

  private moreEvent(ev: any) {
    this.value = ev.date;
    this.type = 'day';
  }

  private getEventColor(event: any) {
    return CalendarSpecification.colorOf(event);
  }

  private submitCalte() {

    const calteRecord: CalteRecord = CalteFormatConverter.format2record(this.calteRecordId, this.calteFormat);

    const request = {
      aiEstheDetectFaceResultId: this.aiEstheDialogResult === {} ?
          null : (this.aiEstheDialogResult as any).id,
      calteRecord: calteRecord
    };

    httpResource.post(`common/calte/register-record/`, request)
        .then((response: any) => {
          this.submitMessage = '送信に成功しました。';
        })
        .catch((error: any) => {
          this.submitMessage = '送信に失敗しました。';
          this.submitError = true;
        })
        .finally(() => {
          this.submitDialog = true;
        });
  }
}
