

















import {Component, Vue} from "vue-property-decorator";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import {User} from "@/components/domains/authorization/user";
import {AuthGetterTypes} from "@/store/auth/types";

@Component({
  components: {HoverLinkCard}
})
export default class Settings extends Vue {
  name = "Settings";
  user: User = this.$store.getters[AuthGetterTypes.getCurrentUser];
}
