






































































import {Component, Vue} from "vue-property-decorator";
import HomeTemplate from "@/components/templates/home/HomeTemplate.vue";
import KawaiiTitle from "@/components/infrastructures/atoms/KawaiiTitle.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import Header from "@/components/templates/default/DefaultHeader.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import authService from "@/components/domains/authorization/authService";

@Component({
  components: {PrimaryBtn, ContentTitle, KawaiiTitle, HomeTemplate}
})
export default class SupportPage extends Vue {
  name = "SupportPage";
  links = Header.links;

  get isPremiumAccount() {
    return authService.isPremiumAccount();
  }
}
