








import {Component, Vue} from "vue-property-decorator";
import LoggedInTemplate from "@/components/templates/loggedin/LoggedInTemplate.vue";
import MenuPanel from "@/components/domains/account/mypage/MenuPanel.vue";
import MentalCheckInductionHoverLink from "@/components/domains/mentalcheck/MentalCheckInductionHoverLink.vue";
import authService from "@/components/domains/authorization/authService";
import MentalCheckInductionCard from "@/components/domains/mentalcheck/MentalCheckInductionCard.vue";

@Component({
  components: {MentalCheckInductionCard, MentalCheckInductionHoverLink, MenuPanel, LoggedInTemplate}
})
export default class MenuPage extends Vue {
  name = "MenuPage";

  get isLoggedIn(): boolean {
    return authService.isLoggedIn();
  }
}
