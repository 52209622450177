







































import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import CustomerSearchTable from "@/components/infrastructures/molecules/CustomerSearchTable.vue";
import {AccountService} from "@/components/domains/account/AccountService";
import {AccountTransferGetterTypes, AccountTransferMutationTypes} from "@/store/account/transfer/types";
import {User} from "@/components/domains/authorization/user";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";

@Component({
  components: {KawaiiButton, CustomerSearchTable}
})
export default class SelectCustomerStep extends Step {

  getComponentName(): string {
    return this.name;
  }

  name = "SelectCustomerStep";

  // 検索条件
  condition = "";

  // Table
  options = {};
  loading = false;
  total = 0;
  selected = [];
  headers = [
    {text: "顧客ID", value: "id"},
    {text: "顧客名", value: "name"},
    {text: "団体名", value: "agency"},
    {text: "メールアドレス", value: "email"}
  ];
  items = [];

  get getItems() {
    return this.items;
  }

  mounted() {
    this.searching();
  }

  @Watch("options")
  search() {

    if (this.loading) {
      return;
    }

    const page = (this.options as any).page;
    const itemsPerPage = (this.options as any).itemsPerPage;

    if (itemsPerPage == -1) {
      return;
    }

    this.loading = true;

    AccountService.searchCustomers(page, itemsPerPage, this.condition)
        .then((response: any) => {
          const data = response.data;
          this.total = data.size;
          this.items = data.users;
        })
        .finally(() => {
          this.loading = false;
        });
  }

  @Watch("condition")
  searching() {
    this.search();
  }

  @Watch("selected")
  storing() {
    if (this.selected.length !== 1) {
      this.$store.commit(AccountTransferMutationTypes.setUser, new User());
    } else {
      const parsedobj = JSON.parse(JSON.stringify(this.selected[0]))
      this.$store.commit(AccountTransferMutationTypes.setUser, parsedobj as User);
    }
    this.checkComplete();
  }

  validate() {
    const user = this.$store.getters[AccountTransferGetterTypes.getUser];
    return !user.isEmpty();
  }

  getStepTitle(): string {
    return "顧客選択";
  }

  next() {
    super.next();
  }
}
