













import {Component, Vue} from "vue-property-decorator";
import AdminRegisterForm from "@/components/domains/account/register/AdminRegisterForm.vue";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";

@Component({
  components: {DefaultTemplate, AdminRegisterForm, GeneralField}
})
export default class AdminRegisterPage extends Vue {
  name: "AdminRegisterPage";
}
