import MentalCheckQuestionResult
  from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/mentalCheckQuestionResult";

export default class MentlCheckImageSelector {

  private static imgRoot = '/img/aiesthe/dr_ai_healthcare/';

  public static selectImagePath(mentalCheckQuestionResult: number): string {
    switch (mentalCheckQuestionResult) {
      case MentalCheckQuestionResult.HEAD: {
        return this.imgRoot + 'head.png';
      }
      case MentalCheckQuestionResult.EYES: {
        return this.imgRoot + 'eyes.png';
      }
      case MentalCheckQuestionResult.NECK: {
        return this.imgRoot + 'neck.png';
      }
      case MentalCheckQuestionResult.IN_STOMACH: {
        return this.imgRoot + 'in_stomach.png';
      }
      case MentalCheckQuestionResult.SURFACE_STOMACH: {
        return this.imgRoot + 'surface_stomach.png';
      }
      case MentalCheckQuestionResult.LEGS: {
        return this.imgRoot + 'legs.png';
      }
      case MentalCheckQuestionResult.KNEES: {
        return this.imgRoot + 'knees.png';
      }
      case MentalCheckQuestionResult.HEART: {
        return this.imgRoot + 'heart.png';
      }
      case MentalCheckQuestionResult.NOTHING: {
        return this.imgRoot + 'nothing.png';
      }
    }
    return '';
  }
}
