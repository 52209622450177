







import {Component, Prop, Vue} from "vue-property-decorator";
import {User} from "@/components/domains/authorization/user";
import CreditCardRegister from "@/components/domains/payment/creditcard/CreditCardRegister.vue";
import CreditCardForm from "@/components/domains/payment/creditcard/CreditCardForm.vue";

@Component({
  components: {CreditCardForm, CreditCardRegister}
})
export default class CreditCardInformation extends Vue {
  name = "CreditCardInformation";

  @Prop() user!: User;
}
