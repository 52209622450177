

































import {Component, Vue, Watch} from "vue-property-decorator";
import AIEstheDetectFaceResultCard from "@/components/domains/esthe/aiesthe/AIEstheDetectFaceResultCard.vue";
import DateTimeFormatter from "@/components/infrastructures/functions/time/dateTimeFormatter";
import AIEstheService from "@/components/domains/esthe/aiesthe/aiEstheService";

@Component({
  components: {AIEstheDetectFaceResultCard}
})
export default class AIEstheDetectFaceHistory extends Vue {
  name = "AIEstheDetectFaceHistory";

  hiddenList = false;

  headers = [
    // {text: "分析結果ID", value: "id"},
    {text: "記録日", value: "latestUpdate"}
  ];

  options: any = {}
  items = []
  selectedItems = []
  item: any = {}
  total = 0
  loading = false

  result = {}

  mounted() {
    this.getDataFromApi();
  }

  setDetectResult(data: {}) {
    this.result = data;
  }

  formatDate(str: string) {
    return DateTimeFormatter.formatFromStr(str);
  }

  @Watch("options")
  getDataFromApi() {
    this.loading = true
    this.apiCall().then((data: any) => {
      this.total = data.total;
      this.items = data.items;
    }).catch(error => {
      // NOP
    }).finally(() => {
      this.loading = false;
    })
  }

  async apiCall() {
    const {page, itemsPerPage} = this.options;
    return AIEstheService.historyPagingSize(page, itemsPerPage);
  }

  @Watch("selectedItems")
  private watchSelected(selectedItems: any) {
    if (selectedItems.length == 0) {
      this.setDetectResult({});
      return;
    }
    this.item = selectedItems[0];
    AIEstheService.detectFaceResult(this.item.id)
        .then((response: any) => {
          this.setDetectResult(response.data);
        });
  }
}
