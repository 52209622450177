





















































import {Component, VModel, Vue, Watch} from "vue-property-decorator";
import FieldData from "@/components/infrastructures/functions/fieldData";
import Address from "@/components/domains/account/profile/address/address";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import ContentTitleField from "@/components/infrastructures/atoms/designed/ContentTitleField.vue";
import AddressService from "@/components/domains/account/profile/address/addressService";
import DecideBtn from "@/components/infrastructures/atoms/designed/DecideBtn.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import Overlay from "@/components/infrastructures/atoms/designed/Overlay.vue";

@Component({
  components: {Overlay, SubBtn, DecideBtn, ContentTitleField, LabelField: ContentTitleField, ContentTitle}
})
export default class AddressForm<T extends Address> extends Vue {

  name = "AddressForm";

  @VModel() modelVal!: T;
  titleBaseSize = 25;

  prefectures: { value: string; text: string }[] = [];
  municipalities: { value: string; text: string }[] = [];
  disabledAddressAutomationInput = true;

  // Overlay
  overlay = false;

  public txtPostcode = new FieldData()
      .withId('postcode_first')
      .withType('text_field')
      .withMaxLength(7);

  public slctPrefecture = new FieldData()
      .withId('prefecture')
      .withType('select');

  public slctMunicipality = new FieldData()
      .withId('municipality')
      .withType('select');

  public txtAddress = new FieldData()
      .withId('address')
      .withType('text_field')
      .withMaxLength(200);

  public txtBuilding = new FieldData()
      .withId('building')
      .withType('text_field')
      .withMaxLength(50);

  public txtRoomNumber = new FieldData()
      .withId('roomNumber')
      .withType('text_field')
      .withMaxLength(20);

  public updateAddress(): void {
    this.modelVal.postcodeFirst = this.txtPostcode.value.substring(0, 3);
    this.modelVal.postcodeLast = this.txtPostcode.value.substring(3, 7);
    this.modelVal.prefectureCode = this.slctPrefecture.value;
    this.modelVal.municipalityCode = this.slctMunicipality.value;
    this.modelVal.address = this.txtAddress.value;
    this.modelVal.buildingName = this.txtBuilding.value;
    this.modelVal.roomNumber = this.txtRoomNumber.value;
  }

  public reset(): void {
    this.txtPostcode.withValue("");
    this.slctPrefecture.withValue("");
    this.slctMunicipality.withValue("");
    this.txtAddress.withValue("");
    this.txtBuilding.withValue("");
    this.txtRoomNumber.withValue("");
  }

  mounted() {
    AddressService.fetchPrefectures().then(prefectures => {
      this.prefectures = prefectures;
      this.slctPrefecture.withSelection(this.prefectures);
      this.initializeValue();
    })
  }

  public initializeValue() {
    if (this.modelVal.prefectureCode !== '') {
      this.slctPrefecture.withValue(this.modelVal.prefectureCode);
    }
    if (this.modelVal.municipalityCode !== '') {
      this.slctMunicipality.withValue(this.modelVal.municipalityCode);
    }
    if (this.modelVal.postcodeFirst !== '' && this.modelVal.postcodeLast !== '') {
      this.txtPostcode.withValue(this.modelVal.postcodeFirst + this.modelVal.postcodeLast);
    }
    if (this.modelVal.address !== '') {
      this.txtAddress.withValue(this.modelVal.address);
    }
    if (this.modelVal.buildingName !== '') {
      this.txtBuilding.withValue(this.modelVal.buildingName);
    }
    if (this.modelVal.roomNumber !== '') {
      this.txtRoomNumber.withValue(this.modelVal.roomNumber);
    }
  }

  private fetchAddress() {

    this.overlay = true;
    this.disabledAddressAutomationInput = true;

    this.slctPrefecture.withDisabled(true);
    this.slctMunicipality.withDisabled(true);
    this.txtAddress.withDisabled(true);

    AddressService.searchFromPostcode(this.txtPostcode.value)
        .then((address: Address) => {
          this.slctPrefecture.withValue(address.prefectureCode);
          this.slctMunicipality.withValue(address.municipalityCode);
          this.txtAddress.withValue(address.address);
        })
        .catch((error) => {
          alert(error.response.data.message)
        })
        .finally(() => {
          this.slctPrefecture.withDisabled(false);
          this.slctMunicipality.withDisabled(false);
          this.txtAddress.withDisabled(false);
          this.updateDisabledAddressAutomationInput();
          this.overlay = false;
        });
  }

  @Watch('txtPostcode.value')
  private updateDisabledAddressAutomationInput() {
    if (this.txtPostcode.getStringValue().length != 7) {
      this.disabledAddressAutomationInput = true;
    } else {
      this.disabledAddressAutomationInput = false;
    }
  }

  @Watch('slctPrefecture.value')
  private async updateMunicipality() {
    this.municipalities = (await AddressService.fetchMunicipalities(this.slctPrefecture.value));
    this.slctMunicipality.withSelection(this.municipalities);
  }
}
