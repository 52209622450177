import CalteFormat from "@/components/domains/calte/calteFormat";
import CalteRecord from "@/components/domains/calte/calteRecord";

export interface CalteState {
    selectedCustomer: {};
    calteFormat: CalteFormat;
    calteRecord: CalteRecord;
    aiEstheFaceDetectResult: {};
}

export enum CalteGetterTypes {
    getSelectedCustomer = "CalteGetSelectedCustomer",
    getCalteFormat = "CalteGetFieldItems",
    getCalteRecord = "CalteGetCalteRecord",
    getAIEstheFaceDetectResult = "CalteGetAIEstheFaceDetectResult"
}

export enum CalteMutationTypes {
    setSelectedCustomer = "CalteSetSelectedCustomer",
    setCalteFormat = "CalteSetFormat",
    setCalteRecord = "CalteSetCalteRecord",
    setAIEstheFaceDetectResult = "CalteSetAIEstheFaceDetectResult"
}

export enum CalteActionTypes {
    registerCalteRecord = "CalteRegisterCalteRecord",
    registerCalteFormat = "CalteRegisterCalteFormat",
    registerCalteTemplate = "CalteRegisterCalteTemplate",
    searchCalteFormat = "CalteSearchCalteFormat",
    clear = "CalteClear"
}
