




import {Component, Vue} from "vue-property-decorator";
import AgreementService from "@/components/domains/agreement/agreementService";
import MarkdownViewer from "@/components/infrastructures/atoms/MarkdownViewer.vue";

@Component({
  components: {MarkdownViewer}
})
export default class ProvidingDataAgreementText extends Vue {
  name = "ProvidingDataAgreementText";

  markdown = '';

  get getMarkdown() {
    return this.markdown
  }

  mounted() {
    AgreementService.providingDataMarkdown()
        .then(obj => {
          this.markdown = obj.data;
        });
  }
}
