import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'md',
    theme: {
        themes: {
            light: {
                primary: "#fccbcf",
                secondary: "#ef8893",
                accent: "#f6c0d1",
                link: "#ef8893",
                error: "#ff5722",
                warning: "#ffc107",
                info: "#cadef6",
                success: "#c0f6d1",
                aiesthe: "#78C7F8",
            },
        },
    },
});
