



































import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import Vue from "vue";
import {AIEstheActionTypes, AIEstheGetterTypes, AIEstheMutationTypes} from "@/store/aiesthe/types";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiCamera from "@/components/infrastructures/molecules/KawaiiCamera.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn, BackBtn, KawaiiCamera, KawaiiButton, VerticalSlotContents}
})
export default class BeforeCameraStep extends Step {

  name = "BeforeCameraStep";
  image = "";
  disabledSent = true;
  dialog = false;
  overlay = false;
  sentError = false;

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {
    this.$store.dispatch(AIEstheActionTypes.getCurrentStep).then(() => {
      const after = this.$store.getters[AIEstheGetterTypes.isAfter];
      if (after) {
        this.next();
      }
    })
  }

  getStepTitle(): string {
    return "AIエステ.顔診断エステ.Before撮影";
  }

  getStepSubTitle(): string {
    return "振動体験前の写真撮影を行います。";
  }

  sendImage() {
    this.overlay = true;
    this.$store.dispatch(AIEstheActionTypes.sendImage).then(() => {
      this.dialog = true;
    }).catch((error) => {
      alert('写真の読み取りに失敗しました。もう一度お試しください。');
      this.sentError = true;
    }).finally(() => {
      this.overlay = false;
    })
  }

  decide() {
    this.dialog = false;
    this.overlay = false
    this.next();
  }

  @Watch('image')
  private watchImage() {
    this.$store.commit(AIEstheMutationTypes.setImage, this.image)
    this.disabledSent = this.image === "";
  }
}
Vue.component("BeforeCameraStep", BeforeCameraStep);
