

























































import {Component, Watch} from "vue-property-decorator";
import AuthMixin from "@/components/domains/authorization/authMixin"
import SubscriptionStateTable from "@/components/domains/payment/status/SubscriptionStateTable.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiDaialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import PaymentService from "@/components/domains/payment/paymentService";

@Component({
  components: {
    SubBtn,
    PrimaryBtn,
    MyPageContentTemplate, SubscriptionStateTable, KawaiiButton, KawaiiDaialog
  }
})
export default class SubscriptionState extends AuthMixin {

  public name = "SubscriptionState";
  productList: any[] = [];
  updateKey = false;
  clicked = false;
  btnDisabled = true
  hasError = false;
  title = "";
  message = "";
  selectedSubscriptions = [];
  prevCancelSubs = [];
  confirmationDialog = false;
  resultDialog = false;
  overlay = false
  headers = [
    {text: "契約者", value: "contractorName"},
    {text: "使用者名", value: "userName"},
    {text: "商品名", value: "productDisplayName"},
    {text: "解約予定日", value: "ifCancelAt"},
  ];

  @Watch("selectedSubscriptions")
  enableBtn() {
    this.btnDisabled = this.selectedSubscriptions.length === 0
  }

  async cancel() {
    this.initialDialog()
    this.overlay = true

    const request = {
      historyIds: this.selectedSubscriptions.map(history => (history as any).id)
    }

    PaymentService.cancel(this.selectedSubscriptions)
        .then(() => {
          this.title = "";
          this.message = "解約に成功しました。";
          this.hasError = false
        }).catch((error: any) => {
      this.title = "解約に失敗しました。";
      this.message = error.response.data.message;
      this.hasError = true
    }).finally(() => {
      this.overlay = false
      this.resultDialog = true
      this.refreshTable()
    })
    this.confirmationDialog = false
  }

  updatePrevCancel() {

    this.confirmationDialog = false;

    // 解約した場合の情報を取得
    PaymentService.previewCancel(this.selectedSubscriptions)
        .then(response => {
          this.prevCancelSubs = response.data;
          this.confirmationDialog = true;
        })
        .catch((error: any) => {
          this.message = error.response.data.message;
          this.hasError = true
          this.overlay = false
          this.resultDialog = true;
        });
  }

  initialDialog() {
    this.overlay = false
    this.confirmationDialog = false;
    this.resultDialog = false;
    this.hasError = false;
    this.title = "";
    this.message = "";
  }

  refreshTable() {
    this.selectedSubscriptions = []
    this.updateKey = !this.updateKey
  }
}
