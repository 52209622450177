














import {Component, VModel} from "vue-property-decorator";
import {VTextField} from "vuetify/lib";

@Component
export default class PasswordField extends VTextField {

  name = "PasswordField";

  @VModel() password!: string;

  show = false;
}
