




























import {Component, Vue} from "vue-property-decorator";
import LoggedInHeader from "@/components/templates/loggedin/LoggedInHeader.vue";
import DefaultHeader from "@/components/templates/default/DefaultHeader.vue";
import Copyright from "@/components/infrastructures/atoms/Copyright.vue";
import ErrorHandler from "@/components/infrastructures/functions/error/errorHandler";
import AuthService from "@/components/domains/authorization/authService";
import AiChatButton from "@/components/domains/ai_chat/AiChatButton.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle, AiChatButton, Copyright, DefaultHeader, LoggedInHeader}
})
export default class LoggedInTemplate extends Vue {

  name = "LoggedInTemplate";

  get isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  get isAdmin(): boolean {
    return AuthService.isAdmin();
  }

  get isPremium(): boolean {
    return AuthService.isPremiumAccount();
  }

  beforeMount() {
    ErrorHandler.define((response: any) => {
      // TODO 重複ログイン暫定
      if (response.status == 500
          && response.data.message
          && response.data.message.startsWith("重複ログイン")) {
        alert(response.data.message)
        AuthService.logout();
        this.$router.push('/');
      }
    })
  }
}
