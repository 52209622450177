
















import {Component} from "vue-property-decorator";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle}
})
export default class ContentTitleField extends ContentTitle {
  name = "ContentTitleField";
}
