import {MutationTree} from "vuex";
import {DmvMutationTypes, DmvState} from "@/store/dmv/types";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";

const mutations: MutationTree<DmvState> = {
    [DmvMutationTypes.setSelectedWaves]: (state, subWaves: IndexedAudio[]) => {
        state.subWaves = subWaves;
    },
    [DmvMutationTypes.setSelectedWave]: (state, subWave: IndexedAudio) => {
        state.subWaves = [subWave];
    },
    [DmvMutationTypes.setPlayingMain]: (state, playing: boolean) => {
        state.playingMain = playing;
    },
    [DmvMutationTypes.setLoopSelectedWave]: (state, loop: boolean) => {
        state.loop = loop;
    }
}

export default mutations;
