











import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {Component, Vue} from "vue-property-decorator";
import ForgotPasswordConfirmMailForm
  from "@/components/domains/account/forgot_password/steps/ForgotPasswordConfirmMailForm.vue";
import ForgotPasswordResetPasswordForm
  from "@/components/domains/account/forgot_password/steps/ForgotPasswordResetPasswordForm.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";

@Component({
  components: {DefaultTemplate, ContentTitle}
})
export default class ForgotPasswordPage extends Vue {

  name = "ForgotPasswordPage";

  steps: Step[] = [];
  createdStep = false;

  mounted() {
    this.steps = [
      new ForgotPasswordConfirmMailForm(),
      new ForgotPasswordResetPasswordForm()
    ];
    this.createdStep = true;
  }
}
