





































import {Component, Watch} from "vue-property-decorator";
import FieldData from "@/components/infrastructures/functions/fieldData";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {AuthActionTypes} from "@/store/auth/types";
import router from "@/router";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import AgreementsStore from "@/components/domains/account/register/general/agreementsStore";
import {PurchaseMutationTypes} from "@/store/product/purchase/types";

@Component({
  components: {GeneralField}
})
export default class PersonalRegisterForm extends Step {

  name = "PersonalRegisterForm";
  email = "";

  // TODO default: false <- 同意済み
  approval = true;

  // error
  errorMessage = "";
  hasError = false;

  dialog = false;

  private usernameField: FieldData = new FieldData()
      .withId("username")
      .withRequires(true)
      .withType("text_field")
      .withLabel("ユーザー名 (半角英数字)");

  private loginIdField: FieldData = new FieldData()
      .withId("loginId")
      .withRequires(true)
      .withType("text_field")
      .withLabel("メールアドレス");

  private passwordField: FieldData = new FieldData()
      .withId("password")
      .withRequires(true)
      .withType("password")
      .withLabel("パスワード (8文字以上)");

  private verifyPasswordField: FieldData = new FieldData()
      .withId("verify_password")
      .withRequires(true)
      .withType("password")
      .withLabel("確認用パスワード");

  private registerBtnField: FieldData = new FieldData()
      .withId("registerBtn")
      .withType("button")
      .withLabel('登録')
      .withClickAction(this.register);

  private resetBtnField: FieldData = new FieldData()
      .withId("resetBtn")
      .withType("back_button")
      .withLabel('リセット')
      .withClickAction(this.reset);

  getStepTitle(): string {
    return "ユーザー情報の登録";
  }

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {

    const path = this.$route.params.path;
    this.hasError = false;

    httpResource.post(`/account/confirm-temp-url?path=${path}`)
        .then((response: any) => {
          this.email = response.data;
          this.loginIdField.value = this.email;
        })
        .catch((error: any) => {
          this.errorMessage = error.response.data.message;
          this.hasError = true;
        })
  }

  @Watch('passwordField.value')
  private u1() {
    this.validatePassword();
  }

  @Watch('verifyPasswordField.value')
  private u2() {
    this.validatePassword();
  }

  validatePassword() {
    const password = this.passwordField.value.trim();
    const verifyPassword = this.verifyPasswordField.value.trim();
    this.passwordField.resetError();
    this.verifyPasswordField.resetError();
    if (password !== '' && verifyPassword !== '' && password !== verifyPassword) {
      this.passwordField.error = true;
      this.passwordField.errorMessage = 'パスワードが一致しません';
      this.verifyPasswordField.error = true;
      this.verifyPasswordField.errorMessage = 'パスワードが一致しません';
    }
  }

  register() {

    this.resetFormErrors();

    const data = {
      email: this.loginIdField.value,
      username: this.usernameField.value,
      password: this.passwordField.value,
      verifyPassword: this.verifyPasswordField.value,
      agreeTermsOfService: AgreementsStore.agreeTermsOfService,
      agreeProvidingData: AgreementsStore.agreeProvidingData
    };

    httpResource.post('/account/register/general', data)
        .then((response: any) => {
          const loginId = this.usernameField.value;
          const password = this.passwordField.value;
          this.$store.dispatch(AuthActionTypes.login, {"loginId": loginId, "password": password})
              .then(() => {
                if (response.data.productId) {
                  this.$store.commit(PurchaseMutationTypes.setProductId, response.data.productId);
                  alert("アカウントの作成が完了しましたので、選択したプランの決済ページに遷移します。");
                  router.push("/payment");
                } else {
                  router.push("/mypage");
                }
              });
        })
        .catch((error: any) => {
          alert(error.response.data.message);
        });
  }

  reset() {
    this.resetFormErrors();
    this.usernameField.withValue('');
    this.passwordField.withValue('');
    this.verifyPasswordField.withValue('');
  }

  resetFormErrors() {
    this.resetErrors();
    this.loginIdField.withError(false);
    this.loginIdField.withErrorMessage('');
    this.passwordField.withError(false);
    this.passwordField.withErrorMessage('');
  }

  resetErrors() {
    this.hasError = false;
  }
}
