










































import {Component, Vue, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import SearchDialog, {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

export class CustomerSearchAction implements SearchAction {

  async action(condition: string): Promise<[{}]> {
    const response = await httpResource.get(`pic/calte/customers?condition=${condition}`);
    return response.data.users;
  }
}

@Component({
  components: {SearchDialog}
})
export default class ChooseCustomerStep extends Step {

  name = "ChooseCustomerStep";

  getComponentName(): string {
    return this.name;
  }

  onDecideAction: Function;
  selectedCustomer: any;
  customers: any[] = [];

  headers = [
    {text: "顧客ID", value: "id"},
    {text: "顧客名", value: "name"},
    {text: "メールアドレス", value: "email"}
  ]

  searchCustomerAction: SearchAction = new CustomerSearchAction();

  getStepTitle(): string {
    return "顧客選択";
  }

  // beforeMount () {
  //   this.selectedCustomer = this.$store.getters[CalteGetterTypes.getSelectedCustomer];
  //   this.customers = [this.selectedCustomer];
  // }

  onDecide(customer: any) {
    this.selectedCustomer = customer;
    this.onDecideAction(customer);
    // this.$store.commit(CalteMutationTypes.setSelectedCustomer, customer);
    this.checkComplete()
  }

  validate(): boolean {

    const existsKeys = Object.keys(this.selectedCustomer).length > 0;
    const existsUser = existsKeys && this.selectedCustomer.name != '';

    if (existsUser) {
      this.customers = [this.selectedCustomer];
    }

    return existsUser;
  }

  @Watch("selectedCustomer")
  protected checkCompleteOverride() {
    this.checkComplete();
  }
}
Vue.component("ChooseCustomerStep", ChooseCustomerStep);
