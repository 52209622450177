import {ActionTree} from "vuex";
import {AuthActionTypes, AuthMutationTypes, AuthState} from "@/store/auth/types";
import {RootState} from "@/store/type";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import store from "@/store";
import {MyPageService} from "@/components/domains/account/mypage/myPageService";
import PageSizeService from "@/components/domains/settings/pagesize/pageSizeService";

const INTERVAL_MILLI_SECONDS = 1800000; // 30 minutes

const actions: ActionTree<AuthState, RootState> = {
    [AuthActionTypes.logout]: async ({dispatch}) => {
        await dispatch(AuthActionTypes.performLogout);
        await httpResource.get('auth/logout/');
    },
    [AuthActionTypes.performLogout]: async ({commit, getters}) => {
        const intervalName = getters.getIntervalName
        if (intervalName) {
            clearInterval(intervalName);
        }
        commit(AuthMutationTypes.setAuthenticated, false);
        commit(AuthMutationTypes.clearCurrentUser);
    },
    [AuthActionTypes.login]: async ({dispatch}, {loginId, password}) => {
        const loginRequest = {
            loginId: loginId,
            password: password
        };
        const response = await httpResource.post(
            "auth/login/",
            loginRequest
        );
        if (response.status === 200) {
            await dispatch(AuthActionTypes.authenticateUser);
            return true;
        }
        return false;
    },
    [AuthActionTypes.verifyLogIn]: async ({dispatch}) => {
        if (!store.getters.getIsAuthenticated) {
            await dispatch(AuthActionTypes.authenticateUser);
            return store.getters.getIsAuthenticated;
        }
        return store.getters.getIsAuthenticated;
    },
    [AuthActionTypes.refreshTokenInterval]: async ({dispatch}) => {
        try {
            const response = await httpResource.post("/auth/refresh/");
            if (response.status !== 200) {
                await dispatch(AuthActionTypes.performLogout);
            }
        } catch (error) {
            console.warn('トークンのリフレッシュに失敗しました。');
            // await dispatch(AuthActionTypes.logout);
        }
    },
    [AuthActionTypes.authenticateUser]: async ({dispatch, commit}) => {
        try {

            console.debug('認証リクエスト開始')
            const response = await httpResource.post("/auth/me/");

            if (response.status === 200 && response.data) {

                const currentUser = response.data;

                commit(AuthMutationTypes.setCurrentUser, currentUser);
                commit(AuthMutationTypes.setAuthenticated, true);

                await dispatch(AuthActionTypes.refreshTokenInterval);
                const intervalName = setInterval(async () => {
                    await dispatch(AuthActionTypes.refreshTokenInterval);
                }, INTERVAL_MILLI_SECONDS);

                commit(AuthMutationTypes.setIntervalName, intervalName);

                const myPageLinks = await dispatch(AuthActionTypes.refreshMyPageLinks);
                commit(AuthMutationTypes.setMyPageLinks, myPageLinks);

                await PageSizeService.fetchScale();

            } else {
                await dispatch(AuthActionTypes.performLogout);
            }

        } catch (error) {
            console.warn('認証に失敗しました。');
            // await dispatch(AuthActionTypes.logout);
        } finally {
            console.debug('認証リクエスト終了')
        }
    },
    [AuthActionTypes.hasRole]: async ({dispatch}, {role}) => {
        const user = store.getters.getCurrentUser;
        return user.roles.includes(role);
    },
    [AuthActionTypes.hasAuthority]: async ({dispatch}, {authority}) => {
        const user = store.getters.getCurrentUser;
        return user.authorities.includes(authority);
    },
    [AuthActionTypes.refreshMyPageLinks]: async ({dispatch}) => {
        return MyPageService.getMyPageLinks();
    },
}

export default actions;
