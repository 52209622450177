







import {Component, Vue} from "vue-property-decorator";
import QuestionData from "@/components/infrastructures/functions/questionnaire/questionData";
import StressCheckQuestionHolder from "@/components/domains/stress_check/stressCheckQuestionHolder";
import StressCheckQuestionnaire from "@/components/domains/stress_check/StressCheckQuestionnaire.vue";

@Component({
  components: {StressCheckQuestionnaire}
})
export default class FreeStressCheckPage extends Vue {

  name = "FreeStressCheckPage";

  private stressQuestions: QuestionData[] = [];

  mounted() {
    this.stressQuestions = StressCheckQuestionHolder.getStressCheckQuestions();
  }
}
