












































import {Component, Prop, Vue} from "vue-property-decorator";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";

@Component({
  components: {
    SmartDMVPlayer,
    StopWatchSoundPlayer: DMVPlayer
  }
})
export default class NatureSoundItemCard extends Vue {

  name = "NatureSoundItemCard";

  @Prop({default: {}}) item!: {
    id: string;
    title: string;
    genre: string;
    country: string;
    tags: string[];
    path: string;
  };

  @Prop({
    default: () => {
      /* NOP */
    }
  }) startedAction: Function;

  @Prop({
    default: () => {
      /* NOP */
    }
  }) stoppedAction: Function;

  @Prop({default: false}) selectMode: boolean;

  receiveDateTimeOnStarted(id: string, dateTime: StopWatchDateTime) {
    if (this.startedAction)
      this.startedAction();
  }

  receiveDateTimeOnStopped(id: string, dateTime: StopWatchDateTime) {
    if (this.stoppedAction)
      this.stoppedAction();

    const request = {
      dmvTypeCode: "02",
      planCode: Plan.RANDOM.code,
      vibrationId: this.item.id,
      startDateTime: dateTime.formattedStartDateTime,
      endDateTime: dateTime.formattedEndDateTime,
      totalPlayingTime: dateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);
  }
}
