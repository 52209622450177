export default class Address {

    public addressId = "";
    public prefectureCode = "";
    public prefectureName = "";
    public municipalityCode = "";
    public municipalityName = "";
    public postcodeFirst = "";
    public postcodeLast = "";
    public address = "";
    public buildingName = "";
    public roomNumber = "";

    public reset() {
        this.prefectureCode = "";
        this.prefectureName = "";
        this.municipalityCode = "";
        this.municipalityName = "";
        this.postcodeFirst = "";
        this.postcodeLast = "";
        this.address = "";
        this.buildingName = "";
        this.roomNumber = "";
    }

    public isEmpty() {
        return this._isEmpty(this.address);
    }

    private _isEmpty(value: string) {
        return value == null || value.trim().length == 0;
    }
}