



































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";
import {DmvActionTypes, DmvGetterTypes, DmvMutationTypes} from "@/store/dmv/types";
import HpSmallText from "@/components/infrastructures/atoms/text/HpSmallText.vue";

@Component({
  components: {HpSmallText}
})
export default class SmartSoundPlayer extends Vue {
  name = "SmartSoundPlayer";

  audio: IndexedAudio = new IndexedAudio();
  @Prop() sounds: { id: string; src: string; selected: boolean }[];
  @Prop({default: false}) selectMode: boolean;
  @Prop({default: false}) loop!: boolean;
  @Prop({default: false}) normal!: boolean;
  @Prop({default: 0}) seeked!: number;
  @Prop({default: true}) showControlPanel: boolean;
  @Prop({default: true}) showAllLoop: boolean;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Load audio.")
    }
  }) setAudioAction!: any;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Load audio.")
    }
  }) loadAction!: any;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Start playing audio.")
    }
  }) playAction!: any;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Pause audio.")
    }
  }) pauseAction!: any;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Finish playing audio.")
    }
  }) finishedAction!: any;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Ended playing audio.")
    }
  }) endedAction!: any;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Time updated.")
    }
  }) timeUpdate!: any;

  @Prop({
    default: () => function (audio: IndexedAudio) {
      console.debug("Start or Stop")
    }
  }) startStopListener!: any;

  private selected = false;
  private audioIdx = 0;
  private allLoop = true;
  private allLoopDisabled = false;

  mounted() {
    this.initAudio();
  }

  beforeUnmount() {
    if (!this.selectMode) {
      this.$store.commit(DmvMutationTypes.setPlayingMain, false);
    }
  }

  @Watch('audioIdx')
  private initAudio() {
    this.initAudioIndex(this.audioIdx);
  }

  get getQuerySelectorAudio() {
    return this.$el.querySelectorAll('audio')[this.audioIdx];
  }

  private initAudioIndex(index: number) {

    const audio = this.$el.querySelectorAll('audio')[index];

    audio.addEventListener('timeupdate', this.update);
    audio.addEventListener('loadeddata', this.load);
    audio.addEventListener('ended', this.ended);
    audio.addEventListener('pause', this.pause);
    audio.addEventListener('play', this.play);

    this.audio = new IndexedAudio();
    this.audio.index = this.audioIdx;
    this.audio.id = this.sounds[this.audioIdx].id;
    this.audio.src = this.sounds[this.audioIdx].src;
    this.audio.audioData = audio;

    this.setAudioAction(this.audio);
  }

  private load() {
    this.loadAction(this.audio);
  }

  private update() {
    this.timeUpdate(this.audio);
  }

  public pause() {

    if (!this.selectMode) {
      this.$store.commit(DmvMutationTypes.setPlayingMain, false);
    }

    this.pauseAction(this.audio);
    this.startStopListener(this.audio);

    if (!this.selectMode) {
      this.$store.dispatch(DmvActionTypes.pauseSelectedWaves);
    }
  }

  private ended() {

    if (!this.selectMode) {
      this.$store.commit(DmvMutationTypes.setPlayingMain, false);
    } else if (this.$store.getters[DmvGetterTypes.getPlayingMain]) {
      this.audio.reset();
      this.audio.start();
      return;
    }

    this.endedAction(this.audio);
    this.finishedAction(this.audio);

    if (this.loop) {
      this.initAudio();
      this.audio.audioData.currentTime = 0;
      this.audio.audioData.play();

      if (!this.selectMode) {
        this.$store.dispatch(DmvActionTypes.playSelectedWaves);
      }
    } else if (this.showAllLoop && this.allLoop) {
      this.nextSoundSrc();
      this.audio.audioData.play();

      if (!this.selectMode) {
        this.$store.dispatch(DmvActionTypes.playSelectedWaves);
      }
    }
  }

  public play() {

    if (!this.selectMode) {
      this.$store.commit(DmvMutationTypes.setPlayingMain, true);
    }

    this.playAction(this.audio);
    this.startStopListener(this.audio);

    if (!this.selectMode) {
      this.$store.dispatch(DmvActionTypes.playSelectedWaves);
    }

  }

  public nextSoundSrc() {
    this.audio.audioData.pause();
    if (this.audioIdx + 1 < this.sounds.length) {
      // next
      this.audioIdx = this.audioIdx + 1;
    } else {
      // back
      this.audioIdx = 0;
    }
    this.initAudio();

    if (this.$store.getters[DmvGetterTypes.getPlayingMain]) {
      this.audio.audioData.play();
    }
  }

  public prevSoundSrc() {
    this.audio.audioData.pause();
    if (this.audioIdx - 1 >= 0) {
      // back
      this.audioIdx = this.audioIdx - 1;
    } else {
      // next
      this.audioIdx = this.sounds.length - 1;
    }
    this.initAudio();

    if (this.$store.getters[DmvGetterTypes.getPlayingMain]) {
      this.audio.audioData.play();
    }
  }

  private selectedSounds(value: boolean) {

    if (this.audio.id !== this.sounds[this.audioIdx].id) {
      this.initAudio();
    }

    this.selected = value;

    if (this.selected) {
      this.$store.commit(DmvMutationTypes.setSelectedWave, this.audio);

      if (this.$store.getters[DmvGetterTypes.getPlayingMain]) {
        this.$store.dispatch(DmvActionTypes.playSelectedWaves);
      }
    } else {

      // チェックを押下した時点で現在再生している音源をリセット
      this.$store.dispatch(DmvActionTypes.pauseSelectedWaves);
      this.$store.commit(DmvMutationTypes.setSelectedWaves, []);
    }
  }

  @Watch('loop')
  private updateAllLoop() {
    this.allLoopDisabled = this.loop;
  }
}
