






















import {Component, Vue} from "vue-property-decorator";
import FieldData from "@/components/infrastructures/functions/fieldData";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import PasswordSpecification from "@/components/domains/account/PasswordSpecification";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class ChangingPassword extends Vue {
  name = "ChangingPassword";

  // dialog
  dialog = false;
  dialogTitle = "";
  dialogMessage = "";

  // text field
  txtCurrentPassword = new FieldData()
      .withId("currentPassword")
      .withLabel("現在のパスワード")
      .withType("password")
      .withRequires(true);

  txtNewPassword = new FieldData()
      .withId("newPassword")
      .withLabel("新しいパスワード（８文字以上）")
      .withType("password")
      .withRequires(true);

  txtNewVerifyPassword = new FieldData()
      .withId("newVerifyPassword")
      .withLabel("確認用新しいパスワード")
      .withType("password")
      .withRequires(true);

  checkPassword(): boolean {

    const password = this.txtCurrentPassword.value.trim();
    const newPass = this.txtNewPassword.value.trim();
    const newVeriPass = this.txtNewVerifyPassword.value.trim();

    this.txtCurrentPassword.error = false;
    this.txtCurrentPassword.errorMessage = "";
    this.txtNewPassword.error = false;
    this.txtNewPassword.errorMessage = "";
    this.txtNewVerifyPassword.error = false;
    this.txtNewVerifyPassword.errorMessage = "";

    if (password === '') {
      this.txtCurrentPassword.error = true;
      this.txtCurrentPassword.errorMessage = "現在のパスワードを入力してください。";
      return false;
    }

    if (!PasswordSpecification.verifyPassword(newPass)) {
      this.txtNewPassword.error = true;
      this.txtNewPassword.errorMessage = "８文字以上入力してください。";
      return false;
    }

    if (!PasswordSpecification.checkVerifyPasswords(newPass, newVeriPass)) {
      this.txtNewVerifyPassword.error = true;
      this.txtNewVerifyPassword.errorMessage = "パスワードが一致しません。";
      return false;
    }

    return true;
  }

  clickChangePassword() {

    if (!this.checkPassword()) {
      return;
    }

    const request = {
      currentPassword: this.txtCurrentPassword.value.trim(),
      newPassword: this.txtNewPassword.value.trim(),
      newVerifyPassword: this.txtNewVerifyPassword.value.trim(),
    }

    httpResource.post("account/change-password", request)
        .then((response: any) => {
          this.dialogTitle = "パスワード変更成功";
          this.dialogMessage = "パスワードの変更に成功しました。";
        })
        .catch((error: any) => {
          this.dialogTitle = "パスワード変更失敗";
          this.dialogMessage = error.response.data.message;
        })
        .finally(() => {
          this.dialog = true;
        })
  }
}
