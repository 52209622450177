


























































import {Component, Vue} from "vue-property-decorator";
import HomeTemplate from "@/components/templates/home/HomeTemplate.vue";
import KawaiiTitle from "@/components/infrastructures/atoms/KawaiiTitle.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import Header from "@/components/templates/default/DefaultHeader.vue";

@Component({
  components: {ContentTitle, KawaiiTitle, HomeTemplate}
})
export default class EnvironmentPage extends Vue {
  name = "EnvironmentPage";
  links = Header.links;
}
