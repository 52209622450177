















































import {Component, Prop, Vue} from "vue-property-decorator";
import DeliveryAddress from "@/components/domains/account/profile/address/deliveryAddress";

@Component
export default class DeliveryAddressDisplayCard extends Vue {
  name = "DeliveryAddressDisplayCard";
  @Prop() address!: DeliveryAddress;
  @Prop({default: false}) showUpend: boolean;
  @Prop({default: true}) showBadge: boolean;
}
