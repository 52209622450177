

























import {Component, Vue} from "vue-property-decorator";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";
import {PurchaseGetterTypes, PurchaseMutationTypes} from "@/store/product/purchase/types";
import ProductService from "@/components/domains/products/productService";
import ProductCard from "@/components/domains/products/ProductCard.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn, SubBtn, ContentTitle, ProductCard, DefaultTemplate}
})
export default class PremiumPlanAccountMethodPage extends Vue {
  name = "PremiumPlanAccountMethodPage";

  product: { displayName: string; price: string; functions: string[] } =
      {displayName: "", price: "", functions: []};

  beforeCreate() {

    const productId = this.$store.getters[PurchaseGetterTypes.getProductId];

    ProductService.fetchProduct(productId)
        .then((product) => {
          this.product = product;
        })
        .catch(() => {
          alert('選択されている商品が見つからなかったためホームに戻ります。');
          this.$store.commit(PurchaseMutationTypes.clear);
          this.$router.push('/');
        })
  }

  private getProduct() {
    return this.product;
  }
}
