/// <reference types="resize-observer-browser" />
// https://github.com/Microsoft/TypeScript/issues/28502

export interface Size {
  width: number;
  height: number;
}

interface Point {
  x: number;
  y: number;
}

interface Area extends Size, Point {
  //
}

interface VideoCanvasSyncValues {
  videoSize: Size;
  canvasDisplaySize: Size;
  canvasSize: Size;
  displayToIntrinsicFactor: Point;
  d: Area;
}

// type ResizeObserverCallback = any
// type ResizeObserver = any

export function containIn(sourceSize: Size, maxSize: Size): Area {
  const ratio = Math.min(maxSize.width / sourceSize.width, maxSize.height / sourceSize.height)
  const width = sourceSize.width * ratio
  const height = sourceSize.height * ratio
  return { width, height, x: (maxSize.width - width) / 2, y: (maxSize.height - height) / 2 }
}

export function VideoCanvasSyncValues(): VideoCanvasSyncValues {
  return {
    videoSize: {
      width: 0,
      height: 0
    },
    canvasDisplaySize: {
      width: 0,
      height: 0
    },
    canvasSize: {
      width: 0,
      height: 0
    },
    displayToIntrinsicFactor: {
      x: 0,
      y: 0
    },
    d: {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    }
  }
}

export function recalculateVideoContainedInCanvasValues(video: HTMLVideoElement, canvas: HTMLCanvasElement, videoCanvasSyncValues: VideoCanvasSyncValues) {
  videoCanvasSyncValues.videoSize = {
    width: video.videoWidth,
    height: video.videoHeight
  }
  const canvasRect = canvas.getBoundingClientRect()
  videoCanvasSyncValues.canvasDisplaySize = {
    width: canvasRect.width,
    height: canvasRect.height
  }
  videoCanvasSyncValues.canvasSize = {
    width: canvas.width,
    height: canvas.height
  }

  const area1 = containIn(videoCanvasSyncValues.videoSize, videoCanvasSyncValues.canvasDisplaySize)
  videoCanvasSyncValues.displayToIntrinsicFactor = {
    x: videoCanvasSyncValues.canvasSize.width / videoCanvasSyncValues.canvasDisplaySize.width,
    y: videoCanvasSyncValues.canvasSize.height / videoCanvasSyncValues.canvasDisplaySize.height
  }
  videoCanvasSyncValues.d = {
    x: area1.x * videoCanvasSyncValues.displayToIntrinsicFactor.x,
    y: area1.y * videoCanvasSyncValues.displayToIntrinsicFactor.y,
    width: area1.width * videoCanvasSyncValues.displayToIntrinsicFactor.x,
    height: area1.height * videoCanvasSyncValues.displayToIntrinsicFactor.y
  }

  // if (Math.random() > 0.99) { // 回数が多いとこれでLimit
  console.debug('drawVideoContainedInCanvas', videoCanvasSyncValues)
  // }
}

export function watchElementResize(element: Element, onResize: ResizeObserverCallback) {
  // const resizeObserver = new (ResizeObserver as any)(onResize);
  const resizeObserver = new ResizeObserver(onResize);
  try {
    resizeObserver.observe(element, { box: 'device-pixel-content-box' });
  } catch (ex) {
    resizeObserver.observe(element, { box: 'content-box' });
  }
  return resizeObserver
}

export function drawVideoContainedInCanvas(video: HTMLVideoElement, ctx: CanvasRenderingContext2D , videoCanvasSyncValues: VideoCanvasSyncValues) {
  const { d } = videoCanvasSyncValues
  ctx.drawImage(video, d.x, d.y, d.width, d.height)
}
