import {MutationTree} from "vuex";
import {AuthMutationTypes, AuthState} from "@/store/auth/types";
import {User} from "@/components/domains/authorization/user";

const mutations: MutationTree<AuthState> = {
    [AuthMutationTypes.setAuthenticated]: (state, authenticated: boolean) => {
        state.authenticated = authenticated;
    },
    [AuthMutationTypes.setCurrentUser]: (state, user: User) => {
        state.currentUser = user;
    },
    [AuthMutationTypes.setIntervalName]: (state, intervalName: string) => {
        state.intervalName = intervalName;
    },
    [AuthMutationTypes.clearCurrentUser]: (state) => {
        state.currentUser = new User();
    },
    [AuthMutationTypes.setMyPageLinks]: (state, myPageLinks: {}[]) => {
        state.myPageLinks = myPageLinks;
    }
}

export default mutations;
