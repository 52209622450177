






















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DateTimeFormatter from "@/components/infrastructures/functions/time/dateTimeFormatter";
import AIEstheService from "@/components/domains/esthe/aiesthe/aiEstheService";

@Component
export default class AIEstheHistoryTable extends Vue {

  name = "AIEstheHistoryTable";

  @Prop({default: 'desc'}) sort: string;
  @Prop({default: false}) selectFirst: boolean;

  headers = [
    // {text: "分析結果ID", value: "id"},
    {text: "記録日", value: "latestUpdate"}
  ];

  selectedItems = [];
  options: any = {}
  items = []
  item: any = {}
  total = 0
  loading = false

  mounted() {
    this.getDataFromApi();
  }

  formatDate(str: string) {
    return DateTimeFormatter.formatFromStr(str);
  }

  @Watch("options")
  getDataFromApi() {
    this.loading = true
    this.apiCall()
        .then((data: any) => {
          this.total = data.total;
          this.items = data.items;
          // this.items = data.items.map((item: any) => {
          //   return {"id": item.id, "latestUpdate": item.latestUpdate}
          // });
          if (this.selectFirst && this.items.length > 0) {
            this.selectedItems = [this.items[0]];
          }
        }).catch(error => {
      // NOP
    }).finally(() => {
      this.loading = false;
    })
  }

  async apiCall() {
    const {page, itemsPerPage} = this.options;
    return AIEstheService.historyPagingSizeSorted(page, itemsPerPage, this.sort);
  }

  @Watch("selectedItems")
  private watchSelected(selectedItems: any) {
    if (selectedItems.length == 0) {
      this.selected({});
      return;
    }
    this.item = selectedItems[0];
    const id = this.item.id;
    this.select(id);
    AIEstheService.detectFaceResult(id)
        .then((response: any) => {
          this.selected(response.data)
        });
  }

  private select(id: string) {
    this.$emit('select', id);
  }

  private selected(item: {}) {
    this.$emit('selected', item);
  }
}
