import WaveFile from "@/components/domains/esthe/dmv/waveFile";

export default class SoundTherapyFile extends WaveFile {

    public title = '';
    public fileName: string;
    public cluster: string;
    public composer: string;
    public genres: string[];
    public effects: string[];
    public bodyParts: string[];
}