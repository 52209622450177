





























import {Component, Vue} from "vue-property-decorator";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";
import EstheService from "@/components/domains/esthe/EstheService";

@Component({
  components: {ProductLineupDialog, MyPageContentTemplate}
})
export default class DrAiAndHealthCarePlanPage extends Vue {

  name = "DrAiAndHealthCarePlanPage";

  // products dialog
  private productsDialog = false;
  private products = [];
  private funcName = '';

  mounted() {
    this.products = [];

    EstheService.checkAvailableDrAiAndHealthCare()
        .then(res => {
          if (res.data !== 'E0000') {
            this.funcName = 'Dr.AI ヘルスケア & エステ';
            this.products = res.data.products;
            this.productsDialog = true;
          }
        });
  }

  close() {
    EstheService.checkAvailableDrAiAndHealthCare()
        .then(res => {
          if (res.data !== 'E0000') {
            this.$router.push('/mypage/aiesthe');
          }
        });
  }
}
