






import {Component, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {AuthActionTypes} from "@/store/auth/types";

@Component
export default class EasilyLoginPage extends Vue {
  name = "EasilyLoginPage";

  private dialog = false;
  private dialogMessage = '';

  private decide() {
    this.dialog = false;
    this.dialogMessage = '';
  }

  beforeCreate() {
    const path = this.$route.params.path;
    httpResource.get(`/auth/easily-login?${path}`)
        .then((response: any) => {
          if (response.status === 200) {
            this.$store.dispatch(AuthActionTypes.authenticateUser);
            this.$router.push('/mypage');
          } else {
            this.$router.push('/login');
          }
        })
        .catch(() => {
          this.$router.push('/login');
        });
  }
}
