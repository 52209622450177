













import {Component} from "vue-property-decorator";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";
import authService from "@/components/domains/authorization/authService";
import AuthMixin from "@/components/domains/authorization/authMixin";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";

@Component({
  components: {HoverLinkCard, ProductLineupDialog, MyPageContentTemplate}
})
export default class EnterprisePlanSelectionPage extends AuthMixin {
  name = "EnterprisePlanSelectionPage"

  mounted() {
    authService.authMe().then(() => {
      if (!authService.isEnterprise()) {
        authService.logout();
      }
    })
  }
}
