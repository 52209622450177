







































































import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import CreditCardInfo from "@/components/infrastructures/atoms/CreditCardInfo.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import OrderDetails from "@/components/infrastructures/molecules/OrderDetails.vue";
import {PurchaseActionTypes, PurchaseGetterTypes} from "@/store/product/purchase/types";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import {AuthGetterTypes} from "@/store/auth/types";
import {User} from "@/components/domains/authorization/user";
import AgreementsStore from "@/components/domains/account/register/general/agreementsStore";
import DeliveryAddressDisplayCard from "@/components/domains/account/profile/address/DeliveryAddressDisplayCard.vue";
import AddressService from "@/components/domains/account/profile/address/addressService";
import DeliveryAddress from "@/components/domains/account/profile/address/deliveryAddress";

@Component({
  components: {DeliveryAddressDisplayCard, PrimaryBtn, BackBtn, KawaiiDialog, CreditCardInfo, OrderDetails}
})
export default class ConfirmationStep extends Step {

  name = "ConfirmationStep";

  headers = [
    // {text: "顧客ID", value: "id"},
    {text: "ご使用者名", value: "name"},
    {text: "メールアドレス", value: "email"}
  ];
  customers: User[] = [];
  creditCard = {}
  dialog = false
  errorDialog = false
  errorMessage = false
  purchaseItems = []
  purchaseProcessing = false;
  overlay = false;

  // delivery
  deliveryAddress: DeliveryAddress = new DeliveryAddress();

  beforeMount() {

    if (!AgreementsStore.agreeAll()) {
      alert('利用規約の同意が確認できませんでした。');
      this.back();
    }

    const addressId = this.$store.getters[PurchaseGetterTypes.getDeliveryAddressId];
    if (addressId) {
      AddressService.getDeliveryAddress(addressId).then((address) => {
        this.deliveryAddress = address;
      })
    }

    httpResource.post(`account/users`, {userIds: this.$store.getters[PurchaseGetterTypes.getUsers]})
        .then((response: any) => {
          this.customers = response.data;
          this.checkComplete();
        })
    httpResource.get(`credit-card/fetch?id=${this.$store.getters[PurchaseGetterTypes.getCreditCardId]}`)
        .then((response: any) => {
          this.creditCard = response.data;
          this.checkComplete();
        })
    this.$store.dispatch(PurchaseActionTypes.fetchOrderDetails)
        .then((response: any) => {
          this.purchaseItems = response.data;
          this.checkComplete();
        })
  }

  purchase() {
    this.overlay = true;
    this.purchaseProcessing = true;
    this.$store.dispatch(PurchaseActionTypes.purchase).then(() => {
      this.dialog = true
    }).catch((error: any) => {
      this.errorMessage = error.response.data.message
      this.errorDialog = true
    }).finally(() => {
      this.overlay = false;
    })
  }

  decide() {
    this.dialog = false
    this.$router.push("/")
  }

  public getComponentName(): string {
    return this.name;
  }

  public getStepTitle(): string {
    return "ご注文の確認";
  }

  validate() {
    return this.customers.length > 0 && Object.keys(this.creditCard).length > 0 && this.purchaseItems.length > 0
  }

  get isMeOnlyUse() {
    return this.customers.length == 1 &&
        this.customers[0].id === this.$store.getters[AuthGetterTypes.getCurrentUser].userId;
  }

  get isDelivery() {
    return this.deliveryAddress.deliveryId.length > 0;
  }
}

