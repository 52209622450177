











import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import CalteFormat from "@/components/domains/calte/calteFormat";
import {CalteActionTypes, CalteMutationTypes} from "@/store/calte/types";
import CalteInputRecord from "@/components/domains/calte/calteInputRecord";
import CalteRecord from "@/components/domains/calte/calteRecord";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";

@Component({
  components: {GeneralField}
})
export default class CalteForm extends Vue {

  // Customer
  @Prop() value: {};
  @Prop() submitAction: Function;
  name = "CalteForm";
  calteFormat: CalteFormat = new CalteFormat();

  mounted() {
    this.updateCalteFormat();
  }

  get getCalteFormat() {
    return this.calteFormat;
  }

  submit() {

    if (this.calteFormat.hasEmptyInRequiredFields()) {
      alert('未入力項目があります。');
      return;
    }

    const calteInputRecords: CalteInputRecord[] = this.calteFormat.calteItems.map(item => {
      return new CalteInputRecord(item.id, item.value);
    });
    const calteRecord: CalteRecord = new CalteRecord('', this.calteFormat.calteId, calteInputRecords);

    if (this.submitAction) {
      this.submitAction(calteRecord);
    }
  }

  @Watch("value")
  updateCalteFormat() {
    if (this.value && (this.value as { id: string }).id) {
      this.$store.dispatch(CalteActionTypes.searchCalteFormat, {"userId": (this.value as { id: string }).id})
          .then((response: any) => {
            const id = response.data.calteId;
            const title = response.data.title;
            const items = response.data.calteItems;
            const calteFormat = CalteFormat.createWithId(id, title, items);
            this.calteFormat = calteFormat;
            this.$store.commit(CalteMutationTypes.setCalteFormat, this.calteFormat);
          })
          .catch((error: any) => {
            this.calteFormat = new CalteFormat();
          });
    }
  }
}
