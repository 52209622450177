






























import {Component, VModel, Vue, Watch} from "vue-property-decorator";
import Address from "@/components/domains/account/profile/address/address";
import AddressService from "@/components/domains/account/profile/address/addressService";
import Overlay from "@/components/infrastructures/atoms/designed/Overlay.vue";

@Component({
  components: {Overlay}
})
export default class AddressForm<T extends Address> extends Vue {

  name = "AddressForm";

  @VModel() modelVal!: T;
  titleBaseSize = 25;

  prefectures: { value: string; text: string }[] = [];
  municipalities: { value: string; text: string }[] = [];
  disabledAddressAutomationInput = true;

  // Overlay
  overlay = false;

  public txtPostcode = "";
  public slctPrefecture = "";
  public slctMunicipality = "";
  public txtAddress = "";
  public txtBuilding = "";
  public txtRoomNumber = "";

  public reset(): void {
    this.txtPostcode = "";
    this.slctPrefecture = "";
    this.slctMunicipality = "";
    this.txtAddress = "";
    this.txtBuilding = "";
    this.txtRoomNumber = "";
  }

  mounted() {
    AddressService.fetchPrefectures().then(prefectures => {
      this.prefectures = prefectures;
      this.initializeValue();
    });
  }

  public initializeValue() {
    if (this.modelVal.prefectureCode !== '') {
      this.slctPrefecture = this.modelVal.prefectureCode;
    }
    if (this.modelVal.municipalityCode !== '') {
      this.slctMunicipality = this.modelVal.municipalityCode;
    }
    if (this.modelVal.postcodeFirst !== '' && this.modelVal.postcodeLast !== '') {
      this.txtPostcode = this.modelVal.postcodeFirst + this.modelVal.postcodeLast;
    }
    if (this.modelVal.address !== '') {
      this.txtAddress = this.modelVal.address;
    }
    if (this.modelVal.buildingName !== '') {
      this.txtBuilding = this.modelVal.buildingName;
    }
    if (this.modelVal.roomNumber !== '') {
      this.txtRoomNumber = this.modelVal.roomNumber;
    }
  }

  private fetchAddress() {

    this.overlay = true;
    this.disabledAddressAutomationInput = true;

    AddressService.searchFromPostcode(this.txtPostcode)
        .then((address: Address) => {
          this.slctPrefecture = address.prefectureCode;
          this.slctMunicipality = address.municipalityCode;
          this.txtAddress = address.address;
        })
        .catch((error) => {
          alert(error.response.data.message)
        })
        .finally(() => {
          this.updateDisabledAddressAutomationInput();
          this.overlay = false;
        });
  }

  @Watch('txtPostcode')
  private updateDisabledAddressAutomationInput() {
    if (this.txtPostcode.length != 7) {
      this.disabledAddressAutomationInput = true;
    } else {
      this.disabledAddressAutomationInput = false;
      this.modelVal.postcodeFirst = this.txtPostcode.substring(0, 3);
      this.modelVal.postcodeLast = this.txtPostcode.substring(3, 7);
    }
  }

  @Watch('slctPrefecture')
  private async updatePrefecture() {
    this.modelVal.prefectureCode = this.slctPrefecture;
    this.municipalities = (await AddressService.fetchMunicipalities(this.slctPrefecture));
  }

  @Watch('slctMunicipality')
  public updateMunicipality() {
    this.modelVal.municipalityCode = this.slctMunicipality;
  }

  @Watch('txtAddress')
  public updateAddressText() {
    this.modelVal.address = this.txtAddress;
  }

  @Watch('txtBuilding')
  public updateBuilding() {
    this.modelVal.buildingName = this.txtBuilding;
  }

  @Watch('txtRoomNumber')
  public updateRoomNumber() {
    this.modelVal.roomNumber = this.txtRoomNumber;
  }
}
