





















































import {Component, Vue, Watch} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class SoundDeleteTab extends Vue {
  name = "SoundDeleteTab";

  get headers() {
    return [
      // {text: "ID", value: "id"},
      {text: "タイトル", value: "title"},
      {text: "ジャンル", value: "genre"},
      {text: "タグ", value: "tags"},
      {text: "産地", value: "country"},
      {text: "目的", value: "purposeDetailId"},
      {text: "視聴", value: "path"},
      {text: "更新", value: "update"},
    ];
  }

  selected: {}[] = [];
  vibrationList: {}[] = [];

  diabledDelete = true;

  purposes: {}[] = [];

  // dialog
  dialog = false;
  dialogMessage = "";

  mounted() {
    this.fetchDMVList;
    this.fetchMst;
  }

  get fetchDMVList() {
    return httpResource.get("/upload/sound-dmv-list")
        .then((response: any) => {
          this.vibrationList = response.data;
        });
  }

  get fetchMst() {
    return httpResource.get("/api/sound-dmv/sound-dmv-mst")
        .then((response: any) => {
          this.purposes = response.data.purposes;
        });
  }

  update(item: {}) {
    httpResource.post("upload/sound-dmv-update", item)
        .then((response: any) => {
          this.fetchDMVList;
        });
  }

  deleteAction() {
    if (this.diabledDelete) {
      return;
    }
    const selected = this.selected[0] as any;

    httpResource.post(`/upload/delete-sound-dmv?id=${selected.id}`)
        .then((response: any) => {
          this.dialogMessage = "削除に成功しました。";
          this.fetchDMVList;
        })
        .catch((error: any) => {
          this.dialogMessage = "削除に失敗しました。";
        })
        .finally(() => {
          this.dialog = true;
        });
  }

  @Watch("selected")
  watchDiabledDelete() {
    this.diabledDelete = this.selected.length === 0;
  }
}
