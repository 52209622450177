








import {Component, Vue, Watch} from "vue-property-decorator";
import KawaiiCalendar from "@/components/domains/calender/KawaiiCalendar.vue";
import CustomerSearchTable from "@/components/infrastructures/molecules/CustomerSearchTable.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";

@Component({
  components: {MyPageContentTemplate, CustomerSearchTable, KawaiiCalendar}
})
export default class ManagementEstheCalendar extends Vue {
  name = "ManagementEstheCalendarPage.vue";

  selectedUsers = [];
  selectedUserIds: string[] = [];

  get getKey() {
    return this.selectedUserIds.join(",");
  }

  @Watch('selectedUsers')
  updateUserIds() {
    this.selectedUserIds = this.selectedUsers.map((u: any) => u.id);
  }
}
