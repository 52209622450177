















import Component from "vue-class-component";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import {Prop, Vue} from "vue-property-decorator";
import Overlay from "@/components/infrastructures/atoms/designed/Overlay.vue";

@Component({
  components: {Overlay, KawaiiDialog}
})
export default class KawaiiSentMessage extends Vue {
  @Prop({default: false})
  hasError: boolean;

  @Prop({default: false})
  overlay: boolean;

  @Prop({default: false})
  dialog: boolean;

  @Prop({default: {title: '完了', message: '送信が完了しました！'}})
  succeedMessage: { title: string; message: string };

  @Prop({default: {title: '失敗', message: '送信に失敗しました！'}})
  failedMessage: { title: string; message: string };

  messages = [
    {title: this.succeedMessage.title, message: this.succeedMessage.message, color: 'primary'},
    {title: this.failedMessage.title, message: this.failedMessage.message, color: 'primary'}
  ]

  private getMessage(hasError: boolean) {
    if (hasError) {
      return this.messages[1]
    }
    return this.messages[0]
  }

  private closeThis() {
    this.$emit('decide')
  }
}
