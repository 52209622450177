










import {Component, Vue} from "vue-property-decorator";
import HpSmallText from "@/components/infrastructures/atoms/text/HpSmallText.vue";

@Component({
  components: {HpSmallText}
})
export default class Copyright extends Vue {
  name = "Copyright"
}
