





































































import {Component, Vue} from "vue-property-decorator";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle, DescriptionText}
})
export default class WhatsDMV extends Vue {
  name = "WhatsDMV";
}
