









































































































































































import {Component, Vue} from "vue-property-decorator";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";
import OverviewCard from "@/components/infrastructures/atoms/designed/OverviewCard.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle, OverviewCard, DescriptionText}
})
export default class Overview extends Vue {
  name: "Overview"
}
