
















































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import DefaultVue from "vue";
import CalteFormat from "@/components/domains/calte/calteFormat";
import {CalteActionTypes, CalteGetterTypes, CalteMutationTypes} from "@/store/calte/types";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component
export default class CreateCalteFormat extends Vue {
  name = "CreateCalteFormat";
  headers = [
    {text: '種別', value: 'type'},
    {text: 'ラベル名', value: 'label'},
    {text: '顧客記入欄', value: 'customer_input'},
    {text: '顧客公開', value: 'customer_publish'},
    {text: '削除', value: 'delete'},
  ];
  calteId = '';
  title = '';
  items: {}[] = [];
  calteFormat = new CalteFormat();
  showPreview = false;

  // Dialog
  dialog = false;
  overlay = false;
  error = false;

  // template
  templateItems: [] = [];
  selectedTemplateId = '';
  removableSelectedTemplate = false;
  selectedTemplate = '';

  mounted() {
    this.calteFormat = this.$store.getters[CalteGetterTypes.getCalteFormat];
    this.fetchTemplateList();
  }

  fetchTemplateList() {
    httpResource.get("pic/calte/template-list")
        .then((response: any) => {
          this.templateItems = response.data;
        });
  }

  addTextField(): void {
    if (this.canAddItem()) {
      this.items.push({type: 'text_field', label: '', customerInput: false, customerPublish: false});
    }
  }

  addTextArea(): void {
    if (this.canAddItem()) {
      this.items.push({type: 'text_area', label: '', customerInput: false, customerPublish: false});
    }
  }

  removeItem(item: any): void {
    const index = this.items.indexOf(item);
    this.items = this.items.filter((v, idx) => idx !== index);
  }

  canAddItem(): boolean {
    // 20 個まで
    return this.items.length < 20;
  }

  validateRegister(): boolean {

    if (this.calteFormat.title == null || this.calteFormat.title.trim() === '') {
      alert('タイトルを入力してください。');
      return false;
    }

    if (this.calteFormat.hasEmptyInLabels()) {
      alert('ラベル名を入力してください。');
      return false;
    }

    if (this.calteFormat.calteItems.length === 0) {
      alert('項目は最低一つ追加してください。');
      return false;
    }

    return true;
  }

  register(): void {

    this.save();

    if (!this.validateRegister()) {
      return;
    }

    this.$store.dispatch(CalteActionTypes.registerCalteFormat)
        .then((response: any) => {
          const calteId = response.data;
          this.calteId = calteId;
          this.save();
        })
        .catch((error: any) => {
          this.error = true;
        })
        .finally(() => {
          this.overlay = true;
          this.dialog = true;
        });
  }

  registerTemplate() {

    this.save();

    if (!this.validateRegister()) {
      return;
    }

    this.$store.dispatch(CalteActionTypes.registerCalteTemplate)
        .then((response: any) => {
          const calteId = response.data;
          this.calteId = calteId;
          this.save();
          this.fetchTemplateList();
        })
        .catch((error: any) => {
          this.error = true;
        })
        .finally(() => {
          this.overlay = true;
          this.dialog = true;
        });
  }

  deleteSelectedTemplate() {

    const templateId = this.selectedTemplateId;

    if (this.selectedTemplateId === '') {
      return;
    }

    const data = {
      id: templateId
    };

    httpResource.post(`pic/calte/delete-template`, data)
        .then((response: any) => {
          this.fetchTemplateList();
        })
        .catch((error: any) => {
          this.error = true;
        })
        .finally(() => {
          this.overlay = true;
          this.dialog = true;
        });
  }

  setCustomerPublish(item: any, publish: boolean) {
    item.customerPublish = publish
  }

  decide(): void {
    this.dialog = false;
    this.overlay = false;
    this.error = false;
  }

  @Watch('showPreview')
  save() {
    this.calteFormat = CalteFormat.createWithId(this.calteId, this.title, this.items);
    this.$store.commit(CalteMutationTypes.setCalteFormat, this.calteFormat);
  }

  @Watch('selectedTemplate')
  applyTemplate() {
    const template = this.selectedTemplate;
    if (template === '') {
      this.selectedTemplateId = '';
      this.removableSelectedTemplate = false;
      return;
    }

    this.templateItems
        .filter((item: any) => item.name === template)
        .find((item: any) => {
          this.selectedTemplateId = item.id;
          this.removableSelectedTemplate = item.removable;
        })

    httpResource.get(`pic/calte/template?name=${template}`)
        .then((response: any) => {
          this.title = response.data.title;
          this.items = response.data.calteItems;
        });
  }
}

DefaultVue.component(CreateCalteFormat.name, CreateCalteFormat);
