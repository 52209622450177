



























import {Component, Prop, Vue} from "vue-property-decorator";
import MentlCheckImageSelector from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/mentlCheckImageSelector";
import MentalCheckMessageSelector
  from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/mentalCheckMessageSelector";
import Answer from "@/components/infrastructures/functions/question/answer";
import QuestionTree from "@/components/infrastructures/atoms/QuestionTree.vue";

@Component({
  components: {QuestionTree}
})
export default class Result extends Vue {

  name = "result";
  complete = false;
  answers = [];
  img = "";
  soundSrc = "";
  message = "";

  @Prop() questionTree!: QuestionTree;
  @Prop() answerToSoundSrc!: any;

  answered(answers: Array<Answer>) {
    this.answerToSoundSrc(answers)
        .then((response: any) => {
          this.img = MentlCheckImageSelector.selectImagePath(answers[4].getResult());
          this.message = MentalCheckMessageSelector.selectMessage(answers[4].getResult());
          this.soundSrc = response.data.soundSrc;
        })
        .catch((reason: any) => {
          // console.log(reason);
        })
        .finally(() => {
          this.complete = true;
        });
  }

  reset() {
    this.complete = false;
    this.img = "";
    this.message = "";
    this.soundSrc = "";
  }
}
