















import {Component, Vue} from "vue-property-decorator";
import MarkdownViewer from "@/components/infrastructures/atoms/MarkdownViewer.vue";
import ProvidingDataAgreementText from "@/components/domains/agreement/ProvidingDataAgreementText.vue";

@Component({
  components: {ProvidingDataAgreementView: ProvidingDataAgreementText, MarkdownViewer}
})
export default class ProvidingDataAgreement extends Vue {

  name = "ProvidingDataAgreement";

  agree(agreement: boolean) {
    this.$emit('agree', agreement);
  }
}
