import {GetterTree} from "vuex";
import {AIEstheGetterTypes, AIEstheState} from "@/store/aiesthe/types";
import {RootState} from "@/store/type";

const getters: GetterTree<AIEstheState, RootState> = {
    [AIEstheGetterTypes.getId]: (state: AIEstheState) => {
        return state.id;
    },
    [AIEstheGetterTypes.getCourse]: (state: AIEstheState) => {
        return state.course;
    },
    [AIEstheGetterTypes.isAfter]: (state: AIEstheState) => {
        return state.after;
    },
    [AIEstheGetterTypes.getCurrentImage]: (state: AIEstheState) => {
        return state.image;
    },
    [AIEstheGetterTypes.getSoundId]: (state: AIEstheState) => {
        return state.soundId;
    },
    [AIEstheGetterTypes.getSoundSrc]: (state: AIEstheState) => {
        return state.soundSrc;
    },
    [AIEstheGetterTypes.getBodyPart]: (state: AIEstheState) => {
        return state.bodyPart;
    },
    [AIEstheGetterTypes.isPublish]: (state: AIEstheState) => {
        return state.publish;
    },
    [AIEstheGetterTypes.getDateTime]: (state: AIEstheState) => {
        return state.dateTime;
    },
    [AIEstheGetterTypes.getDetectFaceResult]: (state: AIEstheState) => {
        return state.detectFaceResult;
    },
}

export default getters;
