import {MutationTree} from "vuex";
import {CalteActionTypes, CalteMutationTypes, CalteState} from "@/store/calte/types";
import CalteFormat from "@/components/domains/calte/calteFormat";
import CalteRecord from "@/components/domains/calte/calteRecord";

const mutations: MutationTree<CalteState> = {
    [CalteMutationTypes.setSelectedCustomer]: (state, customer: {}) => {
        state.selectedCustomer = customer
    },
    [CalteMutationTypes.setCalteFormat]: (state, calteFormat: CalteFormat) => {
        state.calteFormat = calteFormat
    },
    [CalteMutationTypes.setCalteRecord]: (state, calteRecord: CalteRecord) => {
        state.calteRecord = calteRecord;
    },
    [CalteMutationTypes.setAIEstheFaceDetectResult]: (state, aiEstheFaceDetectResult: {}) => {
        state.aiEstheFaceDetectResult = aiEstheFaceDetectResult;
    },
    [CalteActionTypes.clear]: (state) => {
        state.calteFormat = new CalteFormat();
        state.selectedCustomer = {};
        state.calteRecord = new CalteRecord('', '', []);
        state.aiEstheFaceDetectResult = {};
    },
}

export default mutations;
