import Address from "@/components/domains/account/profile/address/address";

export default class DeliveryAddress extends Address {

    public deliveryId = "";
    public firstName = "";
    public middleName = "";
    public lastName = "";
    public phoneNumber = "";
    public regulation = false;

    public isEmpty(): boolean {
        return super.isEmpty();
    }

    public reset() {
        super.reset();
        this.firstName = "";
        this.middleName = "";
        this.lastName = "";
        this.phoneNumber = "";
        this.regulation = false;
    }
}