
























































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import HowToUseCard from "@/components/infrastructures/molecules/HowToUseCard.vue";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";

@Component({
  components: {ContentTitle, DescriptionText, HowToUseCard}
})
export default class HowToUse extends Vue {
  name = "HowToUse";
}
