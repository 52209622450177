









import {Component, Vue, Watch} from "vue-property-decorator";

@Component
export default class PageZoomScaleButton extends Vue {
  name = "PageZoomScaleButton";
  zoomRatio = 1.0;

  @Watch("zoomRatio")
  zoom() {
    document.body.style.zoom=String(this.zoomRatio);
  }
}
