












































































import {Component, Watch} from "vue-property-decorator";
import SoundUploadTab from "@/components/domains/upload/soundupload/SoundUploadTab.vue";
import FieldData from "@/components/infrastructures/functions/fieldData";

@Component
export default class KanadeUploadTab extends SoundUploadTab {

  name = "KanadeUploadTab";

  txtPlayer = new FieldData()
      .withType("text_field")
      .withRequires(true)
      .withLabel("奏者");

  protected get getRequestData() {
    return {
      planCode: "03",
      title: this.txtDMVName.value,
      genreId: this.slctGenre.value,
      countryId: this.slctCountry.value,
      place: this.txtPlace.value,
      tagIds: this.selectedTags.map((t: any) => t.value),
      fileName: this.selectedDMVName,
      player: this.txtPlayer.value,
      purposeDetailId: this.slctPurpose.value,
      dmv: this.selectedDMVBin
    };
  }

  @Watch("txtPlayer.value")
  watchTxtPlayer() {
    this.validate;
  }

  get validate(): boolean {
    this.disabledUpload =
        this.selectedDMVBin === ""
        || this.txtDMVName.value === ""
        || this.selectedTags.length === 0
        || this.slctCountry.value === ""
        || this.txtPlayer.value === ""
        || this.slctGenre.value === ""
        || this.slctPurpose.value === "";

    return !this.disabledUpload;
  }

}
