interface TypFuncKeyVal {
    (key: string, val: any): void;
}

export default class HashMap<T> {

    items: { [key: string]: T };

    constructor() {
        this.items = {};
    }

    public put(key: string, value: T): void {
        this.items[key] = value;
    }

    public del(key: string): boolean {
        return delete this.items[key];
    }

    public has(key: string): boolean {
        return key in this.items;
    }

    public get(key: string): T {
        return this.items[key];
    }

    public len(): number {
        return Object.keys(this.items).length;
    }

    public forEach(f: TypFuncKeyVal) {
        for (const k in this.items) {
            f(k, this.items[k]);
        }
    }

    public entrySet() {
        return this.items;
    }
}