

























import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import FieldData from "@/components/infrastructures/functions/fieldData";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import {AccountService} from "@/components/domains/account/AccountService";
import {PurchaseGetterTypes} from "@/store/product/purchase/types";

@Component({
  components: {GeneralField, KawaiiDialog, KawaiiButton}
})
export default class ConfirmMailForm extends Step {

  name = "ConfirmMailForm";

  // dialog
  dialog = false;
  message = "";

  // mail
  sent = false;

  // already account
  showAlreadyHasAccountQuestion = false;

  mailField: FieldData = new FieldData()
      .withId("mailAddress")
      .withRequires(true)
      .withType("text_field")
      .withLabel("メールアドレス");

  getStepTitle(): string {
    return "メールアドレスの確認";
  }

  getStepSubTitle(): string {
    return "";
  }

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {
    const path = this.$route.params.path;
    if (path !== undefined && path !== '') {
      this.next();
    }
  }

  validateSentMail() {

    this.mailField.error = false;
    this.mailField.errorMessage = "";

    const mail = this.mailField.value.trim();

    if (mail === '') {
      this.mailField.error = true;
      this.mailField.errorMessage = "入力してください。";
      return false;
    }

    return true;
  }

  sentMail() {

    if (!this.validateSentMail()) {
      return;
    }

    // 選択されている商品IDを取得
    const productId = this.$store.getters[PurchaseGetterTypes.getProductId];

    const mail = this.mailField.value.trim();
    AccountService.issueConfirmMailTempUrl(mail, productId)
        .then(() => {
          this.message = "確認用メールを送信しました。"
          this.sent = true;
        })
        .catch((error: any) => {
          this.showAlreadyHasAccountQuestion = true;
          this.message = error.response.data.message;
        })
        .finally(() => {
          this.dialog = true;
        })
  }
}
