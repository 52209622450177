

















































import {Component, Vue, Watch} from "vue-property-decorator";
import CustomerSearchTable from "@/components/infrastructures/molecules/CustomerSearchTable.vue";
import {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";

export class GrantAuthorityUserSearchAction implements SearchAction {

  constructor(private authType: number, private authorities: any[]) {

  }

  async action(condition: string): Promise<[{}]> {

    const request = {
      authType: this.authType,
      authorities: this.authorities,
      condition: condition
    };

    const response = await httpResource.post(`grant/search-users`, request);
    return response.data;
  }
}

@Component({
  components: {KawaiiButton, CustomerSearchTable}
})
export default class GrantAuthority extends Vue {

  name = "GrantAuthority";

  // User
  selectedUsers = [];

  // Authorities
  authorities = [
    {state: 'AI エステ', value: 'ai_esthe'},
  ];
  selectedAuthority = this.authorities[0];

  // 付与・はく奪
  authType = 0;

  refresh = false;

  mounted() {
    // TODO authorities をデーベースから取得
  }

  get getKey() {
    return this.authType + this.selectedAuthority.value + this.refresh;
  }

  get getSearchAction() {
    this.reset();
    return new GrantAuthorityUserSearchAction(this.authType, [this.selectedAuthority.value]);
  }

  get getAuthTypeWord() {
    if (this.authType === 0) {
      return '付与';
    } else {
      return 'はく奪';
    }
  }

  @Watch("selectedUsers")
  updatedUser() {
    // NOP
  }

  reset() {
    this.selectedUsers = [];
  }

  canRegister() {
    return this.selectedUsers.length > 0
  }

  register() {

    const request = {
      userIds: this.selectedUsers.map((u: any) => u.id),
      authority: this.selectedAuthority.value,
      authorityType: this.authType
    };

    httpResource.post("/grant/grant-deprivation", request)
        .then(() => {
          // NOP
        })
        .finally(() => {
          this.refresh = !this.refresh;
        });
  }
}
