


















import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultVue from "vue";
import SearchTable from "@/components/infrastructures/molecules/SearchTable.vue";
import {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

export class SubscriptionStateSearchAction implements SearchAction {

  async action(condition: string): Promise<[{}]> {
    const response = await httpResource.get(`payment/fetch-subscription`);
    return response.data;
  }
}

@Component({
  components: {SearchTable}
})
export default class SubscriptionStateTable extends Vue {

  name = "SubscriptionStateTable";
  @Prop() private value!: [];
  @Prop({default: true}) showSelect: boolean;

  items = [];

  // local args
  headers = [
    {text: "契約者", value: "contractorName"},
    {text: "使用者名", value: "userName"},
    {text: "商品名", value: "productDisplayName"},
    {text: "開始日", value: "startedAt"},
    {text: "状況", value: "status"},
    {text: "解約予定日", value: "cancelAt"},
    {text: "詳細", value: "detail"},
  ];

  beforeMount() {
    httpResource.get(`payment/fetch-subscription`).then((result: any) => {
      this.items = result.data;
    });
  }

  private get inputValue(): {}[] {
    return this.value;
  }

  private set inputValue(value: {}[]) {
    this.$emit('input', value);
  }

  changeSelection(card: any) {
    this.$emit('change', card)
  }
}
DefaultVue.component(SubscriptionStateTable.name, SubscriptionStateTable);
