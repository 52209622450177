






























































import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {AIEstheGetterTypes, AIEstheMutationTypes} from "@/store/aiesthe/types";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import AIEstheService from "@/components/domains/esthe/aiesthe/aiEstheService";

@Component({
  components: {HoverLinkCard, BackBtn}
})
export default class SoundTherapySelectStep extends Step {
  name = "SoundTherapySelectStep";

  recommended = false;

  course = 0;
  dmvList = [];

  beforeMount() {
    if (this.getCourse == 9) {
      //
    } else {
      AIEstheService.fetchSoundTherapyDmvList(this.getCourse).then((response) => {
        this.dmvList = response.data;
      })
    }
  }

  get getCourse() {
    return this.$store.getters[AIEstheGetterTypes.getCourse];
  }

  getComponentName(): string {
    return this.name;
  }

  getStepTitle(): string {
    return "AIエステ.音楽療法.選択";
  }

  chooseSound(soundId: string) {
    this.$store.commit(AIEstheMutationTypes.setSoundId, soundId);
    this.next();
  }

  chooseBodyPart(bodyPart: string) {
    this.$store.commit(AIEstheMutationTypes.setBodyPart, bodyPart);
    this.next();
  }
}
