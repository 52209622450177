<template>
  <v-card-title class="pl-0 text-h4">
    <slot/>
  </v-card-title>
</template>

<script>
export default {
  name: "KawaiiTitle"
}
</script>

<style scoped>

</style>