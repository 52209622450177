









































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import CategoryCard from "@/components/domains/esthe/soundesthe/CategoryCard.vue";
import NatureSoundItemCard
  from "@/components/domains/esthe/soundesthe/plans/categories/naturesound/NatureSoundItemCard.vue";
import FieldData from "@/components/infrastructures/functions/fieldData";

@Component({
      components: {
        CategoryCard,
        NatureSoundItemCard
      }
    }
)
export default class NatureSoundCard extends Vue {
  name = "NatureSoundCard";

  @Prop({default: false}) opened!: boolean;
  @Prop({default: false}) selectMode!: boolean;

  // mst
  tags: {}[] = [];
  selectedTags: {}[] = [];

  // fields
  slctGenres = new FieldData()
      .withType("select");

  // item
  searchedItems: {}[] = [];
  soundItems: {}[] = [];
  beforeIndex = -1;
  selectIndex = 0;

  // event
  attachedEventListener = false;
  stTouchX = 0;
  edTouchX = 0;

  // allow
  disableArrow = false;

  beforeMount() {
    this.fetchSoundList();
    this.fetchMst();
  }

  fetchMst() {
    httpResource.get("/api/sound-dmv/sound-dmv-mst")
        .then((response: any) => {
          this.tags = response.data.tags;
          this.slctGenres.withSelection(response.data.genres);
        });
  }

  fetchSoundList() {
    httpResource.get("api/natural-sound/list")
        .then((response: any) => {
          this.soundItems = response.data;
          this.searchedItems = this.soundItems;
        })
  }

  clickNext() {
    const newIndex = this.selectIndex + 1;
    if (newIndex < this.searchedItems.length) {
      this.selectIndex = newIndex;
    } else {
      this.selectIndex = 0;
    }
    this.disableArrow = false;
  }

  clickBack() {
    const newIndex = this.selectIndex - 1;
    if (newIndex >= 0) {
      this.selectIndex = newIndex;
    } else {
      this.selectIndex = this.searchedItems.length - 1;
    }
    this.disableArrow = false;
  }

  clickSlideBtn(index: number) {
    this.selectIndex = index;
  }

  tagColor(tag: {}) {
    const contains = this.selectedTags.indexOf(tag) !== -1;
    if (contains) {
      return "secondary";
    }
    return "default";
  }

  selectTag(tag: any) {
    const contains = this.selectedTags.indexOf(tag) !== -1;
    if (contains) {
      this.selectedTags = this.selectedTags.filter((n: any) => n.value !== tag.value);
    } else {
      this.selectedTags = this.selectedTags.concat(tag);
    }
  }

  containsAllTag(tags1: string[], tags2: string[]) {
    for (const index in tags1) {
      if (tags2.indexOf(tags1[index]) !== -1) {
        return true;
      }
    }
    return false;
  }

  updateSearchedItems() {
    if (this.selectedTags.length === 0) {
      this.searchedItems = this.soundItems;
      return;
    }
    this.searchedItems = this.soundItems.filter((item: any) => {
      return this.containsAllTag(this.selectedTags.map((t: any) => t.text), item.tags);
    })
  }

  startedAction() {
    if (!this.selectMode) {
      this.disableArrow = true;
    }
  }

  stoppedAction() {
    this.disableArrow = false;
  }

  @Watch("selectedTags")
  watchSelectedTags() {
    this.updateSearchedItems();
  }
}
