








































import {Component, Vue, Watch} from "vue-property-decorator";
import EstheService from "@/components/domains/esthe/EstheService";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";
import NatureSoundCard from "@/components/domains/esthe/soundesthe/plans/categories/naturesound/NatureSoundCard.vue";
import KanadeCard from "@/components/domains/esthe/soundesthe/plans/categories/kanade/KanadeCard.vue";

@Component({
  components: {KanadeCard, NatureSoundCard, ProductLineupDialog}
})
export default class PlayWithSoundEsthe extends Vue {
  name = "PlayWithSoundEsthe";

  // carousel
  carousel = 0;
  items = [
    {text: '自然の響き', value: 0},
    {text: '奏', value: 1}
  ]

  // sound esthe
  showSoundEstheSelection = false;

  // products dialog
  private productsDialog = false;
  private products = [];
  private funcName = '';

  @Watch("showSoundEstheSelection")
  toggleShowSoundEstheSelection() {
    if (this.showSoundEstheSelection) {

      this.products = [];

      EstheService.checkAvailableWithSoundEsthe()
          .then(res => {
            if (res.data === 'E0000') {
              // NOP
            } else {
              this.funcName = 'メディカルサウンドも一緒に聴く機能';
              this.products = res.data.products;
              this.productsDialog = true;
              this.showSoundEstheSelection = false;
            }
          });
    }
  }
}
