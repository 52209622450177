

















import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultVue from "vue";
import SearchTable from "@/components/infrastructures/molecules/SearchTable.vue";
import {CustomerSearchAction} from "@/components/domains/calte/ChooseCustomerStep.vue";

@Component({
  components: {SearchTable}
})
export default class CustomerSearchTable extends Vue {

  name = "CustomerSearchTable.vue";
  private value!: [];
  @Prop({default: () => new CustomerSearchAction()}) searchCustomerAction: any;
  @Prop({default: 5}) itemsPerPage: number;
  @Prop({default: false}) singleSelect: boolean;

  // local args
  headers = [
    {text: "顧客ID", value: "id"},
    {text: "顧客名", value: "name"},
    {text: "団体名", value: "agency"},
    {text: "メールアドレス", value: "email"}
  ];

  private get inputValue(): {}[] {
    return this.value;
  }

  private set inputValue(value: {}[]) {
    this.$emit('input', value);
  }

  changeSelection(customers: any) {
    this.$emit('changeSelection', customers)
  }
}
DefaultVue.component(CustomerSearchTable.name, CustomerSearchTable);
