





































import {Component, Vue} from "vue-property-decorator";
import {AuthGetterTypes} from "@/store/auth/types";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import BaseInformation from "@/components/domains/account/profile/BaseInformation.vue";
import AddressInformation from "@/components/domains/account/profile/AddressInformation.vue";
import CreditCardInformation from "@/components/domains/account/profile/CreditCardInformation.vue";

@Component({
  components: {CreditCardInformation, AddressInformation, BaseInformation, MyPageContentTemplate}
})
export default class ProfilePage extends Vue {
  name = "ProfilePage";
  tab: any = null;

  // tab info
  tabBase = "base";
  tabAddress = "address";
  tabCreditCard = "creditCard";

  get getUser() {
    return this.$store.getters[AuthGetterTypes.getCurrentUser];
  }
}
