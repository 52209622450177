import {DmvState} from "@/store/dmv/types";
import {Module} from "vuex";
import {RootState} from "@/store/type";
import getters from "@/store/dmv/getters";
import mutations from "@/store/dmv/mutations";
import actions from "@/store/dmv/actions";

const state: DmvState = {
    subWaves: [],
    playingMain: false,
    loop: false
}
export const dmv: Module<DmvState, RootState> = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
