



















import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import CreditCard from "@/components/infrastructures/atoms/CreditCard.vue";
import CreditCardSearchTable from "@/components/domains/payment/creditcard/CreditCardSearchTable.vue";
import CreditCardRegister from "@/components/domains/payment/creditcard/CreditCardRegister.vue";
import {PurchaseGetterTypes, PurchaseMutationTypes} from "@/store/product/purchase/types";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import CreditCardForm from "@/components/domains/payment/creditcard/CreditCardForm.vue";

@Component({
  components: {
    CreditCardForm,
    PrimaryBtn, BackBtn, MyPageContentTemplate, CreditCard, CreditCardSearchTable, CreditCardRegister
  }
})
export default class CreditCardStep extends Step {

  name = "CreditCardStep";
  selectedCard: any[] = [];

  // input card
  showInputCard = false;

  public getComponentName(): string {
    return this.name;
  }

  public getStepTitle(): string {
    return "お支払い情報";
  }

  protected validate(): boolean {
    return this.$store.getters[PurchaseGetterTypes.getCreditCardId] !== ""
  }

  @Watch("selectedCard")
  setCreditCard() {
    if (this.selectedCard.length == 1) {
      this.$store.commit(PurchaseMutationTypes.setCreditCardId, (this.selectedCard[0] as any).id)
    } else {
      this.$store.commit(PurchaseMutationTypes.setCreditCardId, "")
    }
    this.checkComplete()
  }

  afterRegistered() {
    this.setCreditCard();
    if (this.validate()) {
      this.next();
    }
  }
}
