







import {Component, Vue} from "vue-property-decorator";

@Component
export default class DescriptionText extends Vue {
  name = "DescriptionText";
}
