





















































































import {Component, Watch} from "vue-property-decorator";
import ManagementTemplate from "@/components/templates/loggedin/management/ManagementTemplate.vue";
import MyPage from "@/components/domains/account/mypage/MyPage.vue";

@Component({
  components: {ManagementTemplate}
})
export default class PicManagementPage extends MyPage {
  name = "PicManagementPage";

  @Watch('user.id')
  checkUser() {
    if (!this.isPersonInCharge) {
      this.$router.push("/");
    }
  }

  get isPersonInCharge() {
    return this.user.roles.includes('ROLE_PERSON_IN_CHARGE')
  }
}
