











































import {Component, Vue, Watch} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component
export default class ApprovalList extends Vue {
  name = "ApprovalList";
  hiddenList = false;

  header = [
    // {text: '申請コード', value: 'code'},
    {text: '申請者', value: 'applicant'},
    {text: '申請種別', value: 'type'},
    {text: '状態', value: 'status'},
    {text: '申請日', value: 'appliedDate'},
    {text: '承認日', value: 'approvedDate'},
    {text: '承認/却下', value: 'approve-reject'},
    {text: '削除', value: 'delete'},
  ];

  options = {}
  items = []
  selectedItems = []
  total = 0
  loading = false

  mounted() {
    this.getDataFromApi();
  }

  onClickApprove(item: { code: string }) {
    const data = {"applyingCode": item.code};
    httpResource.post('pic/approval/approve', data)
        .then((response: any) => {
          alert('申請コード: ' + response.data + 'を承認しました。');
        })
        .catch((error: any) => {
          // NOP
        });
  }

  onClickReject(item: any) {
    const data = {"applyingCode": item.code};
    httpResource.post('pic/approval/reject', data)
        .then((response: any) => {
          alert('申請コード: ' + response.data + 'を却下しました。');
        })
        .catch((error: any) => {
          // NOP
        });
  }

  onClickDelete(item: any) {
    const data = {"applyingCode": item.code};
    httpResource.post(`pic/approval/delete`, data)
        .then((response: any) => {
          alert('申請コード: ' + response.data + 'の情報を削除しました。');
        })
        .catch((error: any) => {
          // NOP
          alert('削除失敗: ' + error.response.data.message);
        });
  }

  @Watch("options")
  getDataFromApi() {
    this.loading = true
    this.apiCall().then((data: any) => {
      this.total = data.total;
      this.items = data.items;
    }).catch((error: any) => {
      // NOP
    }).finally(() => {
      this.loading = false;
    })
  }

  async apiCall() {

    const {page, itemsPerPage} = this.options as any

    const size = itemsPerPage == -1 ? this.total : itemsPerPage;
    if (size > 99) {
      alert('100件以上の表示はできません。')
      return;
    }

    // ページ更新するたびにtotal取得するが、動的に担当者がカルテを追加した場合を想定している
    const totalResponse = await httpResource.get(`pic/approval/applying-count`);
    const caltesResponse = await httpResource.get(`pic/approval/applying?page=${page - 1}&size=${size}`);

    return {items: caltesResponse.data, total: totalResponse.data};
  }
}
