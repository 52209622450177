import {Vue} from "vue-property-decorator";
import Mixin from "vue-class-component"

@Mixin
export default class AuthMixin extends Vue {

    beforeCreate() {
        // this.$store.dispatch(AuthActionTypes.verifyLogIn).then((isLoggedIn: boolean) => {
        //     if (!isLoggedIn) {
        //         return router.push("/")
        //     }
        // })
    }

    updated() {
        // this.$store.dispatch(AuthActionTypes.verifyLogIn).then((isLoggedIn: boolean) => {
        //     if (!isLoggedIn) {
        //         return router.push("/")
        //     }
        // })
    }
}
