









import {Component, Vue} from "vue-property-decorator";

@Component
export default class HpTitle extends Vue {
  name = "HpTitle"
}
