import {ActionTree} from "vuex";
import {
    AIEstheActionTypes,
    AIEstheGetterTypes,
    AIEstheMutationTypes,
    AIEstheState
} from "@/store/aiesthe/types";
import {RootState} from "@/store/type";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

const actions: ActionTree<AIEstheState, RootState> = {
    [AIEstheActionTypes.getCurrentStep]: async ({commit, getters}) => {
        const response = await httpResource.get('aiesthe/current-step/');
        commit(AIEstheMutationTypes.setAfter, response.data.after);
        commit(AIEstheMutationTypes.setId, response.data.facialExpressionSoundResultId);
        commit(AIEstheMutationTypes.setSoundId, response.data.soundId);
        commit(AIEstheMutationTypes.setSoundSrc, response.data.soundSrc);
    },
    [AIEstheActionTypes.sendImage]: async ({getters, commit}) => {
        const id = getters[AIEstheGetterTypes.getId]
        const course = getters[AIEstheGetterTypes.getCourse]
        const image = getters[AIEstheGetterTypes.getCurrentImage]
        const after = getters[AIEstheGetterTypes.isAfter]
        const publish = getters[AIEstheGetterTypes.isPublish]

        if (id === '') {
            throw new Error('idが空です。送信に失敗しました。')
        } else if (image == '') {
            throw new Error('画像が空です。送信に失敗しました。')
        }

        const detectRequest = {
            id: id,
            after: after,
            course: course,
            image: image.replace(/^data:image\/[a-z]+;base64,/, ''),
            publish: publish
        }

        const response = await httpResource.post('aiesthe/detect/', detectRequest)
        commit(AIEstheMutationTypes.setSoundSrc, response.data.soundSrc)
    },
    [AIEstheActionTypes.sendImageAfter]: async ({getters, commit}) => {
        const id = getters[AIEstheGetterTypes.getId]
        const course = getters[AIEstheGetterTypes.getCourse]
        const image = getters[AIEstheGetterTypes.getCurrentImage]
        const after = getters[AIEstheGetterTypes.isAfter]
        const publish = getters[AIEstheGetterTypes.isPublish]

        if (id === '') {
            throw new Error('idが空です。送信に失敗しました。')
        } else if (image == '') {
            throw new Error('画像が空です。送信に失敗しました。')
        }

        const detectRequest = {
            id: id,
            after: after,
            course: course,
            image: image.replace(/^data:image\/[a-z]+;base64,/, ''),
            publish: publish
        }

        const response = await httpResource.post('aiesthe/detect-after/', detectRequest)
        commit(AIEstheMutationTypes.setDetectFaceResult, response.data)
    },
};

export default actions;
