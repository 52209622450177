













































import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import {AIEstheActionTypes, AIEstheGetterTypes, AIEstheMutationTypes} from "@/store/aiesthe/types";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import {AuthGetterTypes} from "@/store/auth/types";
import DefaultVue from "vue";

@Component({
  components: {PrimaryBtn, KawaiiButton, VerticalSlotContents}
})
export default class AfterCameraStep extends Step {
  name = "AfterCameraStep";
  image = "";
  disabledSent = true;
  dialog = false;
  overlay = false;
  sentError = false;
  publish = false;

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {
    this.$store.dispatch(AIEstheActionTypes.getCurrentStep).then(() => {
      const after = this.$store.getters[AIEstheGetterTypes.isAfter];
      if (!after) {
        this.$router.push('/mypage/aiesthe');
      }
    })
  }

  getStepTitle(): string {
    return "AIエステ.顔診断エステ.After撮影";
  }

  getStepSubTitle(): string {
    return "振動の効果を確認します。";
  }

  sendImage() {
    this.overlay = true;
    this.$store.dispatch(AIEstheActionTypes.sendImageAfter).then(() => {
      this.dialog = true;
    }).catch((error) => {
      alert('写真の読み取りに失敗しました。もう一度お試しください。');
      this.sentError = true;
    }).finally(() => {
      this.overlay = false;
    });
  }

  decide() {
    this.dialog = false;
    this.overlay = false;
    if (!this.sentError) {
      // this.$router.push('/mypage/aiesthe');
      this.next();
    }
    this.sentError = false;
  }

  @Watch('image')
  private watchImage() {
    this.$store.commit(AIEstheMutationTypes.setImage, this.image)
    this.disabledSent = this.image === "";
  }

  @Watch('publish')
  private watchPublish() {
    this.$store.commit(AIEstheMutationTypes.setPublish, this.publish)
  }

  get isCustomer() {
    return this.$store.getters[AuthGetterTypes.getCurrentUser].roles.indexOf("ROLE_CUSTOMER") !== -1;
  }
}
DefaultVue.component(AfterCameraStep.name, AfterCameraStep);
