











































import {Component, Vue, Watch} from "vue-property-decorator";
import AIEstheHistoryTable from "@/components/domains/esthe/aiesthe/history/AIEstheHistoryTable.vue";
import AIEstheDetectFaceResultCard from "@/components/domains/esthe/aiesthe/AIEstheDetectFaceResultCard.vue";
import AIEstheService from "@/components/domains/esthe/aiesthe/aiEstheService";

@Component({
  components: {AIEstheDetectFaceResultCard, AIEstheHistoryTable}
})
export default class AIEstheComparingHistory extends Vue {
  name = "AIEstheComparingHistory";

  headers = [
    // {text: "分析結果ID", value: "id"},
    {text: "記録日", value: "latestUpdate"}
  ];

  beforeResult: any = {};
  afterResult: any = {};
  result: any = {};

  nothing = false;
  loading = true;

  mounted() {
    AIEstheService.historyPagingSize(1, 1)
        .then((data: any) => {
          if (data.total == 0) {
            this.nothing = true;
          }
        });
  }

  selectedBefore(selected: any) {
    this.beforeResult = selected;
  }

  selectedAfter(selected: any) {
    this.afterResult = selected;
  }

  select(id: string) {
    this.loading = true;
  }

  createResult() {

    if (this.beforeResult === {} || this.afterResult === {}) {
      return;
    }

    this.result = {
      id: this.beforeResult.id + this.afterResult.id,
      beforeImage: this.beforeResult.beforeImage,
      beforeProcFaceImgs: this.beforeResult.beforeProcFaceImgs,
      beforeResult: this.beforeResult.beforeResult,
      beforeLatestUpdate: this.beforeResult.latestUpdate,
      afterImage: this.afterResult.afterImage,
      afterProcFaceImgs: this.afterResult.afterProcFaceImgs,
      afterResult: this.afterResult.afterResult,
      afterLatestUpdate: this.afterResult.latestUpdate,
      dmvResults: []
    };

    this.loading = false;
  }

  @Watch('beforeResult')
  watchBeforeResult() {
    this.createResult();
  }

  @Watch('afterResult')
  watchAfterResult() {
    this.createResult();
  }
}
