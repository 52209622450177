export default class MentalCheckQuestionResult {

  /**
   * NO
   */
  public static readonly NO = 0;

  /**
   * YES
   */
  public static readonly YES = 1;

  public static readonly HEAD = 10;
  public static readonly EYES = 11;
  public static readonly NECK = 12;
  public static readonly IN_STOMACH = 13;
  public static readonly SURFACE_STOMACH = 14;
  public static readonly LEGS = 15;
  public static readonly KNEES = 16;
  public static readonly HEART = 17;
  public static readonly NOTHING = 99;

}
