import {CalendarType} from "@/components/domains/calender/calendarType";

export class CalendarSpecification {

    public static colorOf(event: { name: string }) {
        if (CalendarType.AI_ESTHE.is(event)) {
            return 'secondary';
        } else if (CalendarType.CALTE_RECORD.is(event)) {
            return 'green';
        }
        return 'black';
    }
}