import DateFactory from "@/components/infrastructures/functions/time/dateFactory";

export default class StopWatchDateTime {

    startDateTime = DateFactory.newDate();
    endDateTime = DateFactory.newDate();

    constructor(public hour: number = 0, public min: number = 0, public sec: number = 0) {
        // NOP
    }

    public get formattedStartDateTime(): string {
        return StopWatchDateTime.formatDate(this.startDateTime);
    }

    public get formattedEndDateTime(): string {
        return StopWatchDateTime.formatDate(this.endDateTime);
    }

    public get formattedTotalPlayingTime(): string {
        return StopWatchDateTime.formatTime(this.hour, this.min, this.sec);
    }

    private static formatDate(date: Date): string {

        const d = new Date(date);
        const year = d.getFullYear();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;


        return [year, month, day].join('-') + ' ' + StopWatchDateTime.formatTime(date.getHours(), date.getMinutes(), date.getSeconds());
    }

    private static formatTime(hour: number, min: number, sec: number): string {

        let h = '' + hour;
        let m = '' + min;
        let s = '' + sec;

        if (h.length < 2)
            h = '0' + h;
        if (m.length < 2)
            m = '0' + m;
        if (s.length < 2)
            s = '0' + s;

        return [h, m, s].join(':');
    }
}