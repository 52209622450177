






































import {Component, Prop, Vue} from "vue-property-decorator";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";

@Component({
  components: {SubBtn, PrimaryBtn}
})
export default class KawaiiDaialog extends Vue {

  name = 'KawaiiDialog';

  @Prop()
  public dialog!: boolean;

  @Prop({default: ""})
  title!: string;

  @Prop({default: ""})
  message!: string;

  @Prop({default: "OK"})
  btnMessage!: string;

  @Prop({default: "secondary"})
  color!: string;

  @Prop({default: false})
  hasError!: boolean;

  @Prop({default: 290})
  maxWidth!: number;

  @Prop({default: false})
  hiddenBtn!: boolean;

  @Prop({default: false})
  hiddenOkBtn!: boolean;

  @Prop({default: false})
  showCloseBtn!: boolean;

  @Prop({default: "CLOSE"})
  closeBtnMessage!: string;

  private decide() {
    this.$emit('decide');
  }

  private close() {
    this.$emit('close');
  }
}
