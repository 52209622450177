





























































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultVue from "vue";
import DMVRecordResultCard from "@/components/domains/esthe/dmv/DMVRecordResultCard.vue";
import DateTimeFormatter from "@/components/infrastructures/functions/time/dateTimeFormatter";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";

@Component({
  components: {KawaiiButton, DMVRecordResultCard}
})
export default class AIEstheDetectFaceResultCard extends Vue {
  name = "AIEstheDetectFaceResultCard";
  @Prop() result: any;
  tab = 0;

  // snackbar
  snackbar = false;

  formatDate(str: string) {
    return DateTimeFormatter.formatFromStr(str);
  }
}
DefaultVue.component(AIEstheDetectFaceResultCard.name, AIEstheDetectFaceResultCard);
