









import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Overlay extends Vue {
  name = "Overlay";
  @Prop({default: false}) overlay!: boolean;
}
