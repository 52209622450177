import {GetterTree} from "vuex";
import {AuthGetterTypes, AuthState} from "@/store/auth/types";
import {RootState} from "@/store/type";

const getters: GetterTree<AuthState, RootState> = {
    [AuthGetterTypes.getIsAuthenticated]: (state: AuthState) => {
        return state.authenticated;
    },
    [AuthGetterTypes.getCurrentUser]: (state: AuthState) => {
        return state.currentUser;
    },
    [AuthGetterTypes.getIntervalName]: (state: AuthState) => {
        return state.intervalName;
    },
    [AuthGetterTypes.getMyPageLinks]: (state: AuthState) => {
        return state.myPageLinks;
    },
}

export default getters;
