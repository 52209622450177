













import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultVue from "vue";
import SearchTable from "@/components/infrastructures/molecules/SearchTable.vue";
import {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

export class PaymentHistorySearchAction implements SearchAction {

  async action(condition: string): Promise<[{}]> {
    const response = await httpResource.get(`payment/fetch-history`);
    return response.data.map((v: any) => {
      v.price = "\xA5" + Number(v.price).toLocaleString();
      return v
    });
  }
}

@Component({
  components: {SearchTable}
})
export default class PaymentHistoryTable extends Vue {

  name = "PaymentHistoryTable";
  @Prop() private value!: [];
  @Prop({default: false}) showSelect: boolean;

  // local args
  headers = [
    {text: "購入日", value: "startedAt"},
    {text: "商品名", value: "productDisplayName"},
    {text: "商品種別", value: "productType"},
    {text: "値段", value: "price"},
  ];
  searchCreditCardAction = new PaymentHistorySearchAction();

  private get inputValue(): {}[] {
    return this.value;
  }

  private set inputValue(value: {}[]) {
    this.$emit('input', value);
  }

  changeSelection(card: any) {
    this.$emit('change', card)
  }
}
DefaultVue.component(PaymentHistoryTable.name, PaymentHistoryTable);
