




























































































import {Component, Prop, VModel, Vue, Watch} from "vue-property-decorator";
import PasswordField from "@/components/infrastructures/atoms/PasswordField.vue";
import FieldData from "@/components/infrastructures/functions/fieldData";
import HashMap from "@/components/infrastructures/functions/hashMap";

@Component({
  components: {PasswordField}
})
export default class GeneralField extends Vue {

  name = "GeneralField";

  @VModel() field!: FieldData;
  @Prop({default: ''}) label!: string;
  @Prop({default: false}) disable!: boolean;
  @Prop({default: ''}) placeholder!: string;
  @Prop({default: () => ''}) validate!: string;
  @Prop({default: () => []}) selection!: {}[];
  @Prop() dataMap!: HashMap<string>;

  // inner
  visiblePassword = false;

  @Watch('field.value')
  watchValueField() {
    this.doValidate();
  }

  public doValidate() {

    if (!(this.field == null || this.dataMap == null)) {
      this.dataMap.put(this.field.id, this.field.value);
    }

    this.field.error = false;
    this.field.errorMessage = '';

    if (this.field.requires) {

      if (this.field.type === "select") {
        // TODO
      } else if ((this.field.value == null || this.field.value.trim() === '')) {
        this.field.error = true;
        this.field.errorMessage = '入力してください。';
      }
    }

    if (this.field.value == null) {
      return;
    }

    if (this.field.maxLength > 0 && this.field.value.trim().length > this.field.maxLength) {
      this.field.error = true;
      this.field.errorMessage = this.field.maxLength + '桁以内で入力してください。';
    }
  }

  get getPasswordFieldStatus() {
    return this.visiblePassword ? "非表示にする" : "表示する";
  }

  get getPasswordFieldType() {
    return this.visiblePassword ? "text" : "password";
  }

  private submit() {
    // NOP
  }
}
