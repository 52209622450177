








import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class AILoading extends Vue {
  name = "AILoading"
  @Prop({default: ""}) title: string;
}
