import {CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Title} from "chart.js";

export default class ChartJs {

    public static register() {
        Chart.register(LineController,
            LineElement,
            CategoryScale,
            PointElement,
            LinearScale,
            Title);
    }
}