

























































































import {Component, Vue, Watch} from "vue-property-decorator";
import FieldData from "@/components/infrastructures/functions/fieldData";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import {AuthActionTypes} from "@/store/auth/types";
import router from "@/router";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";

@Component({
  components: {KawaiiDialog, GeneralField}
})
export default class PICRegisterForm extends Vue {

  errors: any[] = [];
  name = "PICRegisterForm";
  hasError = false;

  dialog = false;

  // 団体情報
  private agencyNameField: FieldData = new FieldData()
      .withId("agencyNameCode")
      .withRequires(true)
      .withType("text_field")
      .withLabel("団体名");

  // 担当者情報
  private usernameField: FieldData = new FieldData()
      .withId("username")
      .withRequires(true)
      .withType("text_field")
      .withLabel("ユーザー名 (半角英数字)");

  private loginIdField: FieldData = new FieldData()
      .withId("loginId")
      .withRequires(true)
      .withType("text_field")
      .withLabel("メールアドレス");

  private passwordField: FieldData = new FieldData()
      .withId("password")
      .withRequires(true)
      .withType("password")
      .withLabel("パスワード (8文字以上)");

  private verifyPasswordField: FieldData = new FieldData()
      .withId("verify_password")
      .withRequires(true)
      .withType("password")
      .withLabel("確認用パスワード");

  private registerBtnField: FieldData = new FieldData()
      .withId("registerBtn")
      .withType("button")
      .withLabel('仮登録')
      .withClickAction(this.register);

  private resetBtnField: FieldData = new FieldData()
      .withId("resetBtn")
      .withType("button")
      .withLabel('リセット')
      .withClickAction(this.reset);

  @Watch('passwordField.value')
  private u1() {
    this.validatePassword();
  }

  @Watch('verifyPasswordField.value')
  private u2() {
    this.validatePassword();
  }

  validatePassword() {
    const password = this.passwordField.value.trim();
    const verifyPassword = this.verifyPasswordField.value.trim();
    this.passwordField.resetError();
    this.verifyPasswordField.resetError();
    if (password !== '' && verifyPassword !== '' && password !== verifyPassword) {
      this.passwordField.error = true;
      this.passwordField.errorMessage = 'パスワードが一致しません';
      this.verifyPasswordField.error = true;
      this.verifyPasswordField.errorMessage = 'パスワードが一致しません';
    }
  }

  register() {

    this.resetFormErrors();

    const data = {
      agencyName: this.agencyNameField.value,
      email: this.loginIdField.value,
      username: this.usernameField.value,
      password: this.passwordField.value,
      verifyPassword: this.verifyPasswordField.value
    };

    httpResource.post('/account/register-agency-pic', data)
        .then((response: any) => {
          alert("申請コード: " + response.data + "にてアカウント登録申請を受け付けました。");
          const loginId = this.usernameField.value;
          const password = this.passwordField.value;
          this.$store.dispatch(AuthActionTypes.login, {"loginId": loginId, "password": password})
              .then(() => {
                router.push("/mypage");
              });
        })
        .catch((error: any) => {
          alert(error.response.data.message);
        });
  }

  reset() {
    this.resetFormErrors();
    this.agencyNameField.withValue('');
    this.loginIdField.withValue('');
    this.usernameField.withValue('');
    this.passwordField.withValue('');
    this.verifyPasswordField.withValue('');
  }

  resetFormErrors() {
    this.resetErrors();
    this.loginIdField.withError(false);
    this.loginIdField.withErrorMessage('');
    this.passwordField.withError(false);
    this.passwordField.withErrorMessage('');
  }

  resetErrors() {
    this.hasError = false;
    this.errors = [];
  }
}
