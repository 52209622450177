import CalteFormatFieldData from "@/components/domains/calte/calteFormatFieldData";

export default class CalteFormat {

    calteId = "";
    title = "";
    calteItems: CalteFormatFieldData[] = [];

    public hasEmptyInRequiredFields(): boolean {
        for (const calteItem of this.calteItems) {
            if (calteItem.requires && (calteItem.value == null || (calteItem.value.trim() == ''))) {
                return true;
            }
        }
        return false;
    }

    public hasEmptyInLabels(): boolean {
        for (const calteItem of this.calteItems) {
            if ((calteItem.label == null || calteItem.label.trim() == '')) {
                return true;
            }
        }
        return false;
    }

    public static createFromJson(json: any) {
        return this.createWithId(json.id, json.title, json.items);
    }

    public static createWithId(id: string, title: string, items: {}[]): CalteFormat {
        const calteFormat = new CalteFormat();
        const calteItems = items.map((item: any, index: number) => {
            const data = new CalteFormatFieldData()
                .withCustomerInput(item.customerInput)
                .withCustomerPublish(item.customerPublish)
                .withId((index + 1).toString())
                .withVerticalPriority(index + 1)
                .withRequires(item.requires)
                .withValue(item.value)
                .withType(item.type)
                .withLabel(item.label) as CalteFormatFieldData;
            if (item.id != null) {
                data.withId(item.id);
            }
            return data;
        });
        calteFormat.calteId = id;
        calteFormat.title = title;
        calteFormat.calteItems = calteItems;
        return calteFormat;
    }

    public static create(title: string, items: {}[]): CalteFormat {
        return this.createWithId('', title, items);
    }
}