































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import CalteFormat from "@/components/domains/calte/calteFormat";
import DefaultVue from 'vue';
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";

@Component({
  components: {GeneralField}
})
export default class Calte extends Vue {

  name = "Calte";
  @Prop({default: () => new CalteFormat()}) value: CalteFormat;

  @Watch("value")
  watchVal() {
    // NOP
  }

  createLabel(field: any) {
    if (field.customerInput) {
      return field.label + '（お客様記入欄）';
    }
    if (field.customerPublish && this.isCustomer) {
      return field.label + '（担当者記入欄）';
    }
    if (field.customerPublish && this.isPersonInCharge) {
      return field.label + '（お客様公開）';
    }
    if (this.isPersonInCharge) {
      return field.label + '（お客様非公開）';
    }
    return field.label;
  }

  disableField(field: { customerInput: boolean }) {
    // 顧客かつ顧客入力項目ではない
    if (this.isCustomer && !field.customerInput) {
      return true;
    }
    // 担当者かつ顧客入力項目である
    if (this.isPersonInCharge && field.customerInput) {
      return true;
    }
    return false;
  }

  get getCalte(): CalteFormat {
    return this.value;
  }

  get isCustomer(): boolean {
    const user = this.$store.getters.getCurrentUser;
    return user.roles.includes("ROLE_CUSTOMER")
        && !user.roles.includes("ROLE_PERSON_IN_CHARGE");
  }

  get isPersonInCharge(): boolean {
    const user = this.$store.getters.getCurrentUser;
    return user.roles.includes("ROLE_PERSON_IN_CHARGE");
  }
}

DefaultVue.component(Calte.name, Calte);
