









































































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CategoryCard extends Vue {

  name = "CategoryCard";

  @Prop() img!: string;
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop({default: false}) disable!: boolean;
  @Prop({default: false}) defaultShow!: boolean;
  private show = false;

  beforeMount() {
    this.show = this.defaultShow;
  }
}
