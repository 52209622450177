



































































import {Prop, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import DefaultVue from "vue";

export interface SearchAction {
  action(condition: string): Promise<[{}]>;
}

@Component
export default class SearchDialog extends Vue {

  name = "SearchDialog";
  // args properties
  @Prop() btnText!: string;
  @Prop() title!: string;
  @Prop() placeholder!: string;
  @Prop() headers!: { [text: string]: string };
  @Prop() itemKey!: string;
  @Prop() searchAction!: SearchAction;
  @Prop({default: false}) hideSearchFunction: boolean;
  @Prop({default: ''}) condition: string;

  // local properties
  dialog = false;
  item = null;
  items: {}[] = [];
  activeDecision = true;
  selectedItems = [];

  private decide() {
    this.dialog = false;
    this.$emit("onDecide", this.item)
  }

  private search() {
    this.searchAction.action(this.condition).then((items: [{}]) => {
      this.items = items;
    }).catch((error: any) => {
      // TODO: Error handling
    });
  }

  @Watch("selectedItems")
  private watchSelected(selectedItems: any) {
    this.item = selectedItems[0];
    this.activeDecision = selectedItems.length === 0;
  }
}
DefaultVue.component(SearchDialog.name, SearchDialog);
