





import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class LangSelection extends Vue {

  lang = this.$i18n.locale === 'ja' ? '英語' : '日本語'

  @Prop({default : false}) small: boolean;

  changeLang() {
    if (this.$i18n.locale === 'ja'){
      this.$i18n.locale = 'en'
      this.lang = '日本語'
    }
    else if (this.$i18n.locale === 'en'){
      this.$i18n.locale = 'ja'
      this.lang = '英語'
    }
  }
}
