







































import {Component, Vue} from "vue-property-decorator";
import HomeTemplate from "@/components/templates/home/HomeTemplate.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import NewChatPage from "@/components/domains/support/NewChatForm.vue";
import NewChatForm from "@/components/domains/support/NewChatForm.vue";
import Header from "@/components/templates/default/DefaultHeader.vue";
import SupportService from "@/components/domains/support/supportService";
import InquiryList from "@/components/domains/support/InquiryList.vue";

@Component({
  components: {InquiryList, NewChatForm, NewChatPage, KawaiiDialog, ContentTitle, PrimaryBtn, HomeTemplate}
})
export default class ChatSupportPage extends Vue {
  name = "ChatSupportPage";
  links = Header.links;

  tab = 0;

  // dialog
  dialog = false;
  inquiries = [];

  beforeMount() {
    this.updateInquiries();
  }

  updateInquiries() {
    SupportService.fetchInquiries()
        .then(data => {
          this.inquiries = data;
        });
  }

  afterSending() {
    this.dialog = false;
    this.updateInquiries();
  }
}
