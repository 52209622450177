












import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultHeader from "@/components/templates/default/DefaultHeader.vue";
import Header from "@/components/templates/default/DefaultHeader.vue";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";
import LoggedInHeader from "@/components/templates/loggedin/LoggedInHeader.vue";
import MenuPanel from "@/components/domains/account/mypage/MenuPanel.vue";
import DefaultFooter from "@/components/templates/default/DefaultFooter.vue";
import HomeFooter from "@/components/templates/home/HomeFooter.vue";
import HomeSmartphoneTemplate from "@/components/templates/home/HomeSmartphoneTemplate.vue";
import HomePcTemplate from "@/components/templates/home/HomePcTemplate.vue";

@Component({
  components: {
    HomePcTemplate,
    HomeSmartphoneTemplate,
    HomeFooter, DefaultFooter, MenuPanel, LoggedInHeader, DescriptionText, KawaiiButton, DefaultHeader
  }
})
export default class HomeTemplate extends Vue {
  name: "HomeTemplate"

  @Prop({default: Header.links}) links!: [];

}
