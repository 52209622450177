import HashMap from "@/components/infrastructures/functions/hashMap";
import Question from "@/components/infrastructures/functions/question/question";
import Answer from "@/components/infrastructures/functions/question/answer";

export default class QuestionTreeData {

    private answerQuestionMap: HashMap<Question> = new HashMap<Question>();
    private questions: string[] = [];

    constructor(private readonly firstQuestion: Question) {
        this.questions.push(firstQuestion.getId())
    }

    public getQuestionSize() {
        return this.questions.length;
    }

    public getFirstQuestion() {
        return this.firstQuestion;
    }

    public hasNextQuestion(answerId: string): boolean {
        return this.answerQuestionMap.has(answerId);
    }

    public getQuestion(answerId: string): Question {
        return this.answerQuestionMap.get(answerId);
    }

    public add(answer: Answer, question: Question) {
        if (this.questions.indexOf(question.getId()) === -1) {
            this.questions.push(question.getId());
        }
        this.answerQuestionMap.put(answer.getId(), question);
    }
}
