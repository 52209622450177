


































































import {Component, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component({
  components: {KawaiiDialog, KawaiiButton}
})
export default class EasilyLoginIssueURL extends Vue {
  private name = "EasilyLoginIssueURL";

  // URL
  private url = '';
  private enabled = false;
  private issued = false;

  // ダイアログ
  private dialog = false;
  private dialogMessage = '';

  created() {
    this.checkIssued();
    this.checkEnabled();
  }

  private decide() {
    this.dialog = false;
  }

  private issue(): void {
    const baseUrl = process.env.VUE_APP_BASE_URL;
    httpResource.post('/easily-login/register')
        .then((response: any) => {

          const data = response.data;
          // & のエスケープ文字列 => %26
          this.url = `${baseUrl}easily-login/userId=${data.userId}%26authId=${data.authId}`;

          // manifest.json を取得しなおす
          const elements = document.getElementById("app-manifest");
          const apiUrl = process.env.VUE_APP_MANIFEST_URL;
          const manifestUrl = `${apiUrl}?startUrl=${this.url}`;

          elements!.setAttribute("href", manifestUrl);
        });
  }

  private checkIssued(): void {
    httpResource.get('/easily-login/issued')
        .then((response: any) => {
          const data = response.data;
          this.issued = data;
        });
  }

  private checkEnabled(): void {
    httpResource.get('/easily-login/enabled')
        .then((response: any) => {
          const data = response.data;
          this.enabled = data;
        });
  }

  private disable() {
    httpResource.post('/easily-login/enable?enabled=false')
        .then(() => {
          this.$router.go(0);
        });
  }

  private enable() {
    httpResource.post('/easily-login/enable?enabled=true')
        .then(() => {
          this.$router.go(0);
        });
  }

  private copyUrlToClipBoard() {
    const str = this.url;
    const listener = function (e: any) {
      e.clipboardData.setData("text/plain", str);
      // 本来のイベントをキャンセル
      e.preventDefault();
      // 終わったら一応削除
      document.removeEventListener("copy", listener);
    }
    // コピーのイベントが発生したときに、クリップボードに書き込むようにしておく
    document.addEventListener("copy", listener);
    // コピー
    document.execCommand("copy");
    // URL をコピーしました。
    this.dialogMessage = '簡単ログイン URL をクリップボードにコピーしました。';
    this.dialog = true;
  }

  private isAccessFromMobile() {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }
}
