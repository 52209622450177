export default class Answer {

  private static idSeq = 0;

  private id = '';

  constructor(private text: string, private result: number) {
    this.id = Math.random().toString(32);
  }

  public getId() {
    return this.id;
  }

  public getText() {
    return this.text;
  }

  public getResult() {
    return this.result;
  }
}
