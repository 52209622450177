import {ActionTree} from "vuex";
import {RootState} from "@/store/type";
import {PurchaseActionTypes, PurchaseGetterTypes, PurchaseState} from "@/store/product/purchase/types";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

const actions: ActionTree<PurchaseState, RootState> = {
    [PurchaseActionTypes.purchase]: async ({getters}) => {
        const params = {
            productId: getters[PurchaseGetterTypes.getProductId],
            creditCardId: getters[PurchaseGetterTypes.getCreditCardId],
            deliveryId: getters[PurchaseGetterTypes.getDeliveryAddressId],
            userIds: getters[PurchaseGetterTypes.getUsers]
        }
        if (params.deliveryId !== '') {
            return httpResource.post("v3/subscription/order/", params);
        } else {
            return httpResource.post("v3/subscription/subscribe/", params);
        }
        // return httpResource.post("payment/subscribe/", params);
    },
    [PurchaseActionTypes.fetchOrderDetails]: async ({getters}) => {
        const params = {
            productId: getters[PurchaseGetterTypes.getProductId],
            userIds: getters[PurchaseGetterTypes.getUsers]
        }
        return httpResource.post("payment/order-details/", params);
    },
}

export default actions;
