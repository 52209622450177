








import {Component, Vue} from "vue-property-decorator";
import MentalCheckQuestionHolder
  from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/mentalCheckQuestionHolder";
import Answer from "@/components/infrastructures/functions/question/answer";
import MentalCheckService from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/mentalCheckService";
import Result from "@/components/domains/esthe/aiesthe/plans/dr_ai_healthcare/Result.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import EstheService from "@/components/domains/esthe/EstheService";

@Component({
  components: {MyPageContentTemplate, Result}
})
export default class Up65Page extends Vue {
  name = "Up65Page";

  questionTree = MentalCheckQuestionHolder.createUpper65Tree();
  answerToSoundSrc = function (answers: Array<Answer>) {

    const data = {
      sleeping: answers[0].getResult(),
      appetite: answers[1].getResult(),
      well: answers[2].getResult(),
      forget: answers[3].getResult(),
    }

    return MentalCheckService.answerUp65(data);
  }

  mounted() {

    EstheService.checkAvailableDrAiAndHealthCare()
        .then(res => {
          if (res.data !== 'E0000') {
            this.$router.push('/mypage/aiesthe');
          }
        });
  }
}
