










































































































































import {Component, Mixins, Watch} from "vue-property-decorator";
import store from '@/store';
import router from "@/router";
import {User} from "@/components/domains/authorization/user";
import AuthMixin from "@/components/domains/authorization/authMixin"
import {AuthActionTypes, AuthGetterTypes} from "@/store/auth/types";
import LoggedInTemplate from "@/components/templates/loggedin/LoggedInTemplate.vue";
import NoticeBanner from "@/components/infrastructures/atoms/designed/NoticeBanner.vue";
import ActivityNoticeBanner from "@/components/domains/activity/ActivityNoticeBanner.vue";

@Component({
  components: {ActivityNoticeBanner, NoticeBanner, LoggedInTemplate}
})
export default class MyPage extends Mixins<AuthMixin>(AuthMixin) {

  public name = "MyPage";
  protected user: User = store.getters[AuthGetterTypes.getCurrentUser];
  protected links: {}[] = store.getters[AuthGetterTypes.getMyPageLinks];
  protected status: {} = this.getStatus;

  protected ex = 25;

  mounted() {
    this.$store.dispatch(AuthActionTypes.authenticateUser).then(() => {
      this.user = store.getters[AuthGetterTypes.getCurrentUser];
      this.links = this.$store.getters[AuthGetterTypes.getMyPageLinks];
      if (router.currentRoute.path === '/mypage' && this.links[0]) {
        router.push((this.links.filter((l: any) => l.type !== "title")[0] as any).link);
      }
    });
  }

  get getStatus() {
    switch (this.user.agency.status) {
      case '0':
        this.status = {name: '本登録済み', color: 'primary', textColor: 'black', code: '0'};
        break;
      case '1':
        this.status = {name: '仮登録中', color: 'info', textColor: 'black', code: '1'};
        break;
      default :
        this.status = {name: '停止中', color: '', textColor: 'black', code: '9'};
    }
    return this.status;
  }

  get getPremium() {
    if (this.user.premium) {
      return {name: 'プレミアム会員', color: 'success', textColor: 'black'};
    } else {
      return {name: '一般会員', color: 'primary', textColor: 'black'};
    }
  }

  get isEnterprise() {
    return this.user.enterprise
  }

  logout() {
    this.$store.dispatch(AuthActionTypes.logout).then(() => {
      router.push('/login')
    })
  }

  getUser(): User {
    this.$store.dispatch(AuthActionTypes.verifyLogIn).then(() => {
      this.user = this.$store.getters.getCurrentUser
    })
    return this.user
  }

  provideDataAgreement(agree: boolean) {
    if (!agree) {
      alert("同意いただけない場合、本サービスをご利用いただくことはできません。");
      router.push('/');
    }
  }

  @Watch('user')
  updateLinks() {
    this.links = this.$store.getters.getMyPageLinks;
  }
}
