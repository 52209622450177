export interface PurchaseState {
    productId: string;
    creditCardId: string;
    addressId: string;
    users: [];
}

export enum PurchaseGetterTypes {
    getProductId = "PurchaseStateGetProductId",
    getCreditCardId = "PurchaseStateGetCreditCardId",
    getDeliveryAddressId = "PurchaseStateGetAddressId",
    getUsers = "PurchaseStateGetUsers",
}

export enum PurchaseMutationTypes {
    clear = "PurchaseStateClear",
    setProductId = "PurchaseStateSetProductId",
    setCreditCardId = "PurchaseStateSetSetCreditCardId",
    setDeliveryAddressId = "PurchaseStateSetAddressId",
    setUsers = "PurchaseStateUsers"
}

export enum PurchaseActionTypes {
    fetchOrderDetails = "PurchaseStateFetchOrderDetails",
    fetchCreditCard = "PurchaseStateFetchCreditCard",
    purchase = "PurchaseStatePurchase"
}
