













































import {Component, Vue} from "vue-property-decorator";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import EnterpriseService from "@/components/domains/enterprise/enterpriseService";
import EnterpriseSection from "@/components/domains/enterprise/dto/enterpriseSection";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";
import EnterpriseSectionManagement from "@/components/domains/enterprise/dto/enterpriseSectionManagement";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import {EnterpriseGetterTypes, EnterpriseMutationTypes} from "@/store/enterprise/types";
import store from "@/store";
import DateTimeFormatter from "@/components/infrastructures/functions/time/dateTimeFormatter";

@Component({
  components: {BackBtn, SmartDMVPlayer, MyPageContentTemplate}
})
export default class EnterpriseControlPlayingPage extends Vue {

  name = "EnterpriseControlPlayingPage";

  // load
  reloadDisplayIntervalSec = 30;
  fetchIntervalId = 0;

  // section
  sectionId = this.$route.params.sectionId;
  selectedSection: EnterpriseSection = new EnterpriseSection();
  selectedManagement: EnterpriseSectionManagement = new EnterpriseSectionManagement();

  sectionLoaded = false;
  createdLoaded = false;

  seconds = 0; // 画面表示からの経過秒数
  stopping = true; // 再生指示
  stoppingSec = 0;
  actStopping = true; // 実際に再生されているか
  audio: IndexedAudio;

  // リクエスト制御
  requesting = false;

  created() {
    this.createdLoaded = false;
    this.receiveStopping();
    this.updateStatusInterval();
    this.createdLoaded = true;
  }

  updateStatusInterval() {

    const timeout = 5000;
    this.fetchIntervalId = setInterval(() => {

      // 前回のリクエストが完了していない場合何もしない
      if (!this.requesting) {
        // ステータス更新
        this.updateStatus();
      }

      // 再生指示があるのに60秒間停止していたら画面更新
      if (this.isPlayingAndActStopping && (this.stoppingSec += (timeout / 1000)) >= this.reloadDisplayIntervalSec) {
        this.$router.go(0);
      }

      this.seconds += timeout / 1000;
      if (this.seconds >= 86400) {
        this.$router.go(0);
      }

    }, timeout);
  }

  beforeDestroy() {
    // TODO なるべく止めないようにする
    // if (this.audio) {
    //   this.audio.stop();
    // }
    // clearInterval(this.fetchIntervalId);
  }

  fetchSection() {

    if (this.sectionId === "") {
      return;
    }

    this.requesting = true;
    this.sectionLoaded = false;
    EnterpriseService.section(this.sectionId)
        .then((result: EnterpriseSection) => {

          // 重複ステータスチェック
          if (result.statusCode === "E1000") {
            clearInterval(this.fetchIntervalId);
            alert("この区画はすでに利用されているため、ご利用いただけません。前の画面に戻ります。");
            this.$router.push('/mypage/enterprise/control');
          }

          // 選択中の音声
          this.selectedSection = result;

          const playings = result.managements;
          playings.forEach(mng => {

            if (mng.id === this.selectedManagement.id) {
              this.selectedManagement.playing = mng.playing;
            } else if (mng.playing) {
              this.selectedManagement = mng;
              return;
            }
          })
        })
        .finally(() => {
          this.sectionLoaded = true;
          this.requesting = false;
        })
  }

  reportStatus() {
    if (this.selectedSection) {
      const data = {
        playingDmvId: this.actStopping ? '' : this.selectedManagement.dmv.id,
        sectionId: this.selectedSection.sectionId,
        actStopping: this.actStopping,
        oldestStopTime: DateTimeFormatter.formatStandard(store.getters[EnterpriseGetterTypes.getOldestStoppedTime])
      }
      EnterpriseService.reportSectionStatus(data);
    }
  }

  updateStatus() {

    // セクションの取得
    this.fetchSection();

    // 重複ステータスチェック
    if (this.selectedSection.statusCode === "E1000") {
      return;
    }

    // レポーティング
    this.reportStatus();

    if (!this.audio) {
      return;
    }

    // 再生許可がある場合※なるべく再生を止めない
    if (this.selectedManagement
        && this.selectedManagement.playing) {
      if (this.selectedManagement.volume) {
        this.audio.audioData.volume = this.selectedManagement.volume;
      }
      this.stopping = false;
      this.audio.start();
    } else {
      this.stopping = true;
      this.audio.pause();
    }

    // 実際に再生が止まっているか
    this.actStopping = this.audio.audioData.paused;
  }

  setAudio(audio: IndexedAudio) {
    this.audio = audio;
  }

  receiveStopping() {
    const stopTime = store.getters[EnterpriseGetterTypes.getOldestStoppedTime];
    if (stopTime.getTime() === new Date(1).getTime()) {
      store.commit(EnterpriseMutationTypes.setOldestStoppedTime, new Date());
    }
  }

  receiveStarting() {
    store.commit(EnterpriseMutationTypes.setOldestStoppedTime, new Date(1));
  }

  get isPlayingAndActStopping() {
    return !this.stopping && this.actStopping;
  }

}
