import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'ja',
    messages: {
        ja: require('@/lang/ja.json'),
        en: require('@/lang/en.json'),
        cn: require('@/lang/cn.json'),
        tw: require('@/lang/tw.json')
    }
});