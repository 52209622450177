import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class ProductService {

    public static async requiresDeliveryAddress(productId: string) {
        return (await httpResource.get(`/v2/product/exists-rental?productId=${productId}`)).data;
    }

    public static async fetchProduct(productId: string) {
        return (await httpResource.get(`/v2/product/get?productId=${productId}`)).data;
    }
}