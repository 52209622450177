import Vue from "vue";
import Calte from "@/components/domains/calte/Calte.vue";
import KawaiiSentMessage from "@/components/infrastructures/molecules/KawaiiSentMessage.vue";
import StepContents from "@/components/infrastructures/molecules/StepContents.vue";
import SelectCourseStep from "@/components/domains/esthe/aiesthe/plans/detectface/steps/SelectCourseStep.vue";
import ChooseAIEstheDetectFaceResultDialog
    from "@/components/domains/esthe/aiesthe/AIEstheChooseDetectFaceResultDialog.vue";
import CalteManagement from "@/components/domains/calte/pic/CalteManagement.vue";
import DetectFaceResultCard from "@/components/domains/esthe/aiesthe/plans/detectface/DetectFaceResultCard.vue";
import FilloutCalte from "@/components/domains/calte/customer/FilloutCalte.vue";
import FeelingSelectCourseStep
    from "@/components/domains/esthe/aiesthe/plans/feeling/steps/FeelingSelectCourseStep.vue";
import FeelingVibrationExperienceStep
    from "@/components/domains/esthe/aiesthe/plans/feeling/steps/FeelingVibrationExperienceStep.vue";
import AIEstheRandomPlanPage from "@/components/domains/esthe/aiesthe/plans/AIEstheRandomPlanPage.vue";
import VibrationExperienceStep
    from "@/components/domains/esthe/aiesthe/plans/detectface/steps/VibrationExperienceStep.vue";
import EasilyLoginAgreement from "@/components/domains/settings/easilylogin/EasilyLoginAgreement.vue";
import EasilyLoginIssueURL from "@/components/domains/settings/easilylogin/EasilyLoginIssueURL.vue";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import Authorization from "@/components/domains/authorization/Authorization.vue";
import ConfirmationStep from "@/components/domains/payment/shared/steps/ConfirmationStep.vue";
import CreditCardStep from "@/components/domains/payment/shared/steps/CreditCardStep.vue";
import UserSelectStep from "@/components/domains/payment/shared/subscription/picsteps/UserSelectStep.vue";
import FreeStressCheckResultDialog from "@/components/domains/stress_check/FreeStressCheckResultDialog.vue";
import GrantAuthoritySearchTable from "@/components/domains/authorization/GrantAuthoritySearchTable.vue";
import ManagementEstheCalendar from "@/components/domains/calender/ManagementEstheCalendarPage.vue";
import VisibleInvisibleFrame from "@/components/infrastructures/molecules/VisibleInvisibleFrame.vue";
import EstheRank from "@/components/domains/activity/EstheRank.vue";
import AccountTransfer from "@/components/domains/account/transfer/AccountTransfer.vue";
import SelectCustomerStep from "@/components/domains/account/transfer/customer/steps/SelectCustomerStep.vue";
import TransferAgencySelectionStep
    from "@/components/domains/account/transfer/common/steps/TransferAgencySelectionStep.vue";
import AccountTransferVerificationStep
    from "@/components/domains/account/transfer/common/steps/AccountTransferVerificationStep.vue";
import SoundEsthePlanSelectionPage from "@/components/domains/esthe/soundesthe/SoundEsthePlanSelectionPage.vue";
import ChangingPassword from "@/components/domains/settings/password/ChangingPassword.vue";
import ConfirmMailForm from "@/components/domains/account/register/general/steps/ConfirmMailForm.vue";
import PersonalRegisterForm from "@/components/domains/account/register/general/steps/PersonalRegisterForm.vue";
import AIEstheHistory from "@/components/domains/esthe/aiesthe/history/AIEstheHistory.vue";
import ForgotPasswordConfirmMailForm
    from "@/components/domains/account/forgot_password/steps/ForgotPasswordConfirmMailForm.vue";
import ForgotPasswordResetPasswordForm
    from "@/components/domains/account/forgot_password/steps/ForgotPasswordResetPasswordForm.vue";
import ResultStep from "@/components/domains/esthe/aiesthe/plans/detectface/steps/ResultStep.vue";
import ProvidingDataAgreementDialog from "@/components/domains/agreement/ProvidingDataAgreementDialog.vue";
import vuePdf from "vue-pdf";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import KawaiiCamera from '@/components/infrastructures/molecules/KawaiiCamera.vue';
import AfterCameraStep from "@/components/domains/esthe/aiesthe/plans/detectface/steps/AfterCameraStep.vue";
import ProvidingDataAgreement from "@/components/domains/agreement/ProvidingDataAgreement.vue";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";
import HomeFooter from "@/components/templates/home/HomeFooter.vue";
import AgreementsStep from "@/components/domains/agreement/AgreementsStep.vue";
import DeliveryAddressStep from "@/components/domains/payment/shared/steps/DeliveryAddressStep.vue";
import SoundTherapySelectCourseStep
    from "@/components/domains/esthe/aiesthe/plans/sound_therapy/steps/SoundTherapySelectCourseStep.vue";
import SoundTherapyExperienceStep
    from "@/components/domains/esthe/aiesthe/plans/sound_therapy/steps/SoundTherapyExperienceStep.vue";
import SoundTherapySelectStep
    from "@/components/domains/esthe/aiesthe/plans/sound_therapy/steps/SoundTherapySelectStep.vue";

export default class GlobalComponentRegister {

    public static register() {

        Vue.component('vue-pdf', vuePdf)
        Vue.component("Calte", Calte);
        Vue.component("KawaiiSentMessage", KawaiiSentMessage);
        Vue.component("StepContents", StepContents);
        Vue.component("KawaiiCamera", KawaiiCamera);
        Vue.component("SelectCourseStep", SelectCourseStep);
        Vue.component("ChooseAIEstheDetectFaceResultDialog", ChooseAIEstheDetectFaceResultDialog);
        Vue.component("CalteManagement", CalteManagement);
        Vue.component("DetectFaceResultCard", DetectFaceResultCard);
        Vue.component("FilloutCalte", FilloutCalte);
        Vue.component("FeelingSelectCourseStep", FeelingSelectCourseStep);
        Vue.component("FeelingVibrationExperienceStep", FeelingVibrationExperienceStep);
        Vue.component("AIEstheRandomPlan", AIEstheRandomPlanPage);
        Vue.component("VibrationExperienceStep", VibrationExperienceStep);
        Vue.component("EasilyLoginAgreement", EasilyLoginAgreement);
        Vue.component("EasilyLoginIssueURL", EasilyLoginIssueURL);
        Vue.component("VerticalSlotContents", VerticalSlotContents);
        Vue.component("Authorization", Authorization);
        Vue.component("ConfirmationStep", ConfirmationStep);
        Vue.component("CreditCardStep", CreditCardStep);
        Vue.component("UserSelectStep", UserSelectStep);
        Vue.component("FreeStressCheckResultDialog", FreeStressCheckResultDialog);
        Vue.component("GrantAuthoritySearchTable", GrantAuthoritySearchTable);
        Vue.component("ManagementEstheCalendar", ManagementEstheCalendar);
        Vue.component("VisibleInvisibleFrame", VisibleInvisibleFrame);
        Vue.component("EstheRank", EstheRank);
        Vue.component("AccountTransfer", AccountTransfer);
        Vue.component("SelectCustomerStep", SelectCustomerStep);
        Vue.component("TransferAgencySelectionStep", TransferAgencySelectionStep);
        Vue.component("AccountTransferVerificationStep", AccountTransferVerificationStep);
        Vue.component("SoundEsthePlanSelection", SoundEsthePlanSelectionPage);
        Vue.component("ChangingPassword", ChangingPassword);
        Vue.component("ConfirmMailForm", ConfirmMailForm);
        Vue.component("PersonalRegisterForm", PersonalRegisterForm);
        Vue.component("AIEstheHistory", AIEstheHistory);
        Vue.component("ForgotPasswordConfirmMailForm", ForgotPasswordConfirmMailForm);
        Vue.component("ForgotPasswordResetPasswordForm", ForgotPasswordResetPasswordForm);
        Vue.component("ResultStep", ResultStep);
        Vue.component("ProvidingDataAgreementDialog", ProvidingDataAgreementDialog);
        Vue.component("ProvidingDataAgreement", ProvidingDataAgreement);
        Vue.component("GeneralField", GeneralField);
        Vue.component("AfterCameraStep", AfterCameraStep);
        Vue.component("AgreementsStep", AgreementsStep);
        Vue.component("DescriptionText", DescriptionText);
        Vue.component("HomeFooter", HomeFooter);
        Vue.component("DeliveryAddressStep", DeliveryAddressStep);
        Vue.component("SoundTherapySelectCourseStep", SoundTherapySelectCourseStep);
        Vue.component("SoundTherapyExperienceStep", SoundTherapyExperienceStep);
        Vue.component("SoundTherapySelectStep", SoundTherapySelectStep);
    }
}
