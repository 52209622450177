






























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DefaultVue from "vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import HpSubTitle from "@/components/infrastructures/atoms/text/HpSubTitle.vue";

export abstract class Step extends Vue {

  private stepNumber = 1;
  protected complete = false;

  abstract getStepTitle(): string;

  abstract getComponentName(): string

  public getStepSubTitle() {
    return "";
  }

  mounted() {
    this.checkComplete();
  }

  public doComplete() {
    this.complete = true;
  }

  public doCancel() {
    this.complete = false;
  }

  public importStep(step: Step) {
    this.$emit("importStep", step);
  }

  public next() {
    this.complete = this.validate();
    // this.doComplete();
    this.$emit("next", this);
  }

  public back() {
    this.doCancel();
    this.$emit("back", this);
  }

  public getStepNumber(): number {
    return this.stepNumber;
  }

  public setStepNumber(stepNumber: number) {
    this.stepNumber = stepNumber;
  }

  public isCompleted(): boolean {
    return this.complete;
  }

  @Watch("complete")
  private watchCompleteAction() {
    this.$emit("watchComplete", this)
  }

  protected validate(): boolean {
    return true;
  }

  protected checkComplete(): void {
    if (this.validate()) {
      this.doComplete();
    } else {
      this.doCancel();
    }
  }
}

@Component({
  components: {HpSubTitle, ContentTitle}
})
export default class StepContents extends Vue {

  @Prop({default: []}) steps: Step[];
  componentNames = this.steps.map((step) => step.getComponentName());
  name = "StepContents";
  currentStep = 1;
  disableNext = true;
  updateKey = false

  constructor() {
    super();
    this.steps.forEach((step: Step, index) => {
      step.setStepNumber(index + 1);
    });
  }

  private watchStepComplete(step: Step) {
    this.disableNext = !step.isCompleted()
  }

  private goNextStepAction(step: Step) {
    const complete = step.isCompleted();
    if (complete && this.hasNextStep(step)) {
      const index = this.componentNames.indexOf(step.getComponentName());
      this.currentStep = this.steps[index + 1].getStepNumber();
    } else if (complete && !this.hasNextStep(step)) {
      this.currentStep = 1
      this.updateThisComponent()
    }
  }

  private goBackStepAction(step: Step) {
    const complete = step.isCompleted();
    if (!complete && this.hasBackStep(step)) {
      const index = this.componentNames.indexOf(step.getComponentName());
      this.currentStep = this.steps[index - 1].getStepNumber();
    } else if (!complete && !this.hasBackStep(step)) {
      this.currentStep = 1;
      this.updateThisComponent()
    }
  }

  private hasNextStep(step: Step): boolean {
    const nextIndex = this.componentNames.indexOf(step.getComponentName()) + 1;
    return this.steps.length > nextIndex;
  }

  private hasBackStep(step: Step): boolean {
    const nextIndex = this.componentNames.indexOf(step.getComponentName()) - 1;
    return nextIndex >= 0;
  }

  private updateThisComponent() {
    this.updateKey = !this.updateKey
  }
}

DefaultVue.component("StepContents", StepContents);

