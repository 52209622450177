import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class AdvertiseService {

    public static notice(key: string, type: string, request: string) {
        const data = {
            key: key,
            request: request,
            type: type
        }
        return httpResource.post('/v3/advertise/notice', data);
    }
}