








import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";

@Component
export default class AccountTransferVerificationStep extends Step {
  name = "AccountTransferVerificationStep";

  getStepTitle(): string {
    return "移管内容の確認";
  }

  back() {
    super.back();
  }

  getComponentName(): string {
    return this.name;
  }
}
