







import {Component} from "vue-property-decorator";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import {VBtn} from "vuetify/lib";

@Component({
  components: {PrimaryBtn}
})
export default class BackBtn extends VBtn {
  name = "BackBtn";
}
