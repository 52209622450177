







































import {Component, Vue} from "vue-property-decorator";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";

@Component({
  components: {DescriptionText, ContentTitle}
})
export default class Awards extends Vue {

  name = "Awards";

  awards = [
    {img: '/img/home/awards/202010_1.png', year: '2020', month: '10', title: '英国の学術雑誌 Innovation_platform に掲載'},
    {img: '/img/home/awards/201912_1.png', year: '2019', month: '12', title: '英国の学術雑誌 インパクトに掲載'},
    {img: '/img/home/awards/201809_1.png', year: '2018', month: '9', title: 'アグリバイオに掲載'},
    {img: '/img/home/awards/201806_1.png', year: '2018', month: '6', title: 'ICC 美術館に展示'},
    {img: '/img/home/awards/201711_1.png', year: '2017', month: '11', title: '京都大学着想コンテスト 総長賞受賞'},
    {img: '/img/home/awards/201412_1.png', year: '2014', month: '12', title: '情報を生み出す感覚の知性 情報社会をいきるための感覚のリテラシー'},

    {img: '/img/home/awards/201604_1.png', year: '2016', month: '4', title: 'Pervasive Haptics 基礎理論"感覚言語”について'},
    {img: '/img/home/awards/201403_1.png', year: '2014', month: '3', title: 'Tactile Score ドイツの出版社「シュプリンガー」'},
    {img: '/img/home/awards/201403_2.png', year: '2014', month: '3', title: '触覚認識メカニズムと応用技術（S＆T出版）'},
    {img: '/img/home/awards/201401_1.png', year: '2014', month: '1', title: 'BTV、スペイン国営放送'},
    {
      img: '/img/home/awards/201310_1.png', year: '2013', month: '10', title: 'ファセテラピーメソッド 春秋社/著者：鈴木理絵子'
    },
    {
      img: '/img/home/awards/201105_1.png', year: '2011', month: '5', title: '美しい美学をつくる みすず書房/著者：秋庭史典'
    },
  ];
}
