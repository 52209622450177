export interface EnterpriseState {
    oldestStoppedTime: Date;
}

export enum EnterpriseGetterTypes {
    getOldestStoppedTime = "EnterpriseGetOldestStoppedTime"
}

export enum EnterpriseMutationTypes {
    setOldestStoppedTime = "EnterpriseSetOldestStoppedTime"
}
