import {ActionTree} from "vuex";
import {RootState} from "@/store/type";
import {DmvActionTypes, DmvGetterTypes, DmvState} from "@/store/dmv/types";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";

const actions: ActionTree<DmvState, RootState> = {
    [DmvActionTypes.clearSelectedWaves]: async ({getters}) => {

        // NOP
    },
    [DmvActionTypes.playSelectedWaves]: async ({getters}) => {
        const waves = getters[DmvGetterTypes.getSelectedWaves];
        waves.forEach((w: IndexedAudio) => {
            if (w.start) w.start()
        });
    },
    [DmvActionTypes.pauseSelectedWaves]: async ({getters}) => {
        const waves = getters[DmvGetterTypes.getSelectedWaves];
        waves.forEach((w: IndexedAudio) => {
            if (w.pause) w.pause()
        });
    }
}

export default actions;
