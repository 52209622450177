

























import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import FieldData from "@/components/infrastructures/functions/fieldData";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import router from "@/router";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";

@Component({
  components: {GeneralField}
})
export default class ForgotPasswordResetPasswordForm extends Step {

  name = "ForgotPasswordResetPasswordForm";

  // error
  errorMessage = "";
  hasError = false;

  dialog = false;

  private pathField: FieldData = new FieldData()
      .withId("path")
      .withRequires(true)
      .withType("text_field")
      .withLabel("PATH");

  private passwordField: FieldData = new FieldData()
      .withId("password")
      .withRequires(true)
      .withType("password")
      .withLabel("パスワード (8文字以上)");

  private verifyPasswordField: FieldData = new FieldData()
      .withId("verify_password")
      .withRequires(true)
      .withType("password")
      .withLabel("確認用パスワード");

  private registerBtnField: FieldData = new FieldData()
      .withId("registerBtn")
      .withType("button")
      .withLabel('再設定')
      .withClickAction(this.register);

  private resetBtnField: FieldData = new FieldData()
      .withId("resetBtn")
      .withType("back_button")
      .withLabel('リセット')
      .withClickAction(this.reset);

  public getStepTitle(): string {
    return "パスワードリセット";
  }

  public getStepSubTitle(): string {
    return "";
  }

  public getComponentName(): string {
    return this.name;
  }

  beforeMount() {

    const path = this.$route.params.path;
    this.hasError = false;

    httpResource.post(`account/forgot-password/confirm-temp-url?path=${path}`)
        .then((response: any) => {
          const verify = response.data;
          if (!verify) {
            this.errorMessage = "無効な URL です。";
            this.hasError = true;
            return;
          }
          this.pathField.value = path;
        })
        .catch((error: any) => {
          this.errorMessage = error.response.data.message;
          this.hasError = true;
        })
  }

  @Watch('passwordField.value')
  private u1() {
    this.validatePassword();
  }

  @Watch('verifyPasswordField.value')
  private u2() {
    this.validatePassword();
  }

  validatePassword() {
    const password = this.passwordField.value.trim();
    const verifyPassword = this.verifyPasswordField.value.trim();
    this.passwordField.resetError();
    this.verifyPasswordField.resetError();
    if (password !== '' && verifyPassword !== '' && password !== verifyPassword) {
      this.passwordField.error = true;
      this.passwordField.errorMessage = 'パスワードが一致しません';
      this.verifyPasswordField.error = true;
      this.verifyPasswordField.errorMessage = 'パスワードが一致しません';
    }
  }

  register() {

    this.resetFormErrors();

    const data = {
      path: this.pathField.value,
      password: this.passwordField.value,
      verifyPassword: this.verifyPasswordField.value
    };

    httpResource.post('/account/forgot-password/reset', data)
        .then((response: any) => {
          alert("パスワードの変更に成功しました。");
          router.push("/mypage");
        })
        .catch((error: any) => {
          alert(error.response.data.message);
        });
  }

  reset() {
    this.resetFormErrors();
    this.pathField.withValue('');
    this.passwordField.withValue('');
    this.verifyPasswordField.withValue('');
  }

  resetFormErrors() {
    this.resetErrors();
    this.pathField.withError(false);
    this.pathField.withErrorMessage('');
    this.passwordField.withError(false);
    this.passwordField.withErrorMessage('');
  }

  resetErrors() {
    this.hasError = false;
  }
}
