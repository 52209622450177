









import {VBtn} from "vuetify/lib";
import {Component, Prop} from "vue-property-decorator";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn}
})
export default class SubBtn extends VBtn {
  name = "SubBtn";

  @Prop({default: true}) rounded!: boolean;
  @Prop({default: 'secondary'}) color!: string;
  @Prop({default: false}) disabled!: boolean;
}
