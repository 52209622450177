










































import {Component, Prop, Vue} from "vue-property-decorator";
import {StripeElementCard} from "@vue-stripe/vue-stripe";
import CreditCard from "@/components/infrastructures/atoms/CreditCard.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import KawaiiDaialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn, StripeElementCard, CreditCard, KawaiiDaialog}
})
export default class CreditCardRegister extends Vue {

  public name = "CreditCardRegister";
  @Prop({default: ''}) pulishableKey: string;
  title = ""
  message = ""
  resultDialog = false
  hasError = false

  // overlay
  overlay = false;

  load(isLoading: boolean) {
    this.$emit('loading', isLoading)
  }

  setDialog(title: string, message: string, hasError: boolean) {
    this.title = title;
    this.message = message;
    this.hasError = hasError;
  }

  createToken() {
    (this.$refs.elementRef as any).submit();
  }

  register(token: any) {

    this.overlay = true;

    const params = {
      'token': (token as any).id,
      'last4': (token as any).card.last4,
      'brand': (token as any).card.brand,
      'expMonth': (token as any).card.exp_month,
      'expYear': (token as any).card.exp_year
    }
    httpResource.post("credit-card/register/", params).then((response) => {
      this.setDialog("", "登録に成功しました", false);
      this.$emit('afterRegistered', {response: response, hasError: false})
    }).catch((error) => {
      this.setDialog("登録に失敗しました", error.response.data.message, true);
      this.$emit('afterRegistered', {response: error, hasError: true})
    }).finally(() => {
      this.overlay = false;
      this.resultDialog = true;
    })
  }

}
