






import {Component, Vue} from "vue-property-decorator";
import AuthMixin from "@/components/domains/authorization/authMixin";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import PaymentDetail from "@/components/domains/payment/detail/PaymentDetail.vue";

@Component({
  components: {PaymentDetail, MyPageContentTemplate}
})
export default class PaymentDetailPage extends AuthMixin {
  name = "PaymentDetailPage";
  id = this.$route.params.id;
}
