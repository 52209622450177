export class StressLevel {

    private static readonly _values: StressLevel[] = []

    static readonly LEVEL1 = new StressLevel("レベル１", 0);
    static readonly LEVEL2 = new StressLevel("レベル２", 1);
    static readonly LEVEL3 = new StressLevel("レベル３", 2);
    static readonly LEVEL4 = new StressLevel("レベル４", 3);

    private constructor(
        readonly display: string,
        readonly value: number) {
        StressLevel._values.push(this);
    }

    static fromValue(value: number): StressLevel | undefined {
        return StressLevel._values.find(x => x.value === value);
    }

    static values(): StressLevel[] {
        return StressLevel._values
    }
}