

































































import {Component, Vue} from "vue-property-decorator";
import {AuthActionTypes} from "@/store/auth/types";
import router from "@/router";
import WindowSize from "@/components/infrastructures/functions/window/windowSize";
import IconButton from "@/components/infrastructures/atoms/IconButton.vue";
import Header from "@/components/templates/default/DefaultHeader.vue";
import LangSelection from "@/components/infrastructures/molecules/LangSelection.vue";

@Component({
  components: {LangSelection, IconButton}
})
export default class LoggedInHeader extends Vue {
  name = "LoggedInHeader";
  version = process.env.VUE_APP_VERSION;

  public get getLinks() {
    return Header.links;
  }

  get getSmartPhoneSizeLinks() {
    return [
      // {'name': '商品一覧', 'link': '/payment'},
    ];
  }

  logout() {
    this.$store.dispatch(AuthActionTypes.logout).then(() => {
      router.push('/login')
    })
  }

  get isSmartphoneSize() {
    return WindowSize.isSmartphoneSize(this);
  }

  get getMyPageLinks() {
    return this.$store.getters.getMyPageLinks.filter((m: any) => m.link !== '');
  }
}

