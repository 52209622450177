












































import {Component, Vue, Watch} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import CalteFormat from "@/components/domains/calte/calteFormat";
import AIEstheDetectFaceResultCard from "@/components/domains/esthe/aiesthe/AIEstheDetectFaceResultCard.vue";
import CalteRecord from "@/components/domains/calte/calteRecord";
import {CalteFormatConverter} from "@/components/domains/calte/calteFormatConverter";

@Component({
  components: {AIEstheDetectFaceResultCard}
})
export default class FilloutCalte extends Vue {
  name = "FilloutCalte";
  options = {}
  items = []
  selectedItems = []
  caltes = []
  total = 0
  loading = false
  calteFormat = new CalteFormat();
  aiEstheResult = null;
  headers = [
    // {text: 'ID', value: 'id'},
    {text: 'カルテ名', value: 'name'},
    {text: '記入状況', value: 'filledOut'},
    {text: '日付', value: 'updated'}
  ]

  dialog = false;
  overlay = false;
  error = false;

  hiddenList = false;

  @Watch("options")
  getDataFromApi() {
    this.loading = true
    this.apiCall().then((data: any) => {
      this.items = data.items
      this.total = data.total
      this.loading = false
    }).catch(error => {
      // NOP
    })
  }

  mounted() {
    this.getDataFromApi()
  }

  async apiCall() {

    const {page, itemsPerPage} = this.options as any

    const size = itemsPerPage == -1 ? this.total : itemsPerPage;
    if (size > 99) {
      alert('100件以上の表示はできません。')
      return;
    }

    // ページ更新するたびにtotal取得するが、動的に担当者がカルテを追加した場合を想定している
    const totalResponse = await httpResource.get(`customer/calte/browsable-count/`);
    const caltesResponse = await httpResource.get(`customer/calte/browsable?page=${page - 1}&size=${size}`);

    const items = caltesResponse.data.map((v: any) => {
      return {
        id: v.id,
        name: /*v.updated + 'の' + */ v.title,
        filledOut: !v.customerInput ? '未' : '済',
        updated: v.updated
      }
    })
    const total = totalResponse.data
    return {items, total}
  }

  registerCalte() {

    if (this.calteFormat.hasEmptyInRequiredFields()) {
      alert('未入力項目があります。');
      return;
    }

    // calteformat → calterecord変換
    const item = this.selectedItems[0] as any;
    const calteRecord: CalteRecord = CalteFormatConverter.format2record(item.id, this.calteFormat);
    const aiResult = this.aiEstheResult as any;

    const request = {
      aiEstheDetectFaceResultId: aiResult === null ? null : aiResult.id,
      calteRecord: calteRecord
    };

    httpResource.post(`common/calte/register-record/`, request)
        .then((response: any) => {
          // NOP
        })
        .catch((error: any) => {
          this.error = true;
        });
    this.dialog = true;
    this.overlay = true;
  }

  @Watch("selectedItems")
  private watchSelected() {

    this.aiEstheResult = null;

    if (this.selectedItems.length === 0) {
      return;
    }

    const item = this.selectedItems[0] as any

    // カルテの画像取得
    if (item.id !== null && item.id.trim() !== '') {
      httpResource.get(`aiesthe/detect-result-from-calte-record-id?calteRecordId=${item.id}`)
          .then((response: any) => {
            this.aiEstheResult = response.data
          })
          .catch((error: any) => {
            // NOP
          });
    }

    // カルテ内容取得
    httpResource.get(`customer/calte/get-format?calteRecordId=${item.id}`)
        .then((response: any) => {
          const id = response.data.calteId;
          const title = response.data.title;
          const items = response.data.calteItems;
          const calteFormat = CalteFormat.createWithId(id, title, items);
          this.calteFormat = calteFormat;
        })
        .catch((error: any) => {
          this.calteFormat = new CalteFormat();
        });
  }

  decide(): void {
    this.dialog = false;
    this.overlay = false;
    this.error = false;
  }
}

