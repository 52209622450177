import {MutationTree} from "vuex";
import {AccountTransferMutationTypes, AccountTransferState} from "@/store/account/transfer/types";
import {User} from "@/components/domains/authorization/user";
import {Agency} from "@/components/domains/authorization/agency";

const mutations: MutationTree<AccountTransferState> = {
    [AccountTransferMutationTypes.setUser]: (state, user: User) => {
        state.user = user;
    },
    [AccountTransferMutationTypes.setTargetAgency]: (state, targetAgency: Agency) => {
        state.targetAgency = targetAgency;
    },
    [AccountTransferMutationTypes.setTargetRoles]: (state, targetRoles: string[]) => {
        state.targetRoles = targetRoles;
    }
}

export default mutations;
