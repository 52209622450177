import {QuestionnaireSelection} from "@/components/infrastructures/functions/questionnaire/questionnaireSelection";

export default class QuestionData {

    private question = '';
    private selections: QuestionnaireSelection[] = [
        QuestionnaireSelection.APPLICABLE,
        QuestionnaireSelection.SOMETIMES,
        QuestionnaireSelection.NOT_APPLICABLE
    ];

    public selection: QuestionnaireSelection = QuestionnaireSelection.UNANSWERED;

    constructor(question: string) {
        this.question = question;
    }

    public set setSelection(selection: QuestionnaireSelection) {
        this.selection = selection;
    }

    public get getSelection(): QuestionnaireSelection {
        return this.selection;
    }

    public get getQuestion(): string {
        return this.question;
    }

    public get getSelections(): QuestionnaireSelection[] {
        return this.selections;
    }
}