







import {Component, Vue} from "vue-property-decorator";
import StepContents, {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import SelectKindStep from "@/components/domains/esthe/aiesthe/plans/feeling/steps/FeelingSelectCourseStep.vue";
import FeelingVibrationExperienceStep
  from "@/components/domains/esthe/aiesthe/plans/feeling/steps/FeelingVibrationExperienceStep.vue";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";

@Component({
  components: {MyPageContentTemplate, StepContents}
})
export default class AIEstheFeelingPlanPage extends Vue {

  name = "AIEstheFeelingPlanPage";

  steps: Step[] = [
    new SelectKindStep(),
    new FeelingVibrationExperienceStep()
  ];
}

