

































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class CreditCardInfo extends Vue {

  public name = "CreditCardInfo";
  // last4, brand, expMonth, expYear
  @Prop({default: {}}) creditCard: {};
  last4 = ""
  brand = ""
  expMonth = 0;
  expYear = 0;

}
