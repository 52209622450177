
























import {Component} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import DeliveryAddressForm from "@/components/domains/account/profile/address/DeliveryAddressForm.vue";
import DeliveryAddressDisplayCard from "@/components/domains/account/profile/address/DeliveryAddressDisplayCard.vue";
import AddressInformation from "@/components/domains/account/profile/AddressInformation.vue";
import DeliveryAddressExtForm from "@/components/domains/account/profile/address/DeliveryAddressExtForm.vue";
import DeliveryAddress from "@/components/domains/account/profile/address/deliveryAddress";

@Component({
  components: {
    DeliveryAddressExtForm,
    AddressInformation, DeliveryAddressDisplayCard, DeliveryAddressForm, BackBtn, PrimaryBtn
  }
})
export default class DeliveryAddressStep extends Step {

  name = "DeliveryAddressStep"
  selectedDeliveryAddress: DeliveryAddress = new DeliveryAddress();

  getStepTitle(): string {
    return "配送先住所の入力";
  }

  getComponentName(): string {
    return this.name;
  }

  isCompleted(): boolean {
    return (this.selectedDeliveryAddress.deliveryId.length > 0);
  }

  selected(select: DeliveryAddress) {
    // NOP
  }

  next() {
    super.next();
  }
}
