















































import {Component, Vue} from "vue-property-decorator";
import FieldData from "@/components/infrastructures/functions/fieldData";
import authService from "@/components/domains/authorization/authService";
import {User} from "@/components/domains/authorization/user";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import SubBtn from "@/components/infrastructures/atoms/designed/SubBtn.vue";
import GeneralField from "@/components/infrastructures/atoms/GeneralField.vue";
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import HpSubTitle from "@/components/infrastructures/atoms/text/HpSubTitle.vue";
import HpText from "@/components/infrastructures/atoms/text/HpText.vue";
import HpTitle from "@/components/infrastructures/atoms/text/HpTitle.vue";

@Component({
  components: {HpTitle, HpText, HpSubTitle, GeneralField, KawaiiDialog, SubBtn, PrimaryBtn}
})
export default class LoginForm extends Vue {

  name = "LoginForm";

  // login
  mailAddress = new FieldData()
      .withId("mailAddress")
      .withType("text_field")
      .withLabel(this.$t('メールアドレス').toString());
  password = new FieldData()
      .withId("password")
      .withType("password")
      .withLabel(this.$t('パスワード').toString());

  // dialog
  showDialog = false;
  dialogMessage = '';

  login() {
    authService.login(this.mailAddress.value, this.password.value)
        .then((user: User) => {
          this.$router.push("/mypage")
        })
        .catch(() => {
          this.showDialog = true;
          this.dialogMessage = 'ログインに失敗しました。'
        });
  }
}
