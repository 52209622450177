










































































































































import {Component, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import MonthlyActivityChart from "@/components/domains/activity/MonthlyActivityChart.vue";
import MonthlyActivityRank from "@/components/domains/activity/MonthlyActivityRank.vue";

@Component({
  components: {MonthlyActivityRank, MonthlyActivityChart, MyPageContentTemplate}
})
export default class ActivityPage extends Vue {
  name = "ActivityPage";
  checking = false;
  values = [];
  labels = [];
  advice = {};

  mounted() {

    httpResource.get("/activity/weekly")
        .then((response: any) => {
          this.labels = response.data.map((a: any) => a.label);
          this.values = response.data.map((a: any) => a.score);
        });

    httpResource.get("/activity/advice")
        .then((response: any) => {
          this.advice = response.data;
        });
  }

  get rankText() {
    if (this.score > 40) {
      return 'Diamond';
    }
    if (this.score > 35) {
      return 'Platinum';
    }
    if (this.score > 24) {
      return 'Gold';
    }
    if (this.score > 18) {
      return 'Silver';
    }
    return 'Bronze';
  }

  get rankImg() {
    if (this.score > 40) {
      return '/img/useability/diamond.svg';
    }
    if (this.score > 35) {
      return '/img/useability/platinum.svg';
    }
    if (this.score > 24) {
      return '/img/useability/gold.svg';
    }
    if (this.score > 18) {
      return '/img/useability/silver.svg';
    }
    return '/img/useability/bronze.svg';
  }

  get score() {
    return this.values.reduce(function (sum, element) {
      return sum + element;
    }, 0);
  }
}
