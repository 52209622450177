export class CalendarType {

    private static readonly _values: CalendarType[] = []

    static readonly AI_ESTHE = new CalendarType("顔診断結果");
    static readonly CALTE_RECORD = new CalendarType("カルテ記録");
    static readonly DMV_EXP = new CalendarType("DMV 体験");
    static readonly SOUND_EXP = new CalendarType("メディカルサウンド体験");

    private constructor(
        readonly value: string) {
        CalendarType._values.push(this);
    }

    public is(calendar: { name: string }): boolean {
        return CalendarType.parse(calendar) === this;
    }

    static parse(calendar: { name: string }): CalendarType | undefined {
        return CalendarType._values.find(x => calendar.name.startsWith(x.value));
    }

    static fromValue(value: string): CalendarType | undefined {
        return CalendarType._values.find(x => x.value === value);
    }

    static values(): CalendarType[] {
        return CalendarType._values
    }
}
