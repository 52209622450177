






























































import {Component, Watch} from "vue-property-decorator";
import {Step} from "@/components/infrastructures/molecules/StepContents.vue";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";
import DVMPlayListPlayer from "@/components/domains/esthe/DVMPlayListPlayer.vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import EstheService from "@/components/domains/esthe/EstheService";
import AIEstheService from "@/components/domains/esthe/aiesthe/aiEstheService";
import {AIEstheGetterTypes} from "@/store/aiesthe/types";
import SoundTherapyFile from "@/components/domains/esthe/aiesthe/plans/sound_therapy/soundTherapyFile";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";

@Component({
  components: {
    SmartDMVPlayer,
    DVMPlayListPlayer,
    ProductLineupDialog, PrimaryBtn, BackBtn, 'vue-timepicker': VueTimepicker
  }
})
export default class SoundTherapyExperienceStep extends Step {
  name = "SoundTherapyExperienceStep";

  debug = process.env.VUE_APP_DEVELOPMENT_MODE === 'true';

  playing = false;

  // config
  loop = false;

  // cluster 9
  currentAudio!: IndexedAudio;
  currentDateTime!: StopWatchDateTime;
  currentSoundSource = new SoundTherapyFile();
  soundSources: SoundTherapyFile[] = [];

  // products dialog
  private productsDialog = false;
  private products = [];
  private funcName = '';

  // dialog
  dialog = false;

  getComponentName(): string {
    return this.name;
  }

  getStepTitle(): string {
    return "再生";
  }

  private setAudioAction(audio: IndexedAudio) {
    this.currentAudio = audio;
    this.currentSoundSource = this.soundSources[audio.index]
  }

  private updatedAction(audio: IndexedAudio, dateTime: StopWatchDateTime) {
    this.currentAudio = audio;
    this.currentDateTime = dateTime;
  }

  private finishedAction(audio: IndexedAudio, dateTime: StopWatchDateTime) {

    const request = {
      dmvTypeCode: "04",
      planCode: Plan.SOUND_THERAPY.code,
      vibrationId: audio.id,
      startDateTime: dateTime.formattedStartDateTime,
      endDateTime: dateTime.formattedEndDateTime,
      totalPlayingTime: dateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-sound-therapy-playing", request);
  }

  beforeMount() {

    // 音楽療法権限確認
    EstheService.checkAvailableSoundTherapy()
        .then(res => {
          if (res.data === 'E0000') {

            if (this.getClusterId == 9) {
              AIEstheService.fetchSoundTherapyDmvList(this.getClusterId, this.getBodyPart)
                  .then((response) => {
                    this.soundSources = response.data;
                  });
            } else {
              AIEstheService.fetchSoundTherapyDmv(this.getSoundId).then((response) => {
                this.soundSources = [];
                this.soundSources.push(response.data);
              })
            }
          } else {
            this.funcName = '音楽療法';
            this.products = res.data.products;
            this.productsDialog = true;
          }
        });
  }

  @Watch('soundSources')
  private updateSoundSource() {
    this.currentSoundSource = this.soundSources[this.currentAudio.index];
  }

  finish() {
    this.record().finally(() => {
      this.$router.push("/mypage/aiesthe/");
    });
  }

  async record() {
    const request = {
      dmvTypeCode: "04",
      planCode: Plan.SOUND_THERAPY.code,
      vibrationId: this.currentAudio.id,
      startDateTime: this.currentDateTime.formattedStartDateTime,
      endDateTime: this.currentDateTime.formattedEndDateTime,
      totalPlayingTime: this.currentDateTime.formattedTotalPlayingTime
    };
    return httpResource.post("/dmv/record-sound-therapy-playing", request);
  }

  get getClusterId() {
    return this.$store.getters[AIEstheGetterTypes.getCourse];
  }

  get getBodyPart() {
    return this.$store.getters[AIEstheGetterTypes.getBodyPart];
  }

  get getSoundId() {
    return this.$store.getters[AIEstheGetterTypes.getSoundId];
  }
}
