













import {Component, Prop, Vue} from "vue-property-decorator";
import DefaultVue from "vue";

@Component
export default class VerticalSlotContents extends Vue {
  name = "VerticalSlotContents"
  @Prop({default: 1}) slots: number;
  @Prop({default: "center"}) justify: string;
  @Prop({default: 7}) pt: number;

  createClass(num: number) {
    return this.createPaddingTop(num);
  }

  createPaddingTop(num: number) {
    if (num == 1) {
      return 'pt-0';
    }
    return 'pt-' + this.pt;
  }
}

DefaultVue.component(VerticalSlotContents.name, VerticalSlotContents);
