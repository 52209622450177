




















import {Component, Prop, Vue} from "vue-property-decorator";
import DescriptionText from "@/components/infrastructures/atoms/designed/DescriptionText.vue";

@Component({
  components: {DescriptionText}
})
export default class OverviewCard extends Vue {
  name = "OverviewCard";

  @Prop({default: '/img/home/overview/soundesthe/primary_back.png'}) backgroundImg!: string;
  @Prop({default: ''}) img!: string;
  @Prop({default: 'accent'}) color: string;
  @Prop({default: ''}) href: string;
}
