




import {Component, Vue} from "vue-property-decorator";
import AgreementService from "@/components/domains/agreement/agreementService";
import MarkdownViewer from "@/components/infrastructures/atoms/MarkdownViewer.vue";

@Component({
  components: {MarkdownViewer}
})
export default class TermsOfServiceText extends Vue {

  name = "TermsOfServiceText";
  markdown = '';

  mounted() {
    AgreementService.termsOfServiceMarkdown()
        .then(obj => {
          this.markdown = obj.data;
        });
  }
}
