


































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import KawaiiSoundPlayer from "@/components/infrastructures/molecules/KawaiiSoundPlayer.vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import StopWatch from "@/components/infrastructures/functions/time/stopWatch";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import KawaiiDialog from "@/components/infrastructures/atoms/KawaiiDialog.vue";
import EstheService from "@/components/domains/esthe/EstheService";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";

@Component({
  components: {
    ProductLineupDialog,
    KawaiiDialog,
    KawaiiSoundPlayer,
    'vue-timepicker': VueTimepicker,
  }
})
export default class DMVPlayer extends Vue {

  name = "DMVPlayer";

  @Prop({default: "0"}) id!: string;
  @Prop() soundSrc!: string;
  @Prop({default: true}) normal: boolean;
  @Prop({default: false}) showControlPanelUpper: boolean;
  @Prop({default: false}) showControlPanel: boolean;
  @Prop({default: false}) autoPlay: boolean;
  @Prop({default: 0}) defaultSeek: number;

  // products dialog
  private productsDialog = false;
  private products = [];
  private funcName = '';

  // Extends Function
  private innerLoop = false;
  private loop = false;
  private loopDisabled = false;
  private timer = false;
  private timerDisabled = false;
  private timerTimeoutId = 0;
  private pickedTime = {
    HH: '00',
    mm: '15',
  };

  // dialog
  dialog = false;

  private currentTime = 0;

  beforeMount() {
    this.currentTime = this.defaultSeek;
  }

  get getPickedTime() {
    return {
      HH: parseInt(this.pickedTime.HH),
      mm: parseInt(this.pickedTime.mm)
    };
  }

  get getPickedTimeMillis() {
    const hh = this.getPickedTime.HH >= 1 ? this.getPickedTime.HH * 3600 * 1000 : 0;
    const mm = this.getPickedTime.mm >= 1 ? this.getPickedTime.mm * 60 * 1000 : 0;
    return hh + mm;
  }

  @Watch("loop")
  toggleLoop() {
    if (this.loop) {

      this.products = [];

      EstheService.checkAvailableLoop()
          .then(res => {
            if (res.data === 'E0000') {
              this.innerLoop = true;
            } else {
              this.funcName = 'リピート機能';
              this.products = res.data.products;
              this.productsDialog = true;
              this.loop = false;
              this.innerLoop = false;
            }
          });
    }
  }

  @Watch("timer")
  toggleTimer() {

    if (this.timer) {

      this.products = [];

      EstheService.checkAvailableTimer()
          .then(res => {
            if (res.data === 'E0000') {
              this.innerLoop = true;
              this.loop = true;
              this.loopDisabled = true;
            } else {
              this.funcName = 'タイマー機能';
              this.products = res.data.products;
              this.timer = false;
              this.productsDialog = true;
            }
          });

    } else {
      this.loopDisabled = false;
    }
  }

  @Prop({
    default: () => function (id: string, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnStarted!: Function;

  @Prop({
    default: () => function (id: string, currentTime: number) { /* NOP */
    }
  }) receiveDateTimeOnUpdated!: Function;

  @Prop({
    default: () => function (id: string, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnStopped!: Function;

  @Prop({
    default: () => function (playing: boolean) { /* NOP */
    }
  }) startStopListener!: Function;

  @Prop({
    default: () => function (id: string, dateTime: StopWatchDateTime, audio: KawaiiSoundPlayer) { /* NOP */
    }
  }) receiveDateTimeOnFinished!: Function;

  private stopWatch!: StopWatch;

  created() {
    this.stopWatch = new StopWatch();
    this.stopWatch.update();
  }

  playAction(audio: KawaiiSoundPlayer) {
    this.stopWatch.start();
    this.stopWatch.update();
    this.receiveDateTimeOnStarted(this.id, this.stopWatch.getDateTime);
    if (this.timer) {
      // 誤差調整用に 1 秒付加
      window.clearTimeout(this.timerTimeoutId)
      this.timerTimeoutId = window.setTimeout(audio.pauseAudio, this.getPickedTimeMillis + 1000);
    }
  }

  pauseAction(audio: KawaiiSoundPlayer) {
    this.stopWatch.stop();
    this.stopWatch.update();
    this.receiveDateTimeOnStopped(this.id, this.stopWatch.getDateTime);
  }

  timeUpdatedAction(id: string, currentTime: number) {
    this.stopWatch.update();
    this.currentTime = currentTime;
    this.receiveDateTimeOnUpdated(id, this.stopWatch.getDateTime);
  }

  finishedAction(audio: KawaiiSoundPlayer) {
    audio.soundSrc = this.soundSrc;
    this.stopWatch.stop();
    this.stopWatch.update();
    this.receiveDateTimeOnStopped(this.id, this.stopWatch.getDateTime);
    this.receiveDateTimeOnFinished(this.id, this.stopWatch.getDateTime, audio);
    // if (this.innerLoop) {
    //   audio.playAudio();
    // }
  }

  get isNotWorkingTimer() {
    return (this.getPickedTimeMillis / 1000) - this.currentTime < 0;
  }
}
