import {
    VideoCanvasSyncValues, drawVideoContainedInCanvas, recalculateVideoContainedInCanvasValues, watchElementResize, containIn, Size
} from './canvasVideoSync'

function createClearVideoResizeWatcher(video: HTMLElement, callback: () => void) {
    return () => {
        video.removeEventListener('loadedmetadata', callback)
        video.removeEventListener('resize', callback)
        window.removeEventListener('resize', callback)
        window.removeEventListener('orientationchange', callback)
    }
}

function watchVideoResize(video: HTMLVideoElement, callback: () => void) {
    video.addEventListener('loadedmetadata', callback)
    video.addEventListener('resize', callback)
    window.addEventListener('resize', callback)
    window.addEventListener('orientationchange', callback)

    return createClearVideoResizeWatcher(video, callback)
}

export default class Canvas {

    constructor(
        private canvas: HTMLCanvasElement,
        private width: number,
        private height: number,
        private showGrid = false) {
        canvas.setAttribute('width', width.toString());
        canvas.setAttribute('height', height.toString());
    }

    public get getWidth(): number {
        return this.width;
    }

    public get getHeight(): number {
        return this.height;
    }

    public set setWidth(width: number) {
        this.canvas.width = width
        this.width = width
    }

    public set setHeight(height: number) {
        this.canvas.height = height
        this.height = height
    }

    public getCanvas2D(): CanvasRenderingContext2D {
        return this.canvas!.getContext('2d')!;
    }

    public drawImageWithSize(image: any, imageWidth: number, imageHeight: number): void {

        // const offset = (imageWidth - imageHeight) / 2;

        this.getCanvas2D().drawImage(image, 0, 0, imageWidth, imageHeight);
        // this.getCanvas2D().drawImage(image, 0, 0, imageWidth, imageHeight,
        //     0, 0, imageWidth, imageHeight);
    }

    public drawImage(image: any): void {
        this.getCanvas2D().drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
    }

    public drawImageCenter(image: any): void {
        const centerX = this.width / 2 - image.width / 2;
        const centerY = this.height / 2 - image.height / 2;
        this.getCanvas2D().drawImage(image, centerX, centerY, image.width, image.height);
    }

    public clearRect(): void {
        const canvas = this.canvas!.getContext('2d')!;
        canvas.fillStyle = '#000';
        canvas.fillRect(0, 0, this.width, this.height);
    }

    public toDataURL(): string {
        return this.canvas.toDataURL();
    }

    public duplicateCanvas(): HTMLCanvasElement {
        const canvas = document.createElement('canvas')
        canvas.width = this.canvas.width
        canvas.height = this.canvas.height
        const ctx = canvas.getContext('2d')
        if (!ctx) throw new Error('Invalid context')
        ctx.drawImage(this.canvas, 0, 0)
        return canvas
    }

    public syncSizeToParentElement() {
        const element = this.canvas
        const { parentElement } = element
        if (!parentElement) {
            console.warn('No parent element', element)
            return
        }
        parentElement.style.width = element.style.width
        parentElement.style.height = element.style.height
    }

    public fitInParentElement() {
        const element = this.canvas
        const { parentElement } = element
        if (!parentElement) {
            console.warn('No parent element', element)
            return
        }
        const { width, height } = containIn({ width: this.width, height: this.height }, parentElement.getBoundingClientRect())
        element.style.width = `${width}px`
        element.style.height = `${height}px`

        /*
        const elementRect = element.getBoundingClientRect()
        const { width, height } = containIn(elementRect, parentElement.getBoundingClientRect())
        element.style.width = `${width}px`
        element.style.height = `${height}px`
        */
       /*
        if (elementRect.width === width) {
            element.style.width = '100%'
        } else {
            element.style.height = '100%'
        }
        */
    }

    /**
    * VideoとCanvasの内部サイズ同期
    * 対策1
    */
    public syncVideoAndCanvasSize (video: HTMLVideoElement) {
        const v = video
        /*
        // iOSのresizeバグ対応（resizeはスクロールなどの場合も発生）
        // resize処理はwindow以外の場合もあるので、ここをコメントアウトした。
        // 利用する場合、windowに限定して、getWindowSizeをそれに適用すること。
        const getWindowSize = () => ({
            width: window.innerWidth,
            height: window.innerHeight
        })
        let windowSizeState = getWindowSize()
        */
        const resize = () => {
            /*
            const nSize = getWindowSize()
            if (nSize.width === windowSizeState.width && nSize.height === windowSizeState.height) {
                console.warn('resize event occurred but not resize actually happened')
                return
            }
            windowSizeState = nSize
            */
            if (!v.videoWidth || !v.videoHeight) {
                console.warn('Ignored video<>canvas size sync: videoWidth OR videoHeight not availabled.')
                return
            }
            console.debug('video resize', { width: v.videoWidth, height: v.videoHeight })
            // 内部
            const storedCanvas = this.duplicateCanvas()
            this.setWidth = v.videoWidth
            this.setHeight = v.videoHeight
            this.drawImageWithSize(storedCanvas, this.canvas.width, this.canvas.height)
            // Style
            // this.fitInParentElement()
            this.syncSizeToParentElement()

            if (!v.paused) {
                this.clearRect()
                this.drawImage(v)
            }
        }
        const clear = watchVideoResize(v, resize)
        return {
            clear, resize
        }
    }

    /**
     * 描画の際にサイズ同期
     * 対策2
     */
    public containVideoInCanvas(video: HTMLVideoElement) {
        const canvas = this.canvas
        const ctx = canvas.getContext('2d')
        if (!ctx) {
            throw new Error('No context')
        }
        const videoCanvasSyncValues = VideoCanvasSyncValues()
        const draw = () => drawVideoContainedInCanvas(video, ctx, videoCanvasSyncValues)
        
        const recalculate = () => {
            recalculateVideoContainedInCanvasValues(video, canvas, videoCanvasSyncValues)
            if (isNaN(videoCanvasSyncValues.canvasDisplaySize.width) || isNaN(videoCanvasSyncValues.canvasDisplaySize.height)) {
                // 導入によってあり得る
                console.debug('will reattempt recalculate')
                window.setTimeout(() => recalculate(), 100)
            }
            if (!video.paused) {
                this.clearRect() // canvasのサイズ変更があるため
                draw()
            }
            console.debug('recalculate(d)')
        }
        // const resizeObserverCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[], observer: ResizeObserver): void => {
        const resizeObserverCallback: ResizeObserverCallback = (): void => {
            recalculate()
            // 環境により、entriesで正確な情報から計算ができる。必要に応じて導入：
            // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry
        }

        recalculate()

        // Video
        // watchElementResize(video, resizeObserverCallback)
        const clearVideoResize = watchVideoResize(video, recalculate)

        // Canvas
        const resizeObserver = watchElementResize(canvas, resizeObserverCallback)

        // Clear
        const clear = () => {
            clearVideoResize()
            resizeObserver.unobserve(canvas)
        }
        
        return {
            draw, clear
        }
    }

    // グリッドを描画する関数
    public drawGrid(gridSize: number): void {
        const context = this.getCanvas2D();
        const width = this.getWidth;
        const height = this.getHeight;

        context.beginPath();
        context.strokeStyle = '#EEE'; // グリッドの色を指定
        context.lineWidth = 1.3; // グリッドの線の太さを指定

        // 縦のグリッド線を描画
        for (let x = 0; x <= width; x += gridSize) {
            context.moveTo(x, 0);
            context.lineTo(x, height);
        }

        // 横のグリッド線を描画
        for (let y = 0; y <= height; y += gridSize) {
            context.moveTo(0, y);
            context.lineTo(width, y);
        }

        context.stroke();
    }
    // グリッドの表示・非表示を切り替えるメソッド
    public toggleGrid(gridSize: number): void {
        this.showGrid = !this.showGrid; // フラグを反転
        if (this.showGrid) {
            this.drawGrid(gridSize); // グリッドを描画
        } else {
            this.clear(); // グリッドを非表示にするためにキャンバスをクリア
            // 必要に応じて他の内容を再描画
            // 例: this.drawImage(...) など
        }
    }

    // キャンバスをクリアするメソッド（既存のclearRectを利用）
    public clear(): void {
        this.clearRect();
    }
}
