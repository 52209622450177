







































import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import EstheService from "@/components/domains/esthe/EstheService";
import WaveFile from "@/components/domains/esthe/dmv/waveFile";

@Component({
  components: {DMVPlayer}
})
export default class DMVPlayListPlayer extends Vue {
  name = "DMVPlayListPlayer";

  @Prop() soundItems!: WaveFile[];
  @Prop({default: "0"}) id!: string;
  @Prop({default: true}) normal: boolean;
  @Prop({default: false}) showControlPanelUpper: boolean;
  @Prop({default: false}) showControlPanel: boolean;

  // component func
  private currentItemIndex = 0;
  private loop = false;
  private autoPlay = false;

  // products dialog
  private productsDialog = false;
  private products = [];
  private funcName = '';

  @Prop({
    default: () => function (id: string, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnStarted!: Function;

  @Prop({
    default: () => function (id: string, currentTime: number) { /* NOP */
    }
  }) receiveDateTimeOnUpdated!: Function;

  @Prop({
    default: () => function (id: string, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnStopped!: Function;

  @Prop({
    default: () => function (playing: boolean) { /* NOP */
    }
  }) startStopListener!: Function;

  @Prop({
    default: () => function (id: string, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnFinished!: Function;

  @Watch("loop")
  private toggleLoop() {
    if (this.loop) {

      this.products = [];

      EstheService.checkAvailableLoop()
          .then(res => {
            if (res.data === 'E0000') {
              // NOP
            } else {
              this.funcName = '全体リピート機能';
              this.products = res.data.products;
              this.productsDialog = true;
              this.loop = false;
            }
          });
    }
  }

  private stoppedAction(id: string, dateTime: StopWatchDateTime) {
    this.receiveDateTimeOnStopped(id, dateTime);
  }

  private finishedAction(id: string, dateTime: StopWatchDateTime) {
    this.receiveDateTimeOnFinished(id, dateTime);
    if (this.loop) {
      this.autoPlay = true;
      this.setNextSoundIndex();
    }
  }

  private setPrevSoundIndex() {
    if (this.currentItemIndex - 1 >= 0) {
      this.currentItemIndex -= 1;
    } else {
      this.currentItemIndex = this.soundItems.length - 1;
    }
  }

  private setNextSoundIndex() {
    if (this.currentItemIndex + 1 < this.soundItems.length) {
      this.currentItemIndex += 1;
    } else {
      this.currentItemIndex = 0;
    }
  }

  get getCurrentSoundSrc() {
    return this.soundItems[this.currentItemIndex];
  }
}
