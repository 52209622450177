













import {Component, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";

@Component
export default class MonthlyActivityRank extends Vue {
  name = "MonthlyActivityRank";

  loaded = false;
  month = 0;
  rank = 0;

  mounted() {

    httpResource.get("/v2/activity/rank")
        .then((response: any) => {
          this.month = response.data.month;
          this.rank = response.data.percent;
          this.loaded = true;
        });
  }

}
