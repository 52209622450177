























































import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import EstheService from "@/components/domains/esthe/EstheService";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import StopWatch from "@/components/infrastructures/functions/time/stopWatch";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";
import SmartSoundPlayer from "@/components/infrastructures/atoms/SmartSoundPlayer.vue";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";

@Component({
  components: {SmartSoundPlayer, ProductLineupDialog, 'vue-timepicker': VueTimepicker,}
})
export default class SmartDMVPlayer extends Vue {
  name = "SmartDMVPlayer";

  @Prop() sounds: { id: string; src: string }[];
  @Prop({default: false}) selectMode: boolean;
  @Prop({default: false}) showControlPanelUpper: boolean;
  @Prop({default: true}) showControlPanel: boolean;
  @Prop({default: true}) showAllLoop: boolean;

  @Prop({
    default: () => function (audio: IndexedAudio, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnStarted!: Function;

  @Prop({
    default: () => function (audio: IndexedAudio, currentTime: number) { /* NOP */
    }
  }) receiveDateTimeOnUpdated!: Function;

  @Prop({
    default: () => function (audio: IndexedAudio, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnStopped!: Function;

  @Prop({
    default: () => function (playing: boolean) { /* NOP */
    }
  }) startStopListener!: Function;

  @Prop({
    default: () => function (audio: IndexedAudio, dateTime: StopWatchDateTime) { /* NOP */
    }
  }) receiveDateTimeOnFinished!: Function;


  @Prop({
    default: () => function (audio: IndexedAudio) { /* NOP */
    }
  }) receiveSetAudio!: Function;

  // Loading
  private loaded = false;

  // time
  private currentTime = 0;
  private currentAudio = new IndexedAudio();

  // products dialog
  private productsDialog = false;
  private products = [];
  private funcName = '';

  // Extends Function
  private innerLoop = false;
  private loop = false;
  private loopDisabled = false;
  private timer = false;
  private timerDisabled = false;
  private timerTimeoutId = 0;
  private pickedTime = {
    HH: '00',
    mm: '15',
  };

  private playing = false;
  private stopWatch!: StopWatch;

  // dialog
  dialog = false;

  // 経過時間用タイマー
  startedTimer = false;
  nowtime = Date.now();
  endtime = Date.now();
  stoptime = 0;

  beforeMount() {
    this.currentTime = 0;
    window.setInterval(() => this.stoptime = Math.trunc((this.endtime) - (Date.now())), (this.endtime) - (this.nowtime));
  }

  mounted() {
    this.loaded = true;
  }

  get getTimepicker(): HTMLElement {
    return document.getElementsByClassName('display-time').item(0) as HTMLElement;
  }

  @Watch("loop")
  toggleLoop() {
    if (this.loop) {

      this.products = [];

      EstheService.checkAvailableLoop()
          .then(res => {
            if (res.data === 'E0000') {
              this.innerLoop = true;
            } else {
              this.funcName = 'リピート機能';
              this.products = res.data.products;
              this.productsDialog = true;
              this.loop = false;
              this.innerLoop = false;
            }
          });
    } else {
      this.innerLoop = false;
    }
  }

  @Watch("timer")
  toggleTimer() {

    if (this.timer) {

      this.products = [];

      EstheService.checkAvailableTimer()
          .then(res => {
            if (res.data === 'E0000') {
              this.loop = true;
              this.loopDisabled = true;
              this.getTimepicker.focus();
            } else {
              this.funcName = 'タイマー機能';
              this.products = res.data.products;
              this.timer = false;
              this.productsDialog = true;
            }
          })
          .finally(() => {
            this.startedTimer = false;
          });

    } else {
      // NOP
      this.loopDisabled = false;
    }
  }

  created() {
    this.stopWatch = new StopWatch();
    this.stopWatch.update();
  }

  playAction(audio: IndexedAudio) {
    this.currentAudio = audio;
    this.playing = true;
    this.stopWatch.start();
    this.stopWatch.update();
    this.receiveDateTimeOnStarted(audio.id, this.stopWatch.getDateTime);
    if (this.timer && !this.startedTimer) {
      this.startedTimer = true;
      // 誤差調整用に 1 秒付加
      this.resetTimer();
      this.timerTimeoutId = window.setTimeout(() => {
        this.pause();
        this.resetTimer();
        this.startedTimer = false;
      }, this.getPickedTimeMillis + 1000);
    }
    this.nowtime = Date.now();
  }

  resetTimer() {
    this.stopWatch.resetStopWatch();
    window.clearTimeout(this.timerTimeoutId);
    this.nowtime = Date.now();
    // 誤差調整用に 1 秒付加
    this.endtime = this.nowtime + this.getPickedTimeMillis + 1000;
  }

  pause() {
    this.getCurrentAudio.audioData.pause();
  }

  pauseAction(audio: IndexedAudio) {
    this.currentAudio = audio;
    this.playing = false;
    this.stopWatch.stop();
    this.stopWatch.update();
    this.receiveDateTimeOnStopped(audio, this.stopWatch.getDateTime);
  }

  setAudioAction(audio: IndexedAudio) {

    if (this.selectMode) {
      // // console.log(audio)
    }

    this.receiveSetAudio(audio);
  }

  timeUpdatedAction(audio: IndexedAudio) {
    this.currentAudio = audio;
    this.stopWatch.update();
    this.currentTime = parseInt(audio.audioData.currentTime);
    this.receiveDateTimeOnUpdated(audio, this.stopWatch.getDateTime);
  }

  finishedAction(audio: IndexedAudio) {
    this.currentAudio = audio;
    this.playing = false;
    this.stopWatch.stop();
    this.stopWatch.update();
    this.receiveDateTimeOnStopped(audio, this.stopWatch.getDateTime);
    this.receiveDateTimeOnFinished(audio, this.stopWatch.getDateTime);
  }

  @Watch('pickedTime')
  private test() {
    this.resetTimer();
    this.startedTimer = false;
  }

  get getPickedTime() {
    return {
      HH: parseInt(this.pickedTime.HH),
      mm: parseInt(this.pickedTime.mm)
    };
  }

  get getPickedTimeMillis() {
    const hh = this.getPickedTime.HH >= 1 ? this.getPickedTime.HH * 3600 * 1000 : 0;
    const mm = this.getPickedTime.mm >= 1 ? this.getPickedTime.mm * 60 * 1000 : 0;
    return hh + mm;
  }

  get isNotWorkingTimer() {
    return (this.getPickedTimeMillis / 1000) - this.currentTime < 0;
  }

  get getCurrentAudio() {
    return this.currentAudio;
  }

  get isShowingControlPanel(): boolean {
    return !this.selectMode && this.showControlPanel;
  }
}
