
















































import {Component, Vue} from "vue-property-decorator";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import DefaultVue from "vue";
import VerticalSlotContents from "@/components/infrastructures/molecules/VerticalSlotContents.vue";
import KawaiiSoundPlayer from "@/components/infrastructures/molecules/KawaiiSoundPlayer.vue";
import DMVPlayer from "@/components/domains/esthe/DMVPlayer.vue";
import StopWatchDateTime from "@/components/infrastructures/functions/time/stopWatchDateTime";
import {Plan} from "@/components/domains/esthe/aiesthe/plan";
import MyPageContentTemplate from "@/components/templates/loggedin/mypage/MyPageContentTemplate.vue";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import MentalCheckInductionCard from "@/components/domains/mentalcheck/MentalCheckInductionCard.vue";
import SoundEstheCategoryPlan from "@/components/domains/esthe/soundesthe/plans/SoundEstheCategoryPlan.vue";
import NatureSoundCard from "@/components/domains/esthe/soundesthe/plans/categories/naturesound/NatureSoundCard.vue";
import KanadeCard from "@/components/domains/esthe/soundesthe/plans/categories/kanade/KanadeCard.vue";
import ProductLineupDialog from "@/components/domains/products/ProductLineupDialog.vue";
import PlayWithSoundEsthe from "@/components/domains/esthe/soundesthe/PlayWithSoundEsthe.vue";
import SmartDMVPlayer from "@/components/domains/esthe/SmartDMVPlayer.vue";
import IndexedAudio from "@/components/domains/esthe/IndexedAudio";
import AILoading from "@/components/infrastructures/atoms/designed/AILoading.vue";

Component.registerHooks([
  'beforeRouteEnter'
]);
@Component({
  components: {
    AILoading,
    SmartDMVPlayer,
    DMVPlayer,
    PlayWithSoundEsthe,
    ProductLineupDialog,
    KanadeCard,
    NatureSoundCard,
    SoundEstheCategoryPlan,
    MentalCheckInductionCard,
    PrimaryBtn,
    MyPageContentTemplate,
    KawaiiSoundPlayer,
    VerticalSlotContents,
    KawaiiButton
  }
})
export default class AIEstheRandomPlanPage extends Vue {

  name = "AIEstheRandomPlanPage";
  soundId = '';
  soundSrc = '';
  playing = false;
  playingDateTime = new StopWatchDateTime();

  beforeMount() {

    httpResource.get('/aiesthe/random')
        .then((response: any) => {
          // console.log(response)
          this.setSoundId(response.data.id);
          this.setSoundSrc(response.data.soundSrc);
        })
        .catch((error: any) => {
          // console.log(error)
          alert('振動選択に失敗しました。');
        });
  }

  setSoundId(soundId: string) {
    this.soundId = soundId;
  }

  setSoundSrc(soundSrc: string) {
    this.soundSrc = soundSrc;
  }

  receiveDateTimeOnStarted() {
    this.playing = true;
  }

  receiveDateTimeOnStopped(audio: IndexedAudio, dateTime: StopWatchDateTime) {
    this.playingDateTime = dateTime;
    this.playing = false;
  }

  finish() {

    const request = {
      dmvTypeCode: "01",
      planCode: Plan.RANDOM.code,
      vibrationId: this.soundId,
      startDateTime: this.playingDateTime.formattedStartDateTime,
      endDateTime: this.playingDateTime.formattedEndDateTime,
      totalPlayingTime: this.playingDateTime.formattedTotalPlayingTime
    };

    httpResource.post("/dmv/record-playing", request);

    this.$router.push("/mypage/aiesthe/");
  }

  get getSoundSrc() {
    return this.soundSrc;
  }
}

DefaultVue.component(AIEstheRandomPlanPage.name, AIEstheRandomPlanPage);
