






































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ContentTitle extends Vue {
  name = "ContentTitle";

  @Prop({default: ''}) title: string;
  @Prop({default: ''}) subtitle: string;
  @Prop({default: ''}) description: string;
  @Prop({default: 'left'}) align: string;
  @Prop({default: 8}) cols: number;
  @Prop({default: 48}) titleBaseSize: number;
  @Prop({default: true}) showDivider: boolean;
  @Prop({default: true}) bottomDivider: boolean;

  getFontSize(baseSize: number) {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return baseSize * 1.0;
    } else if (this.$vuetify.breakpoint.mdAndUp) {
      return baseSize * 0.8;
    } else {
      return baseSize * 0.6;
    }
  }

  get justifyAlign() {
    return this.align ? this.align : 'left';
  }
}
