



















































import {Component, Prop, Vue} from "vue-property-decorator";
import ProductCard from "@/components/domains/products/ProductCard.vue";
import {PurchaseMutationTypes} from "@/store/product/purchase/types";
import router from "@/router";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";
import BackBtn from "@/components/infrastructures/atoms/designed/BackBtn.vue";
import authService from "@/components/domains/authorization/authService";

@Component({
  components: {BackBtn, PrimaryBtn, ProductCard}
})
export default class ProductLineups extends Vue {
  name = "ProductLineups";

  @Prop() products!: any[];

  @Prop({default: false}) showOtherPlans: boolean;

  purchase(productId: string) {

    this.$store.commit(PurchaseMutationTypes.clear);
    this.$store.commit(PurchaseMutationTypes.setProductId, productId);

    // ログインしていない場合、アカウント登録 or ログインを促すページへ遷移
    if (!authService.isLoggedIn()) {
      router.push('/premium-plan-account-method');
    } else {
      router.push(`/payment`);
    }
  }
}
