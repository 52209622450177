






























import {Component, Vue} from "vue-property-decorator";
import SoundTherapyDMVUploadTab from "@/components/domains/upload/sound_therapy/SoundTherapyDMVUploadTab.vue";
import SoundTherapyDMVDeleteTab from "@/components/domains/upload/sound_therapy/SoundTherapyDMVDeleteTab.vue";

@Component({
  components: {SoundTherapyDMVDeleteTab, SoundTherapyDMVUploadTab}
})
export default class SoundTherapyDMVUpload extends Vue {

  name = "SoundTherapyDMVUpload";
  tab: any = '';
}
