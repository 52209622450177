import Answer from "@/components/infrastructures/functions/question/answer";

export default class Question {

  private id = '';

  constructor(private readonly question: string, private readonly answers: Answer[]) {
    this.id = Math.random().toString(32);
  }

  public getId() {
    return this.id;
  }

  public getQuestion() {
    return this.question;
  }

  public getAnswers() {
    return this.answers;
  }
}
