


































































import SearchDialog, {SearchAction} from "@/components/infrastructures/molecules/SearchDialog.vue";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import DefaultVue from "vue";

class AiEstheDetectFaceResultSearchAction implements SearchAction {

  async action(userId: string): Promise<[{}]> {
    const response = await httpResource.get(`aiesthe/history-without-related-calte?userId=${userId}`);
    return response.data;
  }
}

@Component(
    {
      components: {
        SearchDialog
      }
    }
)
export default class AIEstheChooseDetectFaceResultDialog extends Vue {
  name = "AIEstheChooseDetectFaceResultDialog";
  @Prop() onDecideAction: Function;
  @Prop() userId: string;
  selectedResult!: any;
  customers: any[] = [];
  headers = [
    {text: "分析結果ID", value: "id"},
    {text: "記録日", value: "latestUpdate"}
  ];

  condition = '';
  hiddenList = false;
  activeDecision = true;
  dialog = false;
  options: any = {}
  items = []
  item = {};
  selectedItems = []
  total = 0
  loading = false

  private decide() {
    this.dialog = false;
    this.selectedResult = this.item;
    this.onDecideAction(this.item);
  }

  @Watch("options")
  getDataFromApi() {
    this.loading = true
    this.apiCall().then((data: any) => {
      this.total = data.total;
      this.items = data.items;
    }).catch(error => {
      // NOP
    }).finally(() => {
      this.loading = false;
    })
  }

  async apiCall() {

    const {page, itemsPerPage} = this.options;

    const size = itemsPerPage == -1 ? this.total : itemsPerPage;
    if (size > 99) {
      alert('100件以上の表示はできません。')
      return;
    }

    // ページ更新するたびにtotal取得するが、動的に担当者がカルテを追加した場合を想定している
    const totalResponse = await httpResource.get(`aiesthe/history-count?userId=${this.userId}`);
    const caltesResponse = await httpResource.get(`aiesthe/history-without-related-calte?userId=${this.userId}&page=${page - 1}&size=${size}`);

    return {items: caltesResponse.data, total: totalResponse.data};
  }

  @Watch("selectedItems")
  private watchSelected(selectedItems: any) {
    this.item = selectedItems[0];
    this.activeDecision = selectedItems.length === 0;
  }
}

DefaultVue.component(AIEstheChooseDetectFaceResultDialog.name, AIEstheChooseDetectFaceResultDialog);
