






































































import {Component, Model, Prop, Vue} from "vue-property-decorator";
import StressCheckSpecification from "@/components/domains/stress_check/stressCheckSpecification";
import {StressLevel} from "@/components/domains/stress_check/stressLevel";
import QuestionData from "@/components/infrastructures/functions/questionnaire/questionData";

@Component
export default class FreeStressCheckResultDialog extends Vue {

  name = "FreeStressCheckResultDialog";

  @Model() private stressQuestions: QuestionData[];
  private stressScore: number;
  private stressLevel: StressLevel;
  private adsense: boolean;

  // Dialog
  @Prop({default: '/'}) private backPath: string;
  private dialog = false;
  private dialogMessage = '';

  beforeMount() {
    this.init();
  }

  private init() {
    this.stressScore = 0;
    this.stressLevel = StressLevel.LEVEL1;
    this.dialogMessage = 'すべて回答してください。';
    this.adsense = false;
  }

  private aggregate() {

    const allAnswered = StressCheckSpecification.allAnswered(this.stressQuestions);

    this.init();

    if (allAnswered) {
      this.stressScore = StressCheckSpecification.aggregateStressQuestionnaire(this.stressQuestions);
      this.stressLevel = StressCheckSpecification.stressLevel(this.stressScore);
      this.dialogMessage = StressCheckSpecification.comment(this.stressLevel);
      this.adsense = this.stressLevel != StressLevel.LEVEL1;
    }
  }
}
