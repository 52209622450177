




import {Component, Vue} from "vue-property-decorator";
import {AuthActionTypes, AuthGetterTypes} from "@/store/auth/types";
import ProvidingDataAgreementDialog from "@/components/domains/agreement/ProvidingDataAgreementDialog.vue";

@Component({
  components: {ProvidingDataAgreement: ProvidingDataAgreementDialog}
})
export default class App extends Vue {
  name = 'App';
  isLoggedIn = false;
  setlang = false;

  debug = process.env.VUE_APP_DEBUG === 'true';

  beforeMount() {
    if (!this.setlang && this.$route.query.lang) {
      const lang = this.$route.query.lang;
      lang === 'en' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'ja';
      this.setlang = true;
    }
  }

  beforeCreate() {
    this.$store.dispatch(AuthActionTypes.verifyLogIn);
    this.isLoggedIn = this.$store.getters[AuthGetterTypes.getIsAuthenticated];
  }

  public login(): boolean {
    return this.$store.getters[AuthGetterTypes.getIsAuthenticated];
  }
}
