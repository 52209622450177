





























































import {Component, Prop, Vue} from "vue-property-decorator";
import DateTimeFormatter from "@/components/infrastructures/functions/time/dateTimeFormatter";

@Component
export default class DMVRecordResultCard extends Vue {
  name = "DMVRecordResultCard";

  @Prop() result!: [{}];

  headers = [
    {text: "コース(分)", value: "courseMin"},
    {text: "プラン名", value: "planName"},
    // { text: "振動タイプ", value: "type"}
    {text: "開始日付時刻", value: "startDateTime"},
    {text: "終了日付時刻", value: "endDateTime"},
    {text: "トータル再生時間", value: "totalPlayingTime"},
  ];

  formatDate(str: string) {
    return DateTimeFormatter.formatFromStr(str);
  }

}
