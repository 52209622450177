import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class EstheService {

    public static async checkAvailableLoop() {
        return httpResource.get('/v2/esthe/available-loop-func');
    }

    public static async checkAvailableTimer() {
        return httpResource.get('/v2/esthe/available-timer-func');
    }

    public static async checkAvailableWithSoundEsthe() {
        return httpResource.get('/v2/esthe/available-with-soundesthe-func');
    }

    public static async checkAvailableDrAiAndHealthCare() {
        return httpResource.get('/v2/esthe/available-dr-ai-and-healthcare-func');
    }

    public static async checkAvailableSoundTherapy() {
        return httpResource.get('/v2/esthe/available-sound-therapy-func');
    }

    public static async checkAvailableAIEsthe() {
        return httpResource.get('/v2/esthe/available-aiesthe');
    }
}