





















































import {Component, Prop, Vue} from "vue-property-decorator";
import httpResource from "@/components/infrastructures/functions/http/httpResource";
import MentalCheckSelection from "@/components/domains/mentalcheck/MentalCheckSelection.vue";
import HoverLinkCard from "@/components/infrastructures/atoms/HoverLinkCard.vue";
import MentalCheck from "@/components/domains/mentalcheck/MentalCheck.vue";
import AgreementService from "@/components/domains/agreement/agreementService";
import KawaiiButton from "@/components/infrastructures/atoms/KawaiiButton.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import AuthService from "@/components/domains/authorization/authService";

@Component({
  components: {ContentTitle, KawaiiButton, MentalCheck, HoverLinkCard, MentalCheckSelection}
})
export default class MentalCheckInductionCard extends Vue {
  name = "MentalCheckInductionCard";

  @Prop({default: ''}) redirectPath!: string;

  canAnyMentalCheck = false;
  showMentalCheckSelection = false;
  mentalChecks = [];
  selectedId = '';

  // 同意確認
  showProvideDataAgreement = false;
  provideDataAgreement = false;

  // メンタルチェック表示切替
  toggleMentalCheckSelection = false;

  created() {
    this.fetchMentalCheckInfo();
  }

  showMentalCheck() {
    this.showMentalCheckSelection = true;
    this.showProvideDataAgreement = true;
  }

  agree(agree: boolean) {
    this.provideDataAgreement = agree;
    this.showProvideDataAgreement = false;
  }

  afterSending() {
    this.selectedId = '';
    this.fetchMentalCheckInfo();
  }

  fetchMentalCheckInfo() {

    AgreementService.isAgreementProvidingData()
        .then((response: any) => {
          this.provideDataAgreement = response.data;
        });

    httpResource.get(`/mental-check/can-any-mentalcheck`)
        .then((response: any) => {
          this.canAnyMentalCheck = response.data;

          if (this.canAnyMentalCheck) {
            httpResource.get(`/mental-check/can-do-mentalchecks`)
                .then((response: any) => {
                  this.mentalChecks = response.data;
                });
          }
        });
  }

  get isEnterprise(): boolean {
    return AuthService.isEnterprise();
  }
}
