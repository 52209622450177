import httpResource from "@/components/infrastructures/functions/http/httpResource";

export default class AgreementService {

    public static async isAgreementProvidingData() {
        return httpResource.get("/agreement/providing-data");
    }

    public static async agreeProvidingData(agreement: boolean) {
        return httpResource.post(`/agreement/agree-providing-data?agreement=${agreement}`);
    }

    public static async isAgreementTermOfService() {
        return httpResource.get("/agreement/term-of-service");
    }

    public static async agreeTermOfService(agreement: boolean) {
        return httpResource.post(`/agreement/agree-term-of-service?agreement=${agreement}`);
    }

    public static async termsOfServiceMarkdown() {
        return httpResource.get('/static/agreements/terms_of_service.md');
    }

    public static async providingDataMarkdown() {
        return httpResource.get('/static/agreements/data_analytics_agreement.md');
    }

    public static async privacyPolicy() {
        return httpResource.get('/static/agreements/privacy_policy.md');
    }
}