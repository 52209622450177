




















import {Component, Vue} from "vue-property-decorator";
import {PurchaseMutationTypes} from "@/store/product/purchase/types";
import ProductCard from "@/components/domains/products/ProductCard.vue";
import LoggedInTemplate from "@/components/templates/loggedin/LoggedInTemplate.vue";
import ProductLineups from "@/components/domains/products/ProductLineups.vue";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import SubscriptionService from "@/components/domains/payment/shared/subscription/SubscriptionService";
import HomeTemplate from "@/components/templates/home/HomeTemplate.vue";
import DefaultTemplate from "@/components/templates/default/DefaultTemplate.vue";
import AuthService from "@/components/domains/authorization/authService";

@Component({
  components: {DefaultTemplate, HomeTemplate, ContentTitle, ProductLineups, LoggedInTemplate, ProductCard}
})
export default class PremiumPlansPage extends Vue {

  name = "PremiumPlansPage";
  productList: any[] = [];
  clicked = false
  loading = true;

  get isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  beforeMount() {

    const params = new URLSearchParams(window.location.search);
    const item = params.has('item') ? params.get('item') : '';

    this.loading = true;
    SubscriptionService.fetchAllSubscriptionProducts().then((data) => {
      if (item === '') {
        this.productList = data;
      } else {
        // 一致するアイテムを先頭に移動する
        const matchingItem = data.filter((d: any) => d.id === item);
        const otherItems = data.filter((d: any) => d.id !== item);
        this.productList = [...matchingItem, ...otherItems];
      }
      this.loading = false;
    });

  }

  purchase(id: string) {
    this.$store.commit(PurchaseMutationTypes.clear)
    this.$store.commit(PurchaseMutationTypes.setProductId, id)
    this.clicked = true;
  }
}
