




















import {Component, Vue} from "vue-property-decorator";
import SubscriptionService from "@/components/domains/payment/shared/subscription/SubscriptionService";
import {PurchaseMutationTypes} from "@/store/product/purchase/types";
import ContentTitle from "@/components/infrastructures/atoms/designed/ContentTitle.vue";
import ProductLineups from "@/components/domains/products/ProductLineups.vue";
import authService from "@/components/domains/authorization/authService";
import PrimaryBtn from "@/components/infrastructures/atoms/designed/PrimaryBtn.vue";

@Component({
  components: {PrimaryBtn, ProductLineups, ContentTitle}
})
export default class Plans extends Vue {

  name = "Plans"
  productList: any[] = [];
  clicked = false
  loading = true;

  beforeMount() {
    this.loading = true;
    SubscriptionService.fetchAllSubscriptionProducts().then((data) => {
      this.productList = data;
      this.loading = false;
    });
  }

  purchase(id: string) {
    this.$store.commit(PurchaseMutationTypes.clear)
    this.$store.commit(PurchaseMutationTypes.setProductId, id)
    this.clicked = true;
  }

  get isLoggedIn(): boolean {
    return authService.isLoggedIn();
  }
}
